import React, { Component } from 'react';
import $ from 'jquery';
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from '../Footer';
class Report extends React.Component {
   constructor(props) {
      super(props);
      let edit_id = null;
      const path = this.props.location.pathname;
      const params = this.props.location.pathname.split('/');
      if (params[params.length - 1] != 'Report') {
         edit_id = params[params.length - 1];
      }
      this.state = {
         reporttext: "", baseUrl: process.env.REACT_APP_BASE_URL, reportsms: "", reportText: "", cuserid: edit_id, static: "", cscreenname: this.props.location.state.name
      }
      this.setname = this.setname.bind(this);
   }



   componentDidMount() {
      $(".errorbtn").click(function () {
         $(".errorpop").toggle();
      });

   }
   setname() {
      if (localStorage.getItem('screenname')) {
         var first = localStorage.getItem('screenname').split(" ")[0]
         var firstn = first.charAt(0);
         if (localStorage.getItem('screenname').split(" ")[1]) {
            var second = localStorage.getItem('screenname').split(" ")[1]
            var secondn = second.charAt(0);
            console.log('ssssssssss', secondn);
            this.setState({ static: firstn + secondn });
         }
         else {
            this.setState({ static: firstn });
         }
      }
   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   goback() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   goreport() {
      const body = {
         reportText: this.state.reporttext,
         reportedTo: this.state.cuserid
      }
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/report/reportUser.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            console.log(res);
            this.setState({ reportsms: res.data.message, reportText: "" }, () => {
               $(".errorpop").toggle();
               setTimeout(() => {
                  localStorage.setItem("backid", "back");
                  this.props.history.goBack();
               }, 1000);

            })
            console.log('message', res.data.message);
         }).catch((error) => {
            console.log(error);
         })
   }
   fetchreporttext(e) {
      this.setState({ reporttext: e.target.value });
   }
   render() {

      return (
         //     <div className="newscreen1 newscreen01">
         // <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}  id="one_18">
         <div className={`${this.props.pos} screen1`} id="one_18">
            <div className="top newbg" id="myHeader">
               {/* <!-- --------- header ---------------> */}
               <section className="header userlogin">
                  <div className="row">
                     <div className="col-12 pl-0 pr-0 header_center text-center">
                        <a className="bkc linkback" onClick={this.goback.bind(this)} data-toggle="tab" ><img defaultStyle="font-size: 16px" className="backin" src="../../images/back.png" align="img" /></a>
                        <h6>Timesort</h6>
                     </div>
                  </div>
               </section>
            </div>
            <div className="form-signin login report">
               <h3>{localStorage.getItem("screenname") ? "Report " + this.state.cscreenname + ", " + localStorage.getItem("screenname").split(" ")[0] : ""}</h3>
               <div className="form-group mb-1">
                  <textarea type="text" value={this.state.reporttext} name="report" onChange={this.fetchreporttext.bind(this)} placeholder="report" ></textarea>
               </div>
               <p className="mt-0">{"Please report " + this.state.cscreenname + "’s misconduct if " + this.state.cscreenname.split(" ")[0] + " is in violation of any of Timesort’ s policies"}</p>
               {/* <!--       <p className="mt-0">By submitting the email associated with account , you will receive an email with a link to rest your password</p> --> */}
               <button className="btn btn-lg btn-block" onClick={this.goreport.bind(this)} type="submit">Submit</button>
            </div>
            <div className="op errorpop">
               <div className="errorbtn error h-100"></div>
               <ul>
                  <li>{this.state.reportsms}</li>

                  <button className="upbtn errorbtn  onClick={this.cancelClickProfile.bind(this)}">ok</button>
               </ul>
            </div>
            <Footer {...this.props} />
         </div>
         // </div>
      )
   }
};
export default Report;