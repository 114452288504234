import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from 'react-number-format';
import Footer from '../Footer';
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class Likes extends React.Component {
   constructor(props) {
      super(props);
      let totallike = null;
      let commentid = null;
      let userid = null;
      let eventids = null;
      let usernames = null;
      const path = this.props.location.pathname;
      const params = this.props.location.pathname.split('/');
      if (params[params.length - 1] != 'Report') {
         totallike = params[params.length - 1];
         commentid = params[params.length - 2];
         userid = params[params.length - 3];
         eventids = params[params.length - 4];
         usernames = params[params.length - 5];
      }
      this.state = {
         cuserid: userid, firsttime: false, commentId: commentid, totalLikes: totallike, cscreenname: usernames, eventid: eventids, baseUrl: process.env.REACT_APP_BASE_URL, followerSearch_second: [],
         activecolor_second: '', action: "", ofname: "", perpage_second: 1, alphabetically_second: "", mutuality_second: "", perpage_second: 1, searchPerameter_second: "", followers_second: [], items_second: Array.from({ length: 40 }), hasMore: true
      }

   }
   relevancy() {
      $(".pupup8").toggle();
      this.setState({ mutuality_second: "" });
      this.setState({ alphabetically_second: "" });
      this.componentDidMount();
   }
   mainProfileColor() {
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".username").css("color", "");
      $(".username").css("color", random_color.textColor);
      $(".username").css("background-color", "");
      $(".username").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   mutuality() {
      $(".pupup8").toggle();
      this.setState({ alphabetically_second: "" });
      this.setState({ mutuality_second: "mutuality" });
      this.componentDidMount();
   }
   alphabetically() {
      $(".pupup8").toggle();
      this.setState({ mutuality_second: "" });
      this.setState({ alphabetically_second: "alphabetically" });
      this.componentDidMount();
   }

   componentDidMount() {
      const token = localStorage.getItem('token');
      this.setState({ firsttime: false, followerSearch_second: [], croseIcone_second: "", activecolor_second: 'page', perpage_second: 1, searchPerameter_second: "", followers_second: [], items_second: Array.from({ length: 40 }), hasMore: true });
      var pagepro = 1;
      fetch(this.state.baseUrl + '/api/comment/getCommentLikes.php?user_id=' + this.state.cuserid + '&page=1&limit=40&commentID=' + this.state.commentId + '&eventID=' + this.state.eventid, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.response.length < 39) {

               this.setState({ hasMore: false });
            }
            console.log("res:", responseJson.response);
            if (this.state.mutuality_second != "") {
               this.setState({ followers_second: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse(), firsttime: true }, () => {
                  this.mainProfileColor()
               });
            }
            if (this.state.alphabetically_second != "") {
               this.setState({
                  followers_second: responseJson.response.sort((a, b) =>
                     a.screenName.localeCompare(b.screenName)), firsttime: true
               }, () => {
                  this.mainProfileColor()
               });
            }
            else {
               this.setState({ followers_second: responseJson.response, loading_second: "false", firsttime: true }, () => {
                  this.mainProfileColor()
               });
            }
         });



   }

   removefollowertextSecond() {
      document.getElementById("lemoncal").value = "";
      this.setState({ followerSearch_second: [] });
      this.setState({ croseIcone_second: "" });
      this.componentDidMount();

   }
   fetchMoreDatafollowerSearchSecond() {
      const variable = this.state.cuserid;
      const token = localStorage.getItem('token');

      const pageno = 40;
      this.state.statepage_second = +40;
      this.setState({ statepage_second: this.state.statepage_second });
      if (this.state.followerSearch_second.length >= this.state.statepage_second) {
         this.state.searchPage_second = +1;
         this.setState({ searchPage_second: this.state.searchPage_second });
      }
      else {
         this.setState({ hasMore: false });
         return;
      }
      setTimeout(() => {
         fetch(this.state.baseUrl + '/api/comment/searchCommentLikes.php?user_id=' + this.state.cuserid + '&search=' + this.state.searchPerameter_second + '&page=1&limit=40&commentID=' + this.state.commentId + '&eventID=' + this.state.eventid, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response.length < 39) {
                  $('#march').addClass('closeCd');
                  this.setState({ hasMore: false });
               }
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.followerSearch_second.push(responseJson.response[i]);
               }
               this.setState({
                  followerSearch_second: this.state.followerSearch_second
               }, () => {
                  this.mainProfileColor()
               });
            }, 3000);
      });
      this.setState({
         itemsearchfo_second: this.state.itemsearchfo_second.concat(Array.from({ length: 40 }))
      });
   }

   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }

   weekforNextSecond() {
      if (this.state.followers_second.length > 0 | this.state.followerSearch_second.length > 0) {
         if (this.state.hasMore == false) {
            $('.cros').addClass('lastlike');
            return "loaderclass load closeCd";
         }
         else {
            return "loaderclass load";
         }
      }
      else {
         return "loaderclass";

      }
   }

   fetchMoreDataSecond() {
      setTimeout(() => {
         this.setState({ activecolor_second: 'first' });
         const variable = this.state.cuserid;
         const token = localStorage.getItem('token');
         const totaluser = this.state.cfollowers;
         const recordp = totaluser / 40;
         if (this.state.perpage_second >= recordp) {
            this.setState({ hasMore: false });
            return;
         }

         this.setState({ perpage_second: this.state.perpage_second + 1 });
         fetch(this.state.baseUrl + '/api/comment/getCommentLikes.php?user_id=' + this.state.cuserid + '&page=' + this.state.perpage_second + '&limit=40&commentID=' + this.state.commentId + '&eventID=' + this.state.eventid, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response.length == 0) {
                  $('#march').addClass('closeCd');
                  this.setState({ hasMore: false });
               }
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.followers_second.push(responseJson.response[i]);
               }
               if (this.state.mutuality_second != "") {
                  this.setState({ followers_second: this.state.followers_second.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() }, () => {
                     this.mainProfileColor()
                  });
               }
               if (this.state.alphabetically != "") {
                  this.setState({
                     followers_second: this.state.followers_second.sort((a, b) =>
                        a.screenName.localeCompare(b.screenName))
                  }, () => {
                     this.mainProfileColor()
                  });
               }
               else {
                  this.setState({ followers: this.state.followers_second }, () => {
                     this.mainProfileColor()
                  });
               }
            });
         this.setState({
            items_second: this.state.items_second.concat(Array.from({ length: 40 }))
            , loading: "false"
         });
      }, 1000);
   }
   pops() {
      $(".pupup8").toggle();
   }
   followusercancel = (e) => {
      if (this.state.followerSearch_second.length > 0) {
         var objIndex = this.state.followerSearch_second.findIndex((obj => obj.user_id == e.currentTarget.id));
         if (objIndex !== -1) {
            this.state.followerSearch_second[objIndex].isRequested = 0;
            this.setState({ followerSearch_second: this.state.followerSearch_second }, () => {
               this.mainProfileColor()
            });
         }
      }
      else {
         var objIndex = this.state.followers_second.findIndex((obj => obj.user_id == e.currentTarget.id));
         if (objIndex !== -1) {
            this.state.followers_second[objIndex].isRequested = 0;
            this.setState({ followers_second: this.state.followers_second }, () => {
               this.mainProfileColor()
            });
         }
      }
      const body = { user_id: e.currentTarget.id };
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/follow/cancelFollowRequest.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
         })
         .catch((error) => {
            this.setState({
               error: error.message
            });
            alert(error.response.data.message);
         });
   }
   followuser = (e) => {

      if (this.state.followerSearch_second.length > 0) {
         var objIndex = this.state.followerSearch_second.findIndex((obj => obj.user_id == e.currentTarget.id.split("/")[0]));
         if (objIndex !== -1) {
            if (e.currentTarget.id.split("/")[1] == 1) {
               this.state.followerSearch_second[objIndex].isFollowing = 1;
               this.setState({ followerSearch_second: this.state.followerSearch_second }, () => {
                  this.mainProfileColor()
               });
            }
            else {
               this.state.followerSearch_second[objIndex].isRequested = 1;
               this.setState({ followerSearch_second: this.state.followerSearch_second }, () => {
                  this.mainProfileColor()
               });
            }
         }
      }
      else {
         var objIndex = this.state.followers_second.findIndex((obj => obj.user_id == e.currentTarget.id.split("/")[0]));
         if (objIndex !== -1) {
            if (e.currentTarget.id.split("/")[1] == 1) {
               this.state.followers_second[objIndex].isFollowing = 1;
               this.setState({ followers_second: this.state.followers_second }, () => {
                  this.mainProfileColor()
               });
            }
            else {
               this.state.followers_second[objIndex].isRequested = 1;
               this.setState({ followers_second: this.state.followers_second }, () => {
                  this.mainProfileColor()
               });
            }
         }
      }
      if (e.currentTarget.id.split("/")[1] == 1) {
         var body = {
            user_id: e.currentTarget.id.split("/")[0],
            isRequest: 1
         };
      }
      else {
         var body = {
            user_id: e.currentTarget.id.split("/")[0],
            isRequest: 0
         };
      }
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/follow/sendFollowRequest.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {

         })
         .catch((error) => {
            this.setState({
               error: error.message
            });
         })
   }
   unfollowuser(e) {
      this.setState({ action: e.currentTarget.id, ofname: e.currentTarget.dataset.div_name }, () => {
         $(".errorpop").toggle();
      })
   }
   actionclose() {
      $(".errorpop").toggle();
   }
   action() {
      $(".errorpop").toggle();
      if (this.state.followerSearch_second.length > 0) {
         var objIndex = this.state.followerSearch_second.findIndex((obj => obj.user_id == this.state.action));
         if (objIndex !== -1) {
            this.state.followerSearch_second[objIndex].isFollowing = 0;
            this.state.followerSearch_second[objIndex].isRequested = 0;
            this.setState({ followerSearch_second: this.state.followerSearch_second }, () => {
               this.mainProfileColor()
            });
         }
      }
      else {
         var objIndex = this.state.followers_second.findIndex((obj => obj.user_id == this.state.action));
         if (objIndex !== -1) {
            this.state.followers_second[objIndex].isFollowing = 0;
            this.state.followers_second[objIndex].isRequested = 0;
            this.setState({ followers_second: this.state.followers_second }, () => {
               this.mainProfileColor()
            });
         }
      }
      const body = { user_id: this.state.action };
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/follow/unFollow.php', body, {

         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {

         })
         .catch((error) => {
            this.setState({
               error: error.message
            });
            alert(error.response.data.message)
         })
   }

   onKeyUpValueFollowerSecond(event) {
      const variable = this.state.cuserid;
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/comment/searchCommentLikes.php?user_id=' + this.state.cuserid + '&search=' + event.target.value + '&page=1&limit=40&commentID=' + this.state.commentId + '&eventID=' + this.state.eventid, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.response.length < 39) {
               // $('#march').addClass('closeCd');
               this.setState({ hasMore: false });
            }
            $('.userin').removeClass('active');
            this.setState({ followerSearch_second: [] });
            this.setState({ followerSearch_second: responseJson.response });
         });
      this.setState({ searchPerameter_second: "" });
      this.setState({ searchPerameter_second: event.target.value });
      this.setState({ statepage_second: 0 });
      this.setState({ searchPage_second: 1 });
      this.setState({
         itemsearchfo_second: Array.from({ length: 40 })
      });
      if (event.target.value.length > 0) {
         this.setState({ croseIcone_second: "hash" });
      }
      else {
         this.setState({ croseIcone_second: "" });
      }
   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   goback() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   render() {
      return (
         <div>
            {/* <div className="newscreen1 newscreen01">
        <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"} id="one_8"> */}
            <div className={`${this.props.pos} screen1`} id="one_8">
               <div className="top" id="myHeader">
                  <section className="header headernoti2">
                     <div className="row">
                        <div className="col-4 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><a className="linkback" data-toggle="tab" onClick={this.goback.bind(this)}><img src="/images/back.png" align="img" /></a></li>
                           </ul>
                        </div>
                        <div className="col-4 header_center pl-0 pr-0 text-center">
                           <h6>likes</h6>
                        </div>
                        <div className="col-4 header_right pl-0">
                           <ul className="uld">
                              <li className="more5" onClick={this.pops.bind(this)}><a><img src="/images/more2.png" align="img" /></a></li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <div className="header_search">
                     <form className="form-inline mt-md-0">
                        <input className="form-control" id="lemoncal" type="text" onKeyUp={this.onKeyUpValueFollowerSecond.bind(this)} placeholder={"search likes on " + this.state.cscreenname.split(" ")[0] + "'s comment"} aria-
                           label="Search" />
                        {(() => {
                           if (this.state.croseIcone_second != "") {
                              return (<span className="btn"><img className="" onClick={this.removefollowertextSecond.bind(this)} src="/images/close(1).png" /></span>);
                           }
                           else {
                              return (<span className="btn"><img className="" src="/images/search.png" /></span>);
                           }
                        })()}

                     </form>
                  </div>
               </div>
               <div className="tab-c1ontent followingmain">
                  <div class={this.state.followerSearch_second.length > 0 || this.state.followers_second.length > 0 ? "loaderclass closeCd" : "loaderclass onscroll"}><img src="../../../../../images/newgif.gif" alt="loading" /></div>
                  <div className="row py-0 my-0">
                     <div
                        id="scrollableDivthree"
                        className="scrollClass newscreen1"
                        style={{
                           overflow: 'auto',
                           display: 'row',
                           flexDirection: 'row',
                           margintop: 47,
                        }}
                     >
                        {(() => {
                           if (this.state.followerSearch_second.length > 0) {
                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.itemsearchfo_second.length}
                                    next={this.fetchMoreDatafollowerSearchSecond.bind(this)}
                                    className="cros"
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    hasMore={true}
                                    //  loader={
                                    //    <div id="march" className={this.weekforNextSecond()}>
                                    //    <img
                                    //       src="/images/newgif.gif"
                                    //       alt="loading"
                                    //    />
                                    //    </div>}
                                    loader={
                                       <div className={this.state.followerSearch.length > 0 ? "loaderclass" : "loaderclass closeCd"}>
                                          <img
                                             src="../../../../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    endMessage={
                                       <p style={{ textAlign: "center" }}>
                                          <b>Yay! You have seen it all</b>
                                       </p>
                                    }
                                    useWindow={false}
                                    scrollableTarget="scrollableDivthree"
                                 >

                                    {this.state.followerSearch_second.map((c, i) => {
                                       return (<div className="row">
                                          {(() => {
                                             if (c.user_id == localStorage.getItem('userid')) {
                                                return (
                                                   <div className="col-12">
                                                      <div className="userin userinfollowers">
                                                         <div className="userintest" id={c.user_id} data-div_name="#one_8" >
                                                            <Link className="might" to={{
                                                               pathname: `/${c.username}`,
                                                            }} onClick={this.setStorage.bind(this)}
                                                            >
                                                               <span class="imgg">
                                                                  {c.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(c.screenName)}</span>}
                                                               </span>
                                                               <h6 >{c.screenName}</h6>
                                                               <p ><NumberFormat value={localStorage.getItem('followers')} displayType={'text'} thousandSeparator={true} /> followers</p>
                                                            </Link>
                                                         </div>
                                                      </div>
                                                   </div>
                                                );
                                             }
                                             else {
                                                return (<div className="col-12">
                                                   <div className="userin userinfollowers">
                                                      <div className="userintest" id={c.user_id} data-div_name="#one_8" >
                                                         <Link className="might" to={{
                                                            pathname: `/${c.username}`,
                                                         }} onClick={this.setStorage.bind(this)}
                                                         >
                                                            <span class="imgg">
                                                               {c.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(c.screenName)}</span>}
                                                            </span>
                                                            <h6 >{c.screenName}</h6>
                                                            <p ><NumberFormat value={c.mutual} displayType={'text'} thousandSeparator={true} /> mutual</p>
                                                         </Link>
                                                      </div>
                                                      {(() => {
                                                         if (c.isFollowing == 0 && c.make_public == 1) {
                                                            return (<h5><span id={c.user_id + '/' + c.make_public} onClick={this.followuser.bind(this)} className="follow" >follow</span></h5>);
                                                         }
                                                         else if (c.isFollowing == 0 && c.make_public == 0 && c.isRequested == 0) {
                                                            return (<h5><span id={c.user_id + '/' + c.make_public} onClick={this.followuser.bind(this)} className="follow" >request</span></h5>);
                                                         }
                                                         else if (c.make_public == 0 && c.isRequested == 1) {
                                                            return (<h5><span id={c.user_id} onClick={this.followusercancel.bind(this)} >requested</span></h5>);
                                                         }
                                                         else if (c.isFollowing == 1) {
                                                            return (<h5><span id={c.user_id} data-div_name={c.screenName} onClick={this.unfollowuser.bind(this)}>following</span></h5>);
                                                         }
                                                      })()}
                                                   </div>
                                                </div>
                                                );
                                             }
                                          })()}
                                       </div>
                                       )
                                    })
                                    }
                                 </InfiniteScroll>
                              )
                           }
                           else {

                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.items_second.length}
                                    next={this.fetchMoreDataSecond.bind(this)}
                                    className="cros"
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    hasMore={true}
                                    //  loader={
                                    //    <div id="march" className={this.weekforNextSecond()}>
                                    //    <img
                                    //       src="/images/newgif.gif"
                                    //       alt="loading"
                                    //    />
                                    //    </div>}
                                    loader={
                                       <div className={this.state.followerSearch_second.length > 0 ? "loaderclass" : "loaderclass closeCd"}>
                                          <img
                                             src="../../../../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    endMessage={
                                       <p style={{ textAlign: "center" }}>
                                          <b>Yay! You have seen it all</b>
                                       </p>
                                    }
                                    useWindow={false}
                                    scrollableTarget="scrollableDivthree"
                                 >
                                    {
                                       this.state.followers_second.map((p, i) => {
                                          return (<>
                                             {(() => {
                                                if (p.user_id == localStorage.getItem('userid')) {
                                                   return (
                                                      <div className="col-12">
                                                         <div className="userin userinfollowers">
                                                            <div className="userintest" id={p.user_id} data-div_name="#one_8" >
                                                               <Link className="might" to={{
                                                                  pathname: `/${p.username}`,
                                                               }} onClick={this.setStorage.bind(this)}
                                                               >
                                                                  <span class="imgg">
                                                                     {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(p.screenName)}</span>}
                                                                  </span>
                                                                  <h6 >{p.screenName}</h6>
                                                                  <p ><NumberFormat value={localStorage.getItem('followers')} displayType={'text'} thousandSeparator={true} /> followers</p>
                                                               </Link>
                                                            </div>

                                                         </div>
                                                      </div>
                                                   );
                                                }
                                                else {
                                                   return (<div className="col-12">
                                                      <div className="userin userinfollowers">
                                                         <div className="userintest" id={p.user_id} data-div_name="#one_8">
                                                            <Link className="might" to={{
                                                               pathname: `/${p.username}`,
                                                            }} onClick={this.setStorage.bind(this)}
                                                            >
                                                               <span class="imgg">
                                                                  {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(p.screenName)}</span>}
                                                               </span>
                                                               <h6 >{p.screenName}</h6>
                                                               <p ><NumberFormat value={p.mutual} displayType={'text'} thousandSeparator={true} /> mutual</p>
                                                            </Link>
                                                         </div>
                                                         {(() => {
                                                            if (p.isFollowing == 0 && p.make_public == 1) {
                                                               return (<h5><span id={p.user_id + '/' + p.make_public} onClick={this.followuser.bind(this)} className="follow" >follow</span></h5>);
                                                            }
                                                            else if (p.isFollowing == 0 && p.make_public == 0 && p.isRequested == 0) {
                                                               return (<h5><span id={p.user_id + '/' + p.make_public} onClick={this.followuser.bind(this)} className="follow" >request</span></h5>);
                                                            }
                                                            else if (p.make_public == 0 && p.isRequested == 1) {
                                                               return (<h5><span id={p.user_id} onClick={this.followusercancel.bind(this)} >requested</span></h5>);
                                                            }
                                                            else if (p.isFollowing == 1) {
                                                               return (<h5><span id={p.user_id} data-div_name={p.screenName} onClick={this.unfollowuser.bind(this)}>following</span></h5>);
                                                            }
                                                         })()}
                                                      </div>
                                                   </div>
                                                   );
                                                }
                                             })()}
                                          </>
                                          )
                                       })

                                    }

                                 </InfiniteScroll>
                              );

                           }
                        })()}
                     </div>
                  </div>
               </div>
               <Footer {...this.props} />
               <div className="op pupup8">
                  <div className="more5 h-100" onClick={this.pops.bind(this)} ></div>
                  <ul>
                     <h5 className="text-center">sort</h5>
                     <li><a className="more5" id="secondRelevancy" onClick={this.relevancy.bind(this)} ><img src="/images/More.png" /> relevancy</a></li>
                     <li><a className="more5" id="secondMutuality" onClick={this.mutuality.bind(this)}><img src="/images/mut.png" /> mutuality</a></li>
                     <li><a className="more5" id="secondAlphabetically" onClick={this.alphabetically.bind(this)}><img src="/images/az.png" /> alphabetically</a></li>
                     <button className="upbtn more5" onClick={this.pops.bind(this)}>Cancel</button>
                  </ul>
               </div>
               <div className="op errorpop mainpop">
                  <div className="errorbtn error h-100" onClick={this.actionclose.bind(this)}></div>
                  <ul>
                     <li className="lipost">{"Unfollow " + this.state.ofname.split(" ")[0]}</li>
                     <li>{"Are you sure you would like to unfollow " + this.state.ofname + ", " + localStorage.getItem("screenname").split(" ")[0] + "?"}</li>
                     <div className="newpop">
                        <button className="upbtn errorbtn" onClick={this.actionclose.bind(this)}>CANCEL</button>
                        <button className="upbtn errorbtn" onClick={this.action.bind(this)}>YES</button>
                     </div>
                  </ul>
               </div>
            </div>
         </div>
         // </div>
      )
   }
}
export default Likes;