import React from 'react';
import axios from 'axios';
import { resolve } from './resolve.js';
const apiurl=process.env.REACT_APP_BASE_URL_NODE+"/api";
class Api extends  React.Component {

    // static async adminlogin(email,password){
    //   let body={email:email,password:password};
    //   return await resolve(axios.post(apiurl+`/admin/login`,body).then(res => res.data));
    // }
  
    static async actions(id,action){
      console.log("process.env.REACT_APP_BASE_URL_NODE: ",process.env.REACT_APP_BASE_URL_NODE);
      let body={follow:id,type:action};
      let headers = {
          'Content-Type':  'application/json',
          'x-access-token':localStorage.getItem("timeToken")
      };
      return await resolve(axios.post(apiurl+`/topConnection`,body,{
        headers: headers
      }).then(res => res.data));
    }
}
export default Api;


