export default async function Notificationhelper () {
//     var token = localStorage.getItem('token');
// var response =  await fetch(this.state.baseUrl+'/api/notification/getNotification.php?page=1&limit=30', {
//     method: "GET",
//     headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     "Authorization": "Bearer " + token,
//   },     
//   })
//    const movies = await response.json();
//    if(movies.response!=[])
//    {
 //   var dusers = await movies.response;
     var dusers =[];
    return  dusers;
//    }
}