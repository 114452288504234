export default async function Readnotification () {

//     var token = localStorage.getItem('token');
// var response =  await fetch(this.state.baseUrl+'/api/notification/read.php', {
//     method: "GET",
//     headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     "Authorization": "Bearer " + token,
//   },     
//   })
//    const movies = await response.json();
//    if(movies.response==[])
//    {
//        var success=true;
//     var dusers = await success;
    return false;
  // }
}

