import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from 'react-number-format';
import Footer from '../Footer';
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class Myfollowers extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, alphabetically: "", mutuality: "",
         croseIcone: "", itemsearchfo: Array.from({ length: 40 }), searchPerameter: "", statepage: 0, followerSearch: [],
         activecolor: 'page', perpage: 1, searchPerameter: "", followers: [], items: Array.from({ length: 40 }), hasMore: true,
         itemsearch: Array.from({ length: 40 }), searchPage: 1, action: "", ofname: "", baseUrlNode: process.env.REACT_APP_BASE_URL_NODE,
      }
   }
   relevancy() {
      $(".pupup8").toggle();
      this.setState({ mutuality: "" });
      this.setState({ alphabetically: "" });
      this.componentDidMount();
   }
   mainProfileColor() {
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".username").css("color", "");
      $(".username").css("color", random_color.textColor);
      $(".username").css("background-color", "");
      $(".username").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   mutuality() {
      $(".pupup8").toggle();
      this.setState({ alphabetically: "" });
      this.setState({ mutuality: "mutuality" });
      this.componentDidMount();
   }
   alphabetically() {
      $(".pupup8").toggle();
      this.setState({ mutuality: "" });
      this.setState({ alphabetically: "alphabetically" });
      this.componentDidMount();
   }
   pops() {
      $(".pupup8").toggle();
   }
   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   componentDidMount() {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const localToken = localStorage.getItem("timeToken");
      this.setState({ activecolor: 'page', perpage: 1, searchPerameter: "", loading: "", followers: [], items: 0, items: Array.from({ length: 40 }), hasMore: true });
      var pagepro = 1;
      //fetch(this.state.baseUrl+'/api/follow/getFollowers.php?user_id='+variable+'&page='+pagepro+'&limit=40', {
      fetch(this.state.baseUrlNode + '/api/myfollower?limit=40&user_id=' + variable + '&page=' + pagepro, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //"Authorization": "Bearer " + token,
            "x-access-token": localToken
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (this.state.mutuality != "") {
               this.setState({ followers: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() }, () => {
                  this.mainProfileColor()
               });
            }
            if (this.state.alphabetically != "") {
               this.setState({
                  followers: responseJson.response.sort((a, b) =>
                     a.screenName.localeCompare(b.screenName))
               }, () => {
                  this.mainProfileColor()
               });
            }
            else {
               this.setState({ followers: responseJson.response, loading: "false" }, () => {
                  this.mainProfileColor()
               });
            }

         });
   }
   handleChangeunexcept(event) {
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#these' + item).addClass('active');
      }
      else {
         $('#these' + item).removeClass('active');
      }
      this.setState({ exp: true });
      this.setState(prevState => ({ checkedunexcept: prevState.checkedunexcept.set(item, isChecked) }));
   }
   onKeyUpValueFollower(event) {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const localToken = localStorage.getItem("timeToken");
      //fetch(this.state.baseUrl+'/api/follow/followerSearch.php?user_id='+variable+'&page=1&search='+event.target.value+'&limit=40',{
      fetch(this.state.baseUrlNode + '/api/myFollowerSearch?limit=40&user_id=' + variable + '&page=1&search=' + event.target.value, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": localToken
            //"Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            $('.userin').removeClass('active');
            this.setState({ followerSearch: responseJson.response }, () => {
               this.mainProfileColor()
            });
         });
      this.setState({ searchPerameter: "", searchPerameter: event.target.value, statepage: 0, searchPage: 1 });
      this.setState({
         itemsearchfo: Array.from({ length: 40 })
      });
      if (event.target.value.length > 0) {
         this.setState({ croseIcone: event.target.value });
      }
      else {
         this.setState({ croseIcone: "" });
      }
   }
   removefollowertext() {
      document.getElementById("lemon").value = "";
      this.setState({ followerSearch: [] });
      this.setState({ croseIcone: "" });
      this.componentDidMount();
   }

   fetchMoreDatafollowerSearch() {
      const localToken = localStorage.getItem("timeToken");
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const totaluser = localStorage.getItem('followers');
      const pageno = 40;
      this.state.statepage = +40;
      this.setState({ statepage: this.state.statepage });
      if (this.state.followerSearch.length >= this.state.statepage) {
         this.state.searchPage = +1;
         this.setState({ searchPage: this.state.searchPage });
      }
      else {
         this.setState({ hasMore: false });
         return;
      }
      setTimeout(() => {
         //fetch(this.state.baseUrl+'/api/follow/followerSearch.php?user_id='+variable+'&page='+this.state.searchPage+'&search='+this.state.searchPerameter+'&limit=40', {
         fetch(this.state.baseUrlNode + '/api/myFollowerSearch?limit=40&user_id=' + variable + '&page=' + this.state.searchPage + '&search=' + this.state.searchPerameter, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "x-access-token": localToken
               //"Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.followerSearch.push(responseJson.response[i]);
               }
               this.setState({
                  followerSearch: this.state.followerSearch
               }, () => {
                  this.mainProfileColor()
               });
            }, 3000);
      });

      this.setState({
         itemsearchfo: this.state.itemsearch.concat(Array.from({ length: 40 }))
      });
   }
   followusercancel = (e) => {
      if (this.state.followerSearch.length > 0) {
         var objIndex = this.state.followerSearch.findIndex((obj => obj.user_id == e.currentTarget.id));
         if (objIndex !== -1) {
            this.state.followerSearch[objIndex].isRequested = 0;
            this.setState({ followerSearch: this.state.followerSearch }, () => {
               this.mainProfileColor()
            });
         }
      }
      else {
         var objIndex = this.state.followers.findIndex((obj => obj.user_id == e.currentTarget.id));
         if (objIndex !== -1) {
            this.state.followers[objIndex].isRequested = 0;
            this.setState({ followers: this.state.followers }, () => {
               this.mainProfileColor()
            });
         }
      }
      const body = { user_id: e.currentTarget.id };
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/follow/cancelFollowRequest.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
         })
         .catch((error) => {
            this.setState({
               error: error.message
            });
            alert(error.response.data.message);
         });
   }
   unfollowuser(e) {
      this.setState({ action: e.currentTarget.id, ofname: e.currentTarget.dataset.div_name }, () => {
         $(".errorpop").toggle();
      })
   }
   actionclose() {
      $(".errorpop").toggle();
   }
   action() {
      $(".errorpop").toggle();
      if (this.state.followerSearch.length > 0) {
         var objIndex = this.state.followerSearch.findIndex((obj => obj.user_id == this.state.action));
         if (objIndex !== -1) {
            this.state.followerSearch[objIndex].isFollowing = 0;
            this.state.followerSearch[objIndex].isRequested = 0;
            this.setState({ followerSearch: this.state.followerSearch }, () => {
               this.mainProfileColor()
            });
         }
      }
      else {
         var objIndex = this.state.followers.findIndex((obj => obj.user_id == this.state.action));
         if (objIndex !== -1) {
            this.state.followers[objIndex].isFollowing = 0;
            this.state.followers[objIndex].isRequested = 0;
            this.setState({ followers: this.state.followers }, () => {
               this.mainProfileColor()
            });
         }
      }
      const body = { user_id: this.state.action };
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/follow/unFollow.php', body, {

         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {

         })
         .catch((error) => {
            this.setState({
               error: error.message
            });
            alert(error.response.data.message)
         })
   }

   followuser = (e) => {
      const localToken = localStorage.getItem("timeToken");
      if (this.state.followerSearch.length > 0) {
         var objIndex = this.state.followerSearch.findIndex((obj => obj.user_id == e.currentTarget.id.split("/")[0]));
         if (objIndex !== -1) {
            if (e.currentTarget.id.split("/")[1] == 1) {
               this.state.followerSearch[objIndex].isFollow = 1;
               this.setState({ followerSearch: this.state.followerSearch }, () => {
                  this.mainProfileColor()
               });
            }
            else {
               this.state.followerSearch[objIndex].isRequest = 1;
               this.setState({ followerSearch: this.state.followerSearch }, () => {
                  this.mainProfileColor()
               });
            }
         }
      }
      else {
         var objIndex = this.state.followers.findIndex((obj => obj.user_id == e.currentTarget.id.split("/")[0]));
         if (objIndex !== -1) {
            if (e.currentTarget.id.split("/")[1] == 1) {
               this.state.followers[objIndex].isFollow = 1;
               this.setState({ followers: this.state.followers }, () => {
                  this.mainProfileColor()
               });
            }
            else {
               this.state.followers[objIndex].isRequest = 1;
               this.setState({ followers: this.state.followers }, () => {
                  this.mainProfileColor()
               });
            }
         }
      }
      if (e.currentTarget.id.split("/")[1] == 1) {
         var body = {
            user_id: e.currentTarget.id.split("/")[0],
            isRequest: 1, followType: 'true'
         };
      }
      else {
         var body = {
            user_id: e.currentTarget.id.split("/")[0],
            isRequest: 0, followType: 'true'
         };
      }
      const token = localStorage.getItem('token');
      console.log('body e.currentTarget.id', e.currentTarget.id);
      //   axios.post(this.state.baseUrl+'/api/follow/sendFollowRequest.php',body, {
      axios.post(this.state.baseUrlNode + '/api/follow', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //"Authorization": "Bearer " + token,
            "x-access-token": localToken
         },
      })
         .then((res) => {

         })
         .catch((error) => {
            this.setState({
               error: error.message
            });
         })

   }

   fetchMoreData() {
      setTimeout(() => {
         this.setState({ activecolor: 'first' });
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         const totaluser = localStorage.getItem('followers');
         const recordp = totaluser / 40;
         if (this.state.perpage >= recordp) {
            this.setState({ hasMore: false });
            return;
         }
         this.setState({ perpage: this.state.perpage + 1 });
         fetch(this.state.baseUrl + '/api/follow/getFollowers.php?user_id=' + variable + '&page=' + this.state.perpage + '&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.followers.push(responseJson.response[i]);
               }
               if (this.state.mutuality != "") {
                  this.setState({ followers: this.state.followers.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() }, () => {
                     this.mainProfileColor()
                  });
               }
               if (this.state.alphabetically != "") {
                  this.setState({
                     followers: this.state.followers.sort((a, b) =>
                        a.screenName.localeCompare(b.screenName))
                  }, () => {
                     this.mainProfileColor()
                  });
               }
               else {
                  this.setState({ followers: this.state.followers }, () => {
                     this.mainProfileColor()
                  });
               }
            });
         this.setState({
            items: this.state.items.concat(Array.from({ length: 40 }))
            , loading: "false"
         });
      }, 1000);
   }

   weekforNext() {
      if (this.state.followers.length > 0 | this.state.followerSearch.length > 0) {
         return "loaderclass load";
      }
      else {
         return "loaderclass closeCd";
      }
   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   goback() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   render() {
      return (
         //   <div className="newscreen1 newscreen01">
         //   <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}   id="onetest_8">
         <div className={`${this.props.pos} screen1`} id="onetest_8">
            <div className="top" id="myHeader">
               <section className="header headernoti2">
                  <div className="row">
                     <div className="col-4 header_left pr-0">
                        <ul className="nav nav-tabs">
                           <li><a className="linkback" data-toggle="tab" onClick={this.goback.bind(this)}><img src="../../images/back.png" align="img" /></a></li>
                        </ul>
                     </div>
                     <div className="col-4 header_center pl-0 pr-0 text-center">
                        <h6>followers</h6>
                     </div>
                     <div className="col-4 header_right pl-0">
                        <ul className="uld">
                           <li className="more5"><a onClick={this.pops.bind(this)}><img src="../../images/more2.png" align="img" /></a></li>
                        </ul>
                     </div>
                  </div>
               </section>
               <div className="header_search">
                  <form className="form-inline mt-md-0">
                     <input className="form-control" id="lemon" type="text" onKeyUp={this.onKeyUpValueFollower.bind(this)} placeholder="search your followers" aria-
                        label="Search" />
                     {(() => {
                        if (this.state.croseIcone.length > 0) {
                           return (<span className="btn"><img className="" onClick={this.removefollowertext.bind(this)} src="../../images/close(1).png" /></span>);
                        }
                        else {
                           return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                        }
                     })()}

                  </form>
               </div>
            </div>


            <div className="tab-c1ontent followingmain">
               <div class={this.state.followerSearch.length > 0 || this.state.followers.length > 0 ? "loaderclass closeCd" : "loaderclass"}><img src="../../images/newgif.gif" alt="loading" /></div>
               <div className="row py-0 my-0">
                  <div
                     id="scrollableDivOne"
                     className="scrollClass newscreen1"
                     style={{
                        overflow: 'auto',
                        display: 'row',
                        flexDirection: 'row',
                        margintop: 47,
                     }}
                  >
                     {(() => {
                        if (this.state.followerSearch.length > 0) {
                           return (
                              <InfiniteScroll
                                 dataLength={this.state.itemsearchfo.length}
                                 next={this.fetchMoreDatafollowerSearch.bind(this)}
                                 className="row"
                                 style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                 inverse={false} //
                                 hasMore={true}
                                 loader={
                                    <div className={this.state.followerSearch.length > 0 ? "loaderclass" : "loaderclass closeCd"}>
                                       <img
                                          src="../../images/newgif.gif"
                                          alt="loading"
                                       />
                                    </div>}

                                 endMessage={
                                    <p style={{ textAlign: "center" }}>
                                       <b>Yay! You have seen it all</b>
                                    </p>
                                 }
                                 useWindow={false}
                                 scrollableTarget="scrollableDivOne"
                              >

                                 {this.state.followerSearch.map((c, i) => {

                                    return (

                                       <div className="col-12">
                                          <div className="userin userinfollowers">
                                             <div className="userintest" id={c.user_id} data-div_name="#onetest_8" >
                                                <Link className="might" to={{
                                                   pathname: `/${c.username}`,
                                                   state: { id: c.user_id }
                                                }} onClick={this.setStorage.bind(this)}
                                                >
                                                   <span class="imgg">
                                                      {c.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(c.screenName)}</span>}
                                                   </span>
                                                   <h6 >{c.screenName}</h6>
                                                   {/* <p >{c.mutual} mutual</p> */}
                                                </Link>
                                             </div>
                                             {(() => {
                                                if (c.isFollowing == 0 && c.make_public == 1) {
                                                   return (<h5><span id={c.user_id + '/' + c.make_public} onClick={this.followuser.bind(this)} className="follow" >follow</span></h5>);
                                                }
                                                else if (c.isFollowing == 0 && c.make_public == 0 && c.isRequest == 0) {
                                                   return (<h5><span id={c.user_id + '/' + c.make_public} onClick={this.followuser.bind(this)} className="follow" >request</span></h5>);
                                                }
                                                else if (c.make_public == 0 && c.isRequest == 1) {
                                                   return (<h5><span id={c.user_id} onClick={this.followusercancel.bind(this)} >requested</span></h5>);
                                                }
                                                else if (c.isFollowing == 1) {
                                                   return (<h5><span id={c.user_id} data-div_name={c.screenName} onClick={this.unfollowuser.bind(this)}>following</span></h5>);
                                                }
                                             })()}
                                          </div>
                                       </div>
                                    )
                                 }
                                 )
                                 }
                              </InfiniteScroll>
                           )
                        }
                        else {

                           return (
                              <InfiniteScroll
                                 dataLength={this.state.items.length}
                                 next={this.fetchMoreData.bind(this)}
                                 style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                 inverse={false} //
                                 className="row"
                                 hasMore={true}
                                 loader={
                                    <div className={this.weekforNext()}>
                                       <img
                                          src="../../images/newgif.gif"
                                          alt="loading"
                                       />
                                    </div>}

                                 endMessage={
                                    <p style={{ textAlign: "center" }}>
                                       <b>Yay! You have seen it all</b>
                                    </p>
                                 }
                                 useWindow={false}
                                 scrollableTarget="scrollableDivOne"
                              >
                                 {
                                    this.state.followers.map((p, i) => {
                                       return (
                                          <div className="col-12">
                                             <div className="userin userinfollowers">

                                                <div className="userintest" id={p.user_id} data-div_name="#onetest_8">
                                                   <Link className="might" to={{
                                                      pathname: `/${p.username}`,
                                                      state: { id: p.user_id }
                                                   }} onClick={this.setStorage.bind(this)}
                                                   >
                                                      <span class="imgg">
                                                         {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(p.screenName)}</span>}
                                                      </span>
                                                      <h6 >{p.screenName}</h6>
                                                      {/* <p >{p.mutual} mutual</p> */}
                                                   </Link>
                                                </div>
                                                {(() => {
                                                   if (p.isFollowing == 0 && p.make_public == 1) {
                                                      return (<h5><span id={p.user_id + '/' + p.make_public} onClick={this.followuser.bind(this)} className="follow" >follow</span></h5>);
                                                   }
                                                   else if (p.isFollowing == 0 && p.make_public == 0 && p.isRequested == 0) {
                                                      return (<h5><span id={p.user_id + '/' + p.make_public} onClick={this.followuser.bind(this)} className="follow" >request</span></h5>);
                                                   }
                                                   else if (p.make_public == 0 && p.isRequested == 1) {
                                                      return (<h5><span id={p.user_id} onClick={this.followusercancel.bind(this)} >requested</span></h5>);
                                                   }
                                                   else if (p.isFollowing == 1) {
                                                      return (<h5><span id={p.user_id} data-div_name={p.screenName} onClick={this.unfollowuser.bind(this)}>following</span></h5>);
                                                   }
                                                })()}
                                             </div>
                                          </div>

                                       )
                                    }
                                    )

                                 }


                              </InfiniteScroll>
                           );

                        }
                     })()}
                  </div>
               </div>
            </div>

            <Footer {...this.props} />
            <div className="op pupup8">
               <div className="more5 h-100" onClick={this.pops.bind(this)} ></div>
               <ul>
                  <h5 className="text-center">sort</h5>
                  <li><a className="more5" id="secondRelevancy" onClick={this.relevancy.bind(this)} ><img src="../../images/More.png" /> relevancy</a></li>
                  {/* <li><a className="more5" id="secondMutuality" onClick={this.mutuality.bind(this)}><img src="../../images/mut.png"/> mutuality</a></li> */}
                  <li><a className="more5" id="secondAlphabetically" onClick={this.alphabetically.bind(this)}><img src="../../images/az.png" /> alphabetically</a></li>
                  <button className="upbtn more5" onClick={this.pops.bind(this)}>Cancel</button>
               </ul>
            </div>
            <div className="op errorpop mainpop">
               <div className="errorbtn error h-100" onClick={this.actionclose.bind(this)}></div>
               <ul>
                  <li className="lipost">{"Unfollow " + this.state.ofname.split(" ")[0]}</li>
                  <li>{"Are you sure you would like to unfollow " + this.state.ofname + ", " + localStorage.getItem("screenname").split(" ")[0] + "?"}</li>
                  <div className="newpop">
                     <button className="upbtn errorbtn" onClick={this.actionclose.bind(this)}>CANCEL</button>
                     <button className="upbtn errorbtn" onClick={this.action.bind(this)}>YES</button>
                  </div>
               </ul>
            </div>
         </div>
         // </div>
      )
   }
}
export default Myfollowers;