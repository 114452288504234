import { getValue } from "../objectHelper";

  export function removeDuplicateObjectsFromArray(array, key = "user_id"){
 
    return  [
      ...new Map(array.map((item) => [item[key], item])).values(),
    ];    

  }

/**
 * 
 * @param {object[]} array array of object from the elements to be removed
 * @param {any[]} filterArr array containing items which have to be removed
 * @param {string} key key of object which has the same value as filterArr
 * @returns 
 */
export function removeElementsFromArrayOfObjects(array, filterArr, key = "user_id"){
	return array.filter(obj => !filterArr.includes(obj[key]))
}

export function mergeArraysWithoutDuplicates(arr1, arr2){
	return Array.from(new Set([...arr1, ...arr2]).values())
}


/**
 * this function will return the sorted array of objects based on the provided key and sort order. 
 * It will keep the position same or in the last if the value for the key is not found
 * 
 * @param {object[]} array array to be sorted
 * @param {string} key the array will be sorted based on the value of this key
 * @param {boolean} asc order ascending? default: true 
 * @returns sorted array
 */
export function sortArrayOfObjects(array, key, asc = true) {
  // Check if the array is valid and not empty, and if the key is provided and is a string
  if (!array?.length || !key || typeof key !== 'string') {
      return array; // Return the original array if conditions are not met
  }

  // Create a shallow copy of the original array to avoid modifying the original array
  const arrayToSort = [...array];

  // Sort the array based on the provided key and sort order
  arrayToSort.sort((a, b) => {
    // Get the values of the key from objects a and b
    const valA = getValue(a, key);
    const valB = getValue(b, key);

    // Determine the sorting direction based on the sort order
    const sortDirection = asc ? 1 : -1;

    // Handle cases where the values are not found or not valid
    if (!valA && !(valA === 0) && !valB && !(valB === 0)) return 0; 
    if (!valA && !(valA === 0)) return 1; 
    if (!valB && !(valB === 0)) return -1; 

    // Compare the values and return the comparison result
    return valA > valB ? sortDirection : -sortDirection;
  });

  return arrayToSort; // Return the sorted array
}

export function arrayDeepCopy(array){
  return JSON.parse(JSON.stringify(array))
}