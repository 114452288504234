import React, { createContext, useContext, useState } from 'react';

const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [sharedData, setSharedData] = useState({ freshLoad: true, authUser: null, lastViewedUser: null, pageWiseData: {}, tabWiseData: {}});
  const [activeTab, setActiveTab] = useState("second")
  return (
    <MyContext.Provider value={{ sharedData, setSharedData, activeTab, setActiveTab }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => useContext(MyContext);

export {MyContext}
