import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from 'react-number-format';
import Footer from '../Footer';
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class Mutual extends React.Component {
   constructor(props) {
      super(props);
      let edit_id = null;
      let edit2 = null;
      const path = this.props.location.pathname;
      const params = this.props.location.pathname.split('/');
      if (params[params.length - 1] != 'Mutual') {
         edit_id = params[params.length - 1];
         edit2 = params[params.length - 2];
      }
      this.state = {
         cuserid: edit_id, statepage: 1, cscreenname: edit2, baseUrl: process.env.REACT_APP_BASE_URL
         , activecolor: 'page', mutuality_following: "", mutualSearch: [], croseIcone2: "", itemsearch: Array.from({ length: 40 }), alphabetically_following: "", perpagefo: 1, searchPerameter: "", mutual: [], itemsp: 0, itemsp: Array.from({ length: 40 }), hasMore: true
      }
   }
   mainProfileColor() {
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".username").css("color", "");
      $(".username").css("color", random_color.textColor);
      $(".username").css("background-color", "");
      $(".username").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   componentDidMount() {
      this.setState({ activecolor: 'page', perpagefo: 1, searchPerameter: "", mutual: [], itemsp: 0, itemsp: Array.from({ length: 40 }), hasMore: true });
      const variable = this.state.cuserid;
      const token = localStorage.getItem('token');
      var pagepro = 1;
      fetch(this.state.baseUrl + '/api/follow/getMutualUsers.php?user_id=' + variable + '&page=' + pagepro + '&limit=40', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (this.state.mutuality_following != "") {
               this.setState({ mutual: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() }, () => {
                  this.mainProfileColor();
               });
            }
            if (this.state.alphabetically_following != "") {
               this.setState({
                  mutual: responseJson.response.sort((a, b) =>
                     a.screenName.localeCompare(b.screenName))
               }, () => {
                  this.mainProfileColor();
               });
            }
            else {
               this.setState({ mutual: responseJson.response }, () => {
                  this.mainProfileColor();
               });
            }
         });
   }
   onKeyUpValueMutualy(event) {
      const variable = this.state.cuserid;
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/follow/mutualUserSearch.php?user_id=' + variable + '&page=1&search=' + event.target.value + '&limit=40', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({ mutualSearch: responseJson.response }, () => {
               this.mainProfileColor();
            });
         });
      this.setState({ searchPerameter: "", searchPerameter: event.target.value, statepage: 1 }, () => {
         this.mainProfileColor();
      });
      this.setState({
         itemsearch: Array.from({ length: 40 })
      });
      if (event.target.value.length > 0) {
         this.setState({ croseIcone2: event.target.value });
      }
      else {
         this.setState({ croseIcone2: "" });
      }
   }
   removeMutualtext() {
      document.getElementById("parentfollowing").value = "";
      this.setState({ mutualSearch: [] });
      this.setState({ croseIcone2: "" });
      this.componentDidMount();
   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   goback() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   relevancy_following() {
      $(".pupup9").toggle();
      this.setState({ mutuality_following: "", alphabetically_following: "" });
      this.componentDidMount();
   }
   fetchMoreDataMutualSearch() {
      const variable = this.state.cuserid;
      const token = localStorage.getItem('token');
      const totaluser = localStorage.getItem('followers');
      const pageno = 40;
      this.state.statepage = +40;
      this.setState({ statepage: this.state.statepage });
      if (this.state.mutualSearch.length >= pageno) {
         this.state.searchPage = +1;
         this.setState({ searchPage: this.state.searchPage });
      }
      else {
         this.setState({ hasMore: false });
         return;
      }
      setTimeout(() => {

         fetch(this.state.baseUrl + '/api/follow/mutualUserSearch.php?user_id=' + variable + '&page=' + this.state.searchPage + '&search=' + this.state.searchPerameter + '&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.mutualSearch.push(responseJson.response[i]);
               }
               this.setState({
                  mutualSearch: this.state.mutualSearch
               }, () => {
                  this.mainProfileColor();
               });
            }, 3000);
      });

      this.setState({
         itemsearch: this.state.itemsearch.concat(Array.from({ length: 40 }))
      });
   }
   fetchMoreDataMutual() {
      setTimeout(() => {
         this.setState({ activecolor: 'first' });
         const variable = this.state.cuserid;
         const token = localStorage.getItem('token');
         const totaluser = this.state.cmutual;
         const recordp = totaluser / 40;
         if (this.state.perpagefo >= recordp) {
            this.setState({ hasMore: false });
            return;
         }
         this.setState({ perpagefo: this.state.perpagefo + 1 });
         fetch(this.state.baseUrl + '/api/follow/getMutualUsers.php?user_id=' + variable + '&page=' + this.state.perpagefo + '&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.mutual.push(responseJson.response[i]);
               }
               if (this.state.mutuality_following != "") {
                  this.setState({ mutual: this.state.mutual.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() }, () => {
                     this.mainProfileColor();
                  });
               }
               if (this.state.alphabetically_following != "") {
                  this.setState({
                     mutual: this.state.mutual.sort((a, b) =>
                        a.screenName.localeCompare(b.screenName))
                  }, () => {
                     this.mainProfileColor();
                  });
               }
               else {
                  this.setState({ mutual: this.state.mutual }, () => {
                     this.mainProfileColor();
                  });
               }
            });
         this.setState({
            itemsp: this.state.itemsp.concat(Array.from({ length: 40 }))
         });
      }, 1000);
   }

   mutuality_following() {
      $(".pupup9").toggle();
      this.setState({ alphabetically_following: "", mutuality_following: "mutuality" });
      this.componentDidMount();
   }

   alphabetically_following() {
      $(".pupup9").toggle();
      this.setState({ mutuality_following: "", alphabetically_following: "alphabetically" });
      this.componentDidMount();
   }
   pops() {
      $(".pupup9").toggle();
   }
   weekforMutual() {
      if (this.state.mutual.length > 0) {
         return "loaderclass load";
      }
      else {
         return "loaderclass closeCd";
      }
   }
   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         console.log('ssssssssss', secondn);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   render() {
      return (
         //   <div>
         //   <div className="newscreen1 newscreen01">
         //   <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}  id="one_9">
         <div className={`${this.props.pos} screen1`} id="one_9">
            <div className="top" id="myHeader">
               <section className="header headernoti2">
                  <div className="row">
                     <div className="col-4 header_left pr-0">
                        <ul className="nav nav-tabs">
                           <li><a className="linkback" data-toggle="tab" onClick={this.goback.bind(this)}><img src="../../images/back.png" align="img" /></a></li>
                        </ul>
                     </div>
                     <div className="col-4 header_center pl-0 pr-0 text-center">
                        <h6>mutual</h6>
                     </div>
                     <div className="col-4 header_right pl-0">
                        <ul className="uld">
                           <li className="more6"><a onClick={this.pops.bind(this)}><img src="../../images/more2.png" align="img" /></a></li>
                        </ul>
                     </div>
                  </div>
               </section>
               <div className="header_search">
                  <form className="form-inline mt-md-0">
                     <input className="form-control" id="parentfollowing" type="text" onKeyUp={this.onKeyUpValueMutualy.bind(this)} placeholder={"search " + this.state.cscreenname.split(" ")[0] + "'s mutual friends"} aria-
                        label="Search" />
                     {(() => {
                        if (this.state.croseIcone2.length > 0) {
                           return (<span className="btn"><img className="" onClick={this.removeMutualtext.bind(this)} src="../../images/close(1).png" /></span>);
                        }
                        else {
                           return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                        }
                     })()}

                  </form>
               </div>
            </div>
            <div className="searchlst">
               <div class={this.state.mutualSearch.length > 0 || this.state.mutual.length > 0 ? "loaderclass closeCd" : "loaderclass onscroll"}><img src="../../images/newgif.gif" alt="loading" /></div>
               <div className="row py-0 my-0">
                  <div
                     id="scrollableDivSix"
                     className="scrollClass newscreen1"
                     style={{
                        overflow: 'auto',
                        display: 'row',
                        flexDirection: 'row',
                        margintop: 47,
                     }}
                  >
                     {(() => {
                        if (this.state.mutualSearch.length > 0) {
                           return (
                              <InfiniteScroll
                                 dataLength={this.state.itemsearch.length}
                                 next={this.fetchMoreDataMutualSearch.bind(this)}
                                 className="row"
                                 style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                 inverse={false} //
                                 hasMore={this.state.hasMore}
                                 loader={
                                    <div className={this.state.mutualSearch.length > 0 ? "loaderclass" : " loaderclass closeCd"}>
                                       <img
                                          src="../../images/newgif.gif"
                                          alt="loading"
                                       />
                                    </div>}
                                 useWindow={false}
                                 scrollableTarget="scrollableDivSix">
                                 {this.state.mutualSearch.map((s, i) => {
                                    return (
                                       <div className="col-6" >
                                          <div className="userin" id={s.user_id} >
                                             <Link className="might" to={{
                                                pathname: `/${s.username}`,
                                             }} onClick={this.setStorage.bind(this)}
                                             >
                                                <span class="imgg">
                                                   {s.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + s.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(s.screenName)}</span>}
                                                </span>
                                                <h6>{s.screenName}</h6>
                                                <p><NumberFormat value={s.mutual} displayType={'text'} thousandSeparator={true} /> mutual</p>
                                             </Link>
                                          </div>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           )
                        }
                        else {
                           return (
                              <InfiniteScroll
                                 dataLength={this.state.itemsp.length}
                                 next={this.fetchMoreDataMutual.bind(this)}
                                 className="row"
                                 style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                 inverse={false} //
                                 hasMore={this.state.hasMore}
                                 loader={<div id="track" className={this.weekforMutual()}>
                                    <img
                                       src="../../images/newgif.gif"
                                       alt="loading"
                                    />
                                 </div>
                                 }
                                 useWindow={false}
                                 scrollableTarget="scrollableDivSix"
                              >
                                 {this.state.mutual.map((p, i) => {
                                    return (
                                       <div className="col-6" >
                                          <div className="userin" id={p.user_id} >
                                             <Link className="might" to={{
                                                pathname: `/${p.username}`,
                                             }} onClick={this.setStorage.bind(this)}
                                             >
                                                <span class="imgg">
                                                   {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(p.screenName)}</span>}
                                                </span>
                                                <h6 >{p.screenName}</h6>
                                                <p ><NumberFormat value={p.mutual} displayType={'text'} thousandSeparator={true} /> mutual</p>
                                             </Link>
                                          </div>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           )
                        }
                     })()}


                  </div>
               </div>
            </div>
            <Footer {...this.props} />
            <div className="op pupup9">
               <div className="more6 h-100" onClick={this.pops.bind(this)}></div>
               <ul>
                  <h5 className="text-center">sort</h5>
                  <li><a className="more6" id="sixRelevancy" onClick={this.relevancy_following.bind(this)} ><img src="../../images/More.png" /> relevancy</a></li>
                  <li><a className="more6" id="sixMutuality" onClick={this.mutuality_following.bind(this)}><img src="../../images/mut.png" /> mutuality</a></li>
                  <li><a className="more6" id="sixAlphabetically" onClick={this.alphabetically_following.bind(this)}><img src="../../images/az.png" /> alphabetically</a></li>
                  <button className="upbtn more6" onClick={this.pops.bind(this)}>Cancel</button>
               </ul>
            </div>
         </div>
         // </div>
         //             </div>
      )
   }
}

export default Mutual;