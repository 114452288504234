import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Button } from 'reactstrap';
import { ControlBar, Player } from 'video-react';
import { getdateformat } from '../../utils/dateFormatterForTI/dateFormatter';
import { formatDate as formatDateKatex } from '../../utils/math';
import { fetchTeXCompiledCaption, modifyWebsiteName } from '../../utils/post/manageCaptions';
import { DateDifferenceContainer, handleDateDifferenceContainer } from "../../utils/profiles/dateDifference";
import { handleMarginsOfTimetablesParent, updateStylingOfUserCalendar } from '../../utils/profiles/handleCalendarStyling';

export default function Timetable({ selfProfile, isScreenVisible, subspaceNumber, calendar, userDetails, timetableData, states, updateTimetableData, updateStatesFromTimetable, updateActiveDatesOfTimetable, editDeletePost }) {
    const playerRefs = useRef({});
    const calendarRefs = useRef({});
    const [activeDate, setActiveDate] = useState();
    const [profileData, setProfileData] = useState();
    const [prevDate, setPrevDate] = useState('');
    const [nextDate, setNextDate] = useState('');
    const timeoutRefLable = useRef(null);
    const timeoutRefCalendar = useRef(null);
    const isCompressedCalender = timetableData.availableEventsOnDates?.length < 16;

    var lastCall = 0;

    useEffect(() => {
            setActiveDate(timetableData?.activeDate);
            setProfileData(timetableData.profileData);
    }, [timetableData])

    useEffect(() => {
        if (activeDate) {
            updateCalendarStyling(activeDate);
            fetchEventData(activeDate);
        }
    }, [activeDate])

    useEffect(() => {
        if (timetableData.activeDate) {
            getNextPrevDates(timetableData.activeDate);
        }
    }, [timetableData.activeDate])

    useEffect(() => {
        if (isScreenVisible) {
            let prevWidth = window.innerWidth;
    
            const handleResize = () => {
                const currentWidth = window.innerWidth;
                if (currentWidth !== prevWidth) {
                    updateMarginsOfTimetablesParent(timetableData.availableEventsOnDates);
                    prevWidth = currentWidth;
                }
            };
    
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);
    
    

    useEffect(() => {
        updateMarginsOfTimetablesParent(timetableData.availableEventsOnDates);
    }, [profileData])

    const updateCalendarData = (data) => {
        if (data) {
            updateTimetableData(data);
        }
    }

    const updateCalendarStyling = (date) => {
        const calendarRef = calendarRefs.current;

        //initially update the calendar styling according to available temporal information
        if (calendarRef) {
            updateStylingOfUserCalendar(
                calendarRef,
                date ? date : null,
                {
                    valueCalendarOther: activeDate,
                    availableEventsOnDates: timetableData.availableEventsOnDates,
                    appendedTiles: timetableData.appendedTiles,
                    allEventsList: states.events,
                },
                updateCalendarData,
            );
        } else {
            setTimeout(() => {
                updateCalendarStyling(date);
            }, 1);
        }
    }

    const updateMarginsOfTimetablesParent = (availableEvents) => {
        const calendarRef = calendarRefs.current;

        if (calendarRef && availableEvents.length) {
            handleMarginsOfTimetablesParent(calendarRef, availableEvents);
        }
    }

    const changeCurrentTimeOld = (seconds, playerId) => {
        const player = playerRefs.current[playerId];
        if (player) {
            const currentTime = player.getState().player.currentTime;
            player.seek(currentTime + seconds);
        }
    };

    const checkdateOther = (date) => {
        for (let index = 0; index < states.events.length; index++) {
            var dateads = stringToDate(states.events[index].eventOn, 'DD/MM/YYYY');
            var elx = new Date(dateads);
            var finaldate = moment(elx).format('MMYYYY');
            if (date == finaldate) {
                return states.events[index].eventOn;
            }
        }
    }

    const getNextPrevDates = (dates) => {
        const newDate = moment(dates).format('MMYYYY');
        var index_val = states.otherMonthYear.indexOf(newDate);
        let nextDate, prevDate;
        if (index_val == 0) {
            var previous = states.otherMonthYear[index_val + 1];
            setNextDate('');
            prevDate = checkdateOther(previous);
            setPrevDate(prevDate);
        }
        else {
            var previous = states.otherMonthYear[index_val + 1];
            var next = states.otherMonthYear[index_val - 1];
            nextDate = checkdateOther(next);
            prevDate = checkdateOther(previous);
            setNextDate(nextDate);
            setPrevDate(prevDate);
        }
        updateTimetableData({
            prevDate: prevDate,
            nextDate: nextDate,
        });
        return { nextDate, prevDate };
    }

    const onCalendarOtherUser = (e) => {
        // updateCalendarStyling(e); //update the styling according to available temporal information

        if (!timeoutRefCalendar.current) {
            timeoutRefCalendar.current = setTimeout(() => {
                const date = moment(e).format('DD-MM-YYYY');
                if (states.events.find(x => x.eventOn === date)) {
                    updateTimetableData({ activeDate: e }); // update the new active date
                }
                timeoutRefCalendar.current = null;
            }, 50);
        }
    }

    const fetchEventData = (date) => {
        const token = localStorage.getItem('token');
        const userId = userDetails.user_id;
        const formattedDate = moment(date).format('DD-MM-YYYY');

        let url = '';
        if (token) {
            url = process.env.REACT_APP_BASE_URL + '/api/event/profileEventDateWise.php?profileId=' + userId + '&date=' + formattedDate;
        } else {
            url = process.env.REACT_APP_BASE_URL + '/api/event/profileEventDateWiseGuest.php?profileId=' + userId + '&date=' + formattedDate;
        }
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "TimeZone": moment.tz.guess(),
                "Authorization": "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                updateTimetableData({ profileData: responseJson.response });
                const nextPrevDate = getNextPrevDates(date);

                const post = timetableData.savePost;
                if (post) {
                    const opendUser = userDetails.user_id;
                    const isUserPresent = post.findIndex((data) => data.user == opendUser);

                    if (isUserPresent !== -1) {
                        const updatedSavePost = {
                            savePost: post.map((data) => {
                                if (data.user === opendUser) {
                                    return {
                                        ...data,
                                        date: formattedDate,
                                        eventDetail: responseJson.response,
                                        nextMonthYear: nextPrevDate.nextDate,
                                        previousMonthyear: nextPrevDate.prevDate,
                                    };
                                } else {
                                    return data;
                                }
                            })
                        }
                        updateTimetableData(updatedSavePost);
                    }
                    else {
                        const data = {
                            savePost: [{
                                date: formattedDate,
                                user: userDetails.user_id,
                                eventDetail: responseJson.response,
                                allEvents: states.events,
                                minDate: states.otherMinimum,
                                maxDate: states.otherMaximum,
                                previousMonthyear: prevDate,
                                nextMonthYear: nextDate,
                                otherMonthYear: states.otherMonthYear
                            }]
                        }
                        updateTimetableData(data);
                    }
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    }

    const handleLabelChange = (action, value) => {
        if (!timeoutRefLable.current) {
            timeoutRefLable.current = setTimeout(() => {
                const currentMonth = formatDate(activeDate, "MMYYYY")
                const newMonth = formatDate(value, "MMYYYY")

                let updateAction = action
                let updateDate

                if (action === 'next') {
                    const formattedNextDate = stringToDate(nextDate, 'DD/MM/YYYY');
                    updateDate = new Date(formattedNextDate);
                } else if (action === 'prev') {
                    const formattedPrevDate = stringToDate(prevDate, 'DD/MM/YYYY');
                    updateDate = new Date(formattedPrevDate);
                }
                else if (action == 'onChange' && newMonth != currentMonth) {
                    const change = value.getTime() - activeDate.getTime();

                    if (!change)
                        return

                    updateAction = 'prev';
                    if (change > 0)
                        updateAction = 'next';

                    updateDate = value
                }
                if (updateDate) {
                    updateActiveDatesOfTimetable(updateDate, updateAction);
                    onCalendarOtherUser(updateDate);
                    getNextPrevDates(updateDate);
                }
                timeoutRefLable.current = null;
            }, 50); // timeout to remove the glitch between tiles updation
        }
    }

    const tileClassName = useMemo(() => {
        return ({ activeStartDate, date }) => {
            if (states.events.length > 0) {
                const formattedDate = formatDate(date, "DD-MM-YYYY");
                const event = timetableData.availableEventsOnDates.find(x => x.eventOn === formattedDate);

                if (formattedDate === formatDate(activeDate, "DD-MM-YYYY")) {
                    return 'orange';
                }

                let isActiveMonth = activeStartDate.getMonth() == date.getMonth()
                let displayAlternateTiles = userDetails.alternate_tiles && isActiveMonth
                let className = ""

                if (displayAlternateTiles) {
                    let dateInNumber = date.getDate()

                    let dividend = dateInNumber

                    if (isCompressedCalender) {
                        const isDatePresent = timetableData.availableEventsOnDates.findIndex((obj) => obj?.eventOn === formattedDate);
                        dividend = isDatePresent
                    }
                    className = dividend % 2 === 0 ? 'highlight' : 'darkblue';
                }
                // if (calendar == 'current' && subspaceNumber == 1)
                // console.log(date.getDate());
                
                if (event) {
                    if (displayAlternateTiles){
                        return className
                    }
                    if (event.importantEvents) {
                        return 'darkblue';
                    }

                    if (event.dateCount === 1 && formattedDate !== formatDate(new Date(), "DD-MM-YYYY")) {
                        return 'highlight';
                    }

                    if (event.dateCount !== 1 && formattedDate !== formatDate(new Date(), "DD-MM-YYYY")) {
                        return 'darkblue';
                    }

                    const todayEventIndex = states.events.findIndex(x => x.eventOn === formatDate(new Date(), "DD-MM-YYYY"));
                    if (todayEventIndex !== -1) {
                        const todayEventCount = states.events[todayEventIndex].dateCount;
                        return todayEventCount === 1 ? 'green' : 'darkgreen';
                    }

                    return 'yellow';
                } else {
                    return  'blocklight' + (isCompressedCalender ? " dateOff" : '');
                }
            }
            return '';
        };
    }, [activeDate, profileData]);

    return (
        <>
            {activeDate && <div className={`sectionone sectionone_div ${calendar}`}>
                <div className={(!userDetails?.bio || !userDetails?.website) ? "inkap emptybio prin" : "inkap"}>
                    <div className="inkapil">
                        {/* {userDetails?.isFollowing == 0 && userDetails?.make_public == 0 ?
                            <div className="blankcalendar requestcalender">
                                <div className="testc"><p>{userDetails?.screenName ? "You must follow " + userDetails?.screenName + " to view any of " + userDetails?.screenName.split(" ")[0] + "'s post, " + localStorage.getItem('screenname').split(" ")[0] : ""}</p></div>
                                <span><img src="../../images/calendar.png" alt="image" /></span>
                            </div> : states.events.length > 0 ?  */}
                        <div>
                            {/* <div id="five1" class="likeaddto"><img class="w-100" src="../../images/More.png" alt="image" />
                                </div>
                                <div class="likeaddtop" id="four1"><img class="w-100" src="../../images/Erase.png" alt="image" /></div> */}
                            <div class="table" ref={calendarRefs}>
                                <Calendar
                                    onChange={(e) => onCalendarOtherUser(e)} // timeout to remove the glitch between tiles updation
                                    activeStartDate={activeDate}
                                    locale="en-US" // Set locale to US English
                                    firstDayOfWeek={0}
                                    minDate={timetableData.minDate}
                                    maxDate={timetableData.maxDate}
                                    onActiveStartDateChange={({ action, value }) => handleLabelChange(action, value)} // timeout to remove the glitch between tiles updation
                                    tileClassName={tileClassName}
                                />
                            </div></div>
                    </div>
                </div>
                {userDetails?.isFollowing == 1 || userDetails?.make_public == 1 && <div className="mb-2">
                    {profileData && profileData?.length > 0 ? profileData.map((j, x) => {
                        return (
                            <div className='temporal-information-block'>
                                <div class="likeaddto" id={"promo" + j.eventID}><img class="w-100" src="../../images/More.png" alt="image" /></div>
                                <div class="likeaddto" id={"promi" + j.eventID}><img class="w-100" src="../../images/Erase.png" alt="image" /></div>
                                <div id={"crox" + j.eventID} className="postlike"
                                >
                                    <div className="userin rmrf">
                                        <h1 className='new-heading-tex-789' onMouseOver={(e) => handleDateDifferenceContainer(e, subspaceNumber)}
                                            dangerouslySetInnerHTML={{ __html: formatDateKatex(getdateformat(j.postedOn, j.postedOn2, j.timezone)) }}
                                        ></h1>
                                        <DateDifferenceContainer />
                                        <h5>
                                            <div className='katexParentBlock'>
                                                {j?.link ?
                                                    <a href={j?.link} target='_blank'>
                                                        <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status) }} />
                                                        <p className='hoveredLink'>{modifyWebsiteName(j?.link)}</p>
                                                    </a> :
                                                    (<div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status) }} />)}
                                            </div>
                                        </h5>
                                    </div>
                                    <div className="post ">
                                        {(() => {
                                            if (j.mediaType == "video") {
                                                return (
                                                    <div id={'Video' + j.eventID} class="video-div">
                                                        <Player
                                                            key={j.eventID}
                                                            ref={player => {
                                                                playerRefs.current[j.eventID] = player;
                                                            }}
                                                            id={'Videos' + j.eventID}
                                                            autoPlay={false}
                                                            className="video-react-has-started"
                                                        >
                                                            <source src={process.env.REACT_APP_BASE_URL + j.eventImage} type="video/mp4" />
                                                            <ControlBar autoHide={false} className="my-class" />
                                                        </Player>
                                                        <ul class="videoulkap pb-3">
                                                            <li><Button data-id={"player" + j.id} onClick={() => changeCurrentTimeOld(-10, j.eventID)} ><img class="img1" src="../../images/Backwards.png" />
                                                                <span>10</span></Button>
                                                            </li>
                                                            <li><img class="img2" src="../../images/pause.png" /></li>
                                                            <li><Button data-id={j.id} onClick={() => changeCurrentTimeOld(10, j.eventID)} ><img class="img3" src="../../images/Forwards.png" />
                                                                <span>10</span></Button>
                                                            </li>
                                                        </ul>
                                                    </div>);
                                            }
                                            else if (j.mediaType == "image") {
                                                return (
                                                    <img className="w-100 mt-1" src={process.env.REACT_APP_BASE_URL + j.eventImage} alt="image" />
                                                );
                                            }
                                        }
                                        )()}
                                        <div className="hearti">
                                            <img className="w-100" src="../../images/cklick.png" alt="image" />
                                        </div>
                                    </div>
                                </div>
                                {selfProfile ? (
                                    <ul className={j.mediaType == null || j.mediaType == "" ? "videoul mt3 status" : "videoul mt3"} ><li>
                                        <a className="upbutton" href="javascript:void(0)" id={j.eventID} onClick={(e) => editDeletePost(e)}><img className="option ml-3" src="../../images/pencil.png" align="icon" /></a>
                                    </li></ul>
                                ) : ''}
                            </div>
                        )
                    })
                        : ""}
                </div>}
            </div>
            }
        </>
    )
}

export const weekDays = (dayNumber) => {
    if (dayNumber == 0) {
        return "Sunday";
    }
    if (dayNumber == 6) {
        return "Saturday";
    }
    if (dayNumber == 5) {
        return "Friday";
    }
    if (dayNumber == 4) {
        return "Thursday";
    }
    if (dayNumber == 3) {
        return "Wednesday";
    }
    if (dayNumber == 2) {
        return "Tuesday";
    }
    if (dayNumber == 1) {
        return "Monday";
    }
}

export const stringToDate = (dateString, format) => moment(dateString, format)

export const formatDate = (date, format) => moment(date).format(format)
