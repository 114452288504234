import moment from "moment";

export function updateStylingOfUserCalendar(calendarRef, date, stateObj, updateCalendarData) {
   if (calendarRef) {
      let datesOfThisMonth = stateObj.availableEventsOnDates;
      if (stateObj.allEventsList) {
         handleMarginsOfTimetablesParent(calendarRef, datesOfThisMonth);

         const updateStylingOfTiles = (parent, tiles, activeMonth, tilesLength) => {
            let startingDate = null;
            let endDate = null;
            let prevMonthDates = { array: [], index: null };
            let nextMonthDates = { array: [], index: null };

            parent.classList.remove('compressedCalendar', 'fullCalendar');
            const calendar = datesOfThisMonth?.length <= 15 ? 'compressedCalendar' : 'fullCalendar';
            parent.classList.add(calendar); // add tha parent className for the calendars

            tiles.forEach((item, index) => {
               const childNode = item?.childNodes[0];
               const dateValue = childNode?.ariaLabel;

               // check and remove the last appended tiles
               if (stateObj.appendedTiles.data?.length) {
                  if (childNode) {
                     stateObj.appendedTiles.data.forEach((date) => {
                        if (date == dateValue && stateObj.appendedTiles.appendedMonth != activeMonth) {
                           item.remove();
                           stateObj.appendedTiles.data.shift();
                           return;
                        }
                     })
                  }
               }

               if (index >= 0 && datesOfThisMonth?.length) {
                  const childNode = item?.childNodes[0];
                  if (!childNode) return;
              
                  const date = moment(childNode.ariaLabel, 'MMMM DD, YYYY').format('DD-MM-YYYY');

                  if (date == datesOfThisMonth[0]?.eventOn) {
                     endDate = date;
                  }
                  if (date == datesOfThisMonth[datesOfThisMonth?.length - 1]?.eventOn) {
                     startingDate = date;
                  }

                  if (childNode.innerText == 1 && index >= 0 && prevMonthDates.index == null) {
                     prevMonthDates.array = Array.from(tiles).slice(0, index);
                     prevMonthDates.index = index - 1;
                  } else if (childNode.innerText == 1) {
                     nextMonthDates.array = Array.from(tiles).slice(index, tiles.length);
                     nextMonthDates.index = index;
                  }
               }
            });

            if (datesOfThisMonth?.length && tilesLength != 'auto') {
               const totalDatesLength = datesOfThisMonth.length;
               const prevNextTotalLength = prevMonthDates?.array.length + nextMonthDates?.array.length;
               const lengthDiff = tilesLength - totalDatesLength;

               const length = lengthDiff <= prevNextTotalLength ? lengthDiff : prevNextTotalLength;
               const needToAddTile = lengthDiff > prevNextTotalLength ? tilesLength - (totalDatesLength + prevNextTotalLength) : null;

               toggleVisibilityOfTiles(prevMonthDates, nextMonthDates, length, tiles, needToAddTile, activeMonth, updateCalendarData);
            }
         };

         const datesContainer = calendarRef?.querySelector('.react-calendar__month-view__days');
         const weekDaysContainer = calendarRef?.querySelector('.react-calendar__month-view__weekdays');
         const dates = datesContainer?.querySelectorAll('.react-calendar__month-view__days__day');
         const parent = calendarRef.querySelector('.react-calendar');

         const centerTile = dates[Math.round(dates?.length / 2)];
         const childTile = centerTile?.childNodes[0];
         const monthDate = moment(childTile?.ariaLabel, 'MMMM DD, YYYY').format('MMMM YYYY');
         const activeMonthDate = moment(datesOfThisMonth[0]?.eventOn, 'DD-MM-YYYY').format('MMMM YYYY');

         if (date && monthDate != activeMonthDate) {
            setTimeout(() => {
               updateStylingOfUserCalendar(calendarRef, date, stateObj, updateCalendarData);
            })
            return;
         }

         const activeTiles = datesOfThisMonth?.length;
         let tileCount = activeTiles <= 3 ? 3 
              : activeTiles <= 6 ? 6 
              : activeTiles <= 9 ? 9 
              : activeTiles <= 12 ? 12 
              : activeTiles <= 15 ? 15 
              : 'auto';
         const updateNavigation = tileCount === 'auto' ? false : true;

         if (tileCount) {
            updateStylingOfTiles(parent, dates, activeMonthDate, tileCount);
            updateCalendarNavigation(weekDaysContainer, updateNavigation)
         } 
      }
   }
}

export function handleMarginsOfTimetablesParent(calendarRef, datesOfThisMonth) {
   if (calendarRef && Object.keys(calendarRef)?.length && datesOfThisMonth.length) {
      const parent = calendarRef?.parentNode?.parentNode?.parentNode?.parentNode;
      const topParent = parent?.parentNode;
      const timetableElements = topParent?.querySelectorAll('.sectionone');

      let isFirstPairPresent = false;
      let isSecondPairPresent = false;
      let isThirdPairPresent = false;
      let pairs = [];

      if (timetableElements?.length) {
         timetableElements.forEach((element, index) => {
            element.style.removeProperty('margin');
            const val = element.getBoundingClientRect()?.top;
            if (index > 0 && val === timetableElements[index - 1].getBoundingClientRect()?.top) {
               isFirstPairPresent = index === 1 ? true : isFirstPairPresent;
               isSecondPairPresent = index === 3 ? true : isSecondPairPresent;
               isThirdPairPresent = index === 5 ? true : isThirdPairPresent;
               pairs.push(index - 1);
               pairs.push(index);
            }
         });
      }

      timetableElements.forEach((element, index) => {
         const shouldNotApplyMarginTill = isFirstPairPresent ? 1 : 0;
         if (index > shouldNotApplyMarginTill) {
            element.style.margin = pairs.includes(index) ? '30px 0 0 0' : '30px auto 0';
         }
         if (index <= shouldNotApplyMarginTill && !isFirstPairPresent) {
            element.style.setProperty('margin', '0 auto', 'important');
         }
      });

      updateMarginsOfTimetablesParent(timetableElements, isFirstPairPresent, isSecondPairPresent, isThirdPairPresent);

      setTimeout(() => {
         if (topParent) {
            topParent.parentNode.style.removeProperty('min-height');
            topParent.parentNode.style.minHeight = `${topParent?.offsetHeight }px`;
         }
      }, 100);
   }
}

function updateMarginsOfTimetablesParent(timetableElements, isFirstPairPresent, isSecondPairPresent, isThirdPairPresent) {
   if ((isFirstPairPresent && isSecondPairPresent) || (isSecondPairPresent && isThirdPairPresent)) {
      let spaceBetween1and3 = null, spaceBetween2and4 = null, spaceBetween3and5 = null, spaceBetween4and6 = null;

      const applyMargin = (element, space) => {
         if (space !== null && space > 1) {
            element.style.marginTop = `-${space}px`;
         }
      };

      const calculateExtraSpace = (index1, index2, index3, variable) => {
         const elementToBeAdjusted = index3 || index2;
         if (index3)
            variable = timetableElements[index3].offsetTop - ((timetableElements[index1].offsetHeight + 105) + (timetableElements[index2].offsetHeight + 60));
         else
            variable = timetableElements[index2].offsetTop - (timetableElements[index1].offsetHeight + 105);
         applyMargin(timetableElements[elementToBeAdjusted], variable);
      }

      if (isFirstPairPresent && isSecondPairPresent) {
         calculateExtraSpace(0, 2, null, spaceBetween1and3);
         calculateExtraSpace(1, 3, null, spaceBetween2and4);
      }
      if (isSecondPairPresent && isThirdPairPresent) {
         calculateExtraSpace(0, 2, 4, spaceBetween3and5);
         calculateExtraSpace(1, 3, 5, spaceBetween4and6);
      }
   }
}

function updateCalendarNavigation(weekDaysContainer, update) {
   if (update) {
      weekDaysContainer.style.setProperty('--display-weekdays', 'none');
   } else {
      weekDaysContainer.style.removeProperty('--display-weekdays');
   }
};

function toggleVisibilityOfTiles(prevMonthDates, nextMonthDates, length, tiles, needToAddTile, activeMonth, updateCalendarData) {
   let togglePrev = true; // true for previous, false for next
   let prevIndex = prevMonthDates?.index;
   let nextIndex = nextMonthDates?.index;

   for (let index = 0; index < length; index++) {
      if (togglePrev) {
         if (prevIndex != null && prevIndex >= 0) {
            tiles[prevIndex].classList.remove('dateOff');
            prevIndex -= 1;
         } else if (nextIndex != null && nextIndex < tiles.length) {
            tiles[nextIndex].classList.remove('dateOff');
            nextIndex += 1;
         }
      } else {
         if (nextIndex != null && nextIndex < tiles.length) {
            tiles[nextIndex].classList.remove('dateOff');
            nextIndex += 1;
         } else if (prevIndex != null && prevIndex >= 0) {
            tiles[prevIndex].classList.remove('dateOff');
            prevIndex -= 1;
         }
      }
      togglePrev = !togglePrev;
   }

   if (needToAddTile) {
      let lastAddedDate = null;
      let addedTilesData = [];
      let addedTilesmonth = null;
      for (let index = 0; index < needToAddTile; index++) {
         const newButton = document.createElement('button');
         newButton.className = 'react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend react-calendar__month-view__days__day--neighboringMonth blocklight appendedTile';
         newButton.type = 'button';
         newButton.style.cssText = `flex: 0 0 14.2857%; overflow: hidden;`;

         let dateStr = null;
         if (!lastAddedDate) {
            dateStr = tiles[tiles?.length - 1].childNodes[0].ariaLabel;
            lastAddedDate = dateStr;
         } else {
            dateStr = lastAddedDate;
         }
         const date = moment(dateStr, 'MMMM D, YYYY');
         const nextDate = date.add(1, 'days');
         const nextDateStr = nextDate.format('MMMM D, YYYY');
         const nextDayNumber = nextDate.date();

         // Create the abbr element
         const abbr = document.createElement('abbr');
         abbr.setAttribute('aria-label', nextDateStr);
         abbr.textContent = nextDayNumber;

         // Append the abbr element to the button
         newButton.appendChild(abbr);

         // Get the parent element of the tiles NodeList
         const tilesParent = tiles[0]?.parentNode;

         // Append the button to the parent element
         if (tilesParent) {
            tilesParent.appendChild(newButton);
         }
         if (lastAddedDate) {
            lastAddedDate = nextDateStr;
         }

         addedTilesData.push(nextDateStr);
         addedTilesmonth = activeMonth;
      }
      updateCalendarData({
         appendedTiles: {
            data: addedTilesData,
            appendedMonth: addedTilesmonth,
         },
         //  availableEventsOnDates: datesOfThisMonth,
      })
   }
}