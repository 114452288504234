import React from 'react';
import { MyContext } from '../../context';
import { getGrammarContainer, handleGrammarContainer, removeGrammarContainer } from '../../utils/footer/handleGrammarContainer.js';
import { getDualTooltipContainer, getTooltipContainer, handleDualTooltipContainer, handleOrganizationTree, handleTooltipContainer } from '../../utils/footer/handleTooltipContainer.js';
import { getRomanCountOfSubspacesInStack } from '../../utils/footer/romanCountOfSubspacesInStack.js';
import { getTabWithMostOpenProfilesInStack, getTabwiseOpenProfileScreenNames } from '../../utils/footer/tabWithMostOpenProfilesInStack.js';
import { formatOrganizationName } from '../../utils/math';
import { showInitials } from '../../utils/profiles/shared';
import { isPathADefaultTabPath } from '../../utils/routingHelpers/checkPath';
import { ReactComponent as CalendarSvg } from '../../utils/svg/Calendar.svg';
import { ReactComponent as DownwardArrow } from '../../utils/svg/Down-facing-arrow.svg';
import { ReactComponent as HandShakeSvg } from '../../utils/svg/Handshake.svg';
import { ReactComponent as NewsPaperSvg } from '../../utils/svg/Newspaper.svg';
import { ReactComponent as ZZSvg } from '../../utils/svg/ZZ.svg';
import { loginPagePathname } from '../Login';

class GuestFooter extends React.Component {
   static contextType = MyContext
   constructor(props) {
      super(props);
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, recomended: [], number: false, activeTab: 'second',
         f1: {}, f2: {}, f3: {}, s1: {}, s2: {}, s3: {}, t1: {}, t2: {}, t3: {}, activeProfile: '', destinationInfo: '', screenWidth: window.innerWidth,
         pathname: this.props.location.pathname,
         tabWithMostOpenProfiles: '', openProfilesInStack: '', numeralOpenProfilesInStack: '',
         tabwiseStackInfo: {
            first: { lengthInRoman: "" },
            second: { lengthInRoman: "" },
            third: { lengthInRoman: "" }
         }, folder1: {}, folder2: {}
      }
      this.userBadgeRef = React.createRef();
      this.webBadgeRef = React.createRef();
      this.orgTreeRef = React.createRef();
      this.updateImage = this.updateImage.bind(this);
   }

   // setSubscript() {
   //    // console.log("hi");
   //    if ($(" .newscreen01 .footer-menu  li a ").is(".active2"))
   //       $(".newscreen01 .footer-menu li a sub").html(`<DownwardArrow onMouseOver={()=>handleTooltipContainer()} />`)
   //    else
   //       $(".newscreen01 .footer-menu li a sub").html(``)
   // }

   componentDidMount() {
      this.relevancySearch();
      this.setState({ activeTab: this.context.activeTab });
      this.addDefaultOpenProfiles()
      this.showInitialsOfOpenProfilesOnTab();
      this.getActiveUser();
      window.addEventListener('resize', this.updateImage);

      const handleElementLoaded = () => {
         if (this.orgTreeRef.current) {
            handleOrganizationTree(this.orgTreeRef.current, 1);
         }
     };

     handleElementLoaded();
   }
   componentWillUnmount() {
      clearInterval(this.interval);
      window.addEventListener('resize', this.updateImage);
   }
   componentWillMount() {
      const props = this.props;
      if (props?.location?.pathname == '/') {
         const { appContext } = props;
         const { state, actions } = appContext;
         if (!state.homePageActive.status) {
            actions.setHomePageActive({
               status: true,
               activeHomePageSubspace: 1,
            });
         }
      }
   }

   componentDidUpdate(_, prevState) {
      const { activeTab } = this.context

      if (this.props.isParentUserProfile && activeTab !== prevState.activeTab && !isPathADefaultTabPath(this.props.location.pathname) && prevState.pathname == this.props.location.pathname) {
         // console.log(`C update: isParentUserProfile: ${this.props.isParentUserProfile}\nold activeTab: ${prevState.activeTab} new activeTab: ${activeTab} \nprev.pathname: ${prevState.pathname} current.Pathname: ${this.state.pathname}`);
         this.props.setShouldUpdateOnSamePath(true)
         this.setState({ activeTab })
         this.updateOpenProfilesOnActiveTab(this.props.location.pathname).then(() => this.showInitialsOfOpenProfilesOnTab())
      }
   }

   componentWillReceiveProps(nextProps) {
      // this.getFolderWiseData();
   }

   getFolderWiseData() {
      const folderData = this.props.appContext.state.folderData;

      const folder1TabwiseOpenProfileScreenNames = folderData?.folder1 && getTabwiseOpenProfileScreenNames(folderData?.folder1);
      const folder2TabwiseOpenProfileScreenNames = folderData?.folder2 && getTabwiseOpenProfileScreenNames(folderData?.folder2);

      this.setState({ folder1: folder1TabwiseOpenProfileScreenNames, folder2: folder2TabwiseOpenProfileScreenNames });
   }

   setStorage(arg) {
      localStorage.setItem("backid", "front");


      const { appContext } = this.props;
      const { state, actions } = appContext;

      if (arg === this.state.activeTab && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 1) {
         return;
      }

      const { setActiveTab, sharedData, setSharedData } = this.context
      const openProfiles = sharedData.tabWiseData[arg]?.openProfiles;

      if (arg !== 'second' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 1) {
         actions.setHomePageActive({
            status: false,
            activeHomePageSubspace: null,
         });
      }

      const entry = JSON.parse(localStorage.getItem('entry'));

      let loginPagePath = "/login"
      let pageDataForLogin = sharedData.pageWiseData[loginPagePathname]
      if (pageDataForLogin && pageDataForLogin.showSignUp)
         loginPagePath = "/sign-up"

      const routeAndTab = {
         "first": "/settings",
         "second": state.homePageActive.status ? `/${entry[0]?.username}` : "/",
         "third": loginPagePath,
      }


      if (this.state.activeTab === arg) {
         let openProfilesUpdated = [];

         if (arg === 'second') {
            if (!state.homePageActive.status)
               actions.setHomePageActive({
                  status: true,
                  activeHomePageSubspace: 1,
               })
            else {
               openProfilesUpdated = [routeAndTab[arg]]
               this.props.setShouldUpdateOnSamePath(true)
            }
         }

         sharedData.tabWiseData[arg] = { ...sharedData.tabWiseData[arg], openProfiles: [...openProfilesUpdated] }
         setSharedData(() => {
            return {
               ...sharedData,
            }
         });

         this.props.history.push(routeAndTab[arg]);

      } else {
         if (openProfiles?.length > 0) {
            this.addProfilePathsToHistory(openProfiles[openProfiles.length - 1], openProfiles, arg);
            this.props.setVisitedPaths(openProfiles);
         } else {
            if (arg === 'second' && !state.homePageActive.status && routeAndTab[arg] == '/') {
               this.props.history.push('/');
               actions.setHomePageActive({
                  status: true,
                  activeHomePageSubspace: 1,
               });
            } else {
               this.props.history.push(routeAndTab[arg]);
            }
         }
      }

      if (typeof arg !== "string") {
         arg = ""
      } else {
         setActiveTab(() => arg)
      }

      if (this.props.updateClickedButtonToExit) {
         this.props.updateClickedButtonToExit(arg)
      }
      this.showInitialsOfOpenProfilesOnTab()
   }

   getActiveUser() {
      if (this.props.location?.pathname == '/self') {
         this.setState({ activeProfile: localStorage.getItem('screenname') });
      } else {
         const { sharedData } = this.context;
         const activeTab = this.context.activeTab
         let user = '';
         let activeTabData = sharedData.tabWiseData[activeTab];
         const entry = JSON.parse(localStorage.getItem('entry'));
         if (activeTabData) {
            const pathname = activeTabData?.openProfiles[activeTabData?.openProfiles?.length - 1];
            if (pathname) {
               user = entry.find((user) => user.username == pathname?.substring(1));
               if (user) {
                  this.setState({ activeProfile: user?.screenName });
               } else {
                  setTimeout(() => {
                     this.getActiveUser();
                  }, 1000);
               }
            }
         }
      }
   }

   openUserProfile(path, tab) {
      const { appContext } = this.props;
      const { state, actions } = appContext;

      if (tab !== 'second' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 1) {
         actions.setHomePageActive({
            status: false,
            activeHomePageSubspace: null,
         });
      }

      const { sharedData } = this.context;
      const openedProfiles = sharedData.tabWiseData[tab].openProfiles;
      if (this.state.activeTab !== tab) {
         this.addProfilePathsToHistory(path, openedProfiles, tab);
         this.context.setActiveTab(tab);
      } else {
         this.context.setActiveTab(tab);
         this.props.history.push(path);
      }
   }

   addProfilePathsToHistory(visitedPath, openProfiles, tab) {
      const token = localStorage.getItem('token');
      const tabToPushFirst = tab === 'first' && !token ? '/settings' : tab === 'first' && token ? '/self' :
         tab === 'second' ? '/' :
            tab === 'third' && !token ? '/login' : tab === 'third' && token ? '/upload' : '/';

      window.history.pushState({ enteredBy: "pushState" }, "", tabToPushFirst)

      if (openProfiles[openProfiles.length - 1].includes(visitedPath)) {
         openProfiles.slice(0, openProfiles.length - 1).forEach(path => {
            window.history.pushState({ enteredBy: "pushState" }, "", path);
         });
         this.props.history.push(visitedPath);
      } else {
         openProfiles.forEach(path => {
            window.history.pushState({ enteredBy: "pushState" }, "", path);
         });
         this.props.history.push(visitedPath);
      }
   }

   async showInitialsOfOpenProfilesOnTab() {
      const { sharedData } = this.context;
      const tabWiseData = sharedData.tabWiseData;

      this.updateFolderWiseData()

      if (!(tabWiseData.first?.openProfiles?.length || tabWiseData.second?.openProfiles?.length || tabWiseData.third?.openProfiles?.length)) {
         return {}
      }

      if (!localStorage.entry)
         return await new Promise((resolve) => setTimeout(() => resolve(this.showInitialsOfOpenProfilesOnTab()), 600))


      this.updateSubspaceStackIndicator(tabWiseData)

      const entry = JSON.parse(localStorage.entry);

      const getUserInfo = (pathname) => {
         const user = entry.find((user) => user.username == pathname.substring(1));
         const data = { userName: user?.username, screenName: user?.screenName }
         return data;
      }

      const showData = (profiles, tab) => {
         if (!profiles || !profiles.openProfiles) {
            return {};
         }

         const dataLength = profiles?.openProfiles.length;
         var result = {};

         if (profiles.openProfiles && profiles.openProfiles.length > 0) {
            const openProfiles = profiles.openProfiles;
            result[`${tab}1`] = openProfiles.length === 1 ? getUserInfo(openProfiles[dataLength - 1]) : openProfiles.length === 2 ? getUserInfo(openProfiles[dataLength - 2]) : openProfiles.length >= 3 ? getUserInfo(openProfiles[dataLength - 3]) : {};
            result[`${tab}2`] = (openProfiles.length === 1 || openProfiles.length === 0) ? {} : openProfiles.length === 2 ? getUserInfo(openProfiles[dataLength - 1]) : openProfiles.length >= 3 ? getUserInfo(openProfiles[dataLength - 2]) : {};
            result[`${tab}3`] = (openProfiles.length === 1 || openProfiles.length === 2) ? {} : openProfiles.length >= 3 ? getUserInfo(openProfiles[dataLength - 1]) : {};
         }

         return result;
      };
      const firstTab = showData(tabWiseData["first"], 'F');
      const secondTab = showData(tabWiseData["second"], 'S');
      const thirdTab = showData(tabWiseData["third"], 'T');
      const data = { 'firstTab': firstTab, 'secondTab': secondTab, 'thirdTab': thirdTab }

      this.setState({
         f1: data['firstTab']?.['F1'] || {},
         f2: data['firstTab']?.['F2'] || {},
         f3: data['firstTab']?.['F3'] || {},
         s1: data['secondTab']?.['S1'] || {},
         s2: data['secondTab']?.['S2'] || {},
         s3: data['secondTab']?.['S3'] || {},
         t1: data['thirdTab']?.['T1'] || {},
         t2: data['thirdTab']?.['T2'] || {},
         t3: data['thirdTab']?.['T3'] || {},
      });

      return data;
   }
   relevancySearch() {
      if (JSON.parse(localStorage.getItem("entry")) <= 0 | JSON.parse(localStorage.getItem("entry")) == null) {
         setTimeout(() => {
            const token = localStorage.getItem('token');
            fetch(this.state.baseUrl + '/api/group/user.php', {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {
                  this.setState({ recomended: responseJson.response });
               }).catch((error) => {
                  console.log(error);
               })
         }, 15000);
      }
      else {
         this.setState({ recomended: JSON.parse(localStorage.getItem("entry")) });
      }
   }

   updateImage() {
      this.setState({ screenWidth: window.innerWidth });
   }

   // determineDestination(tab) {
   //    const token = localStorage.getItem('token');
   //    const pathToRedirect = () => {
   //       const path = tab === 'first' && !token ? 'Settings' : tab === 'first' && token ? 'Self' :
   //                    tab === 'second' ? 'Temporal information' : 
   //                    tab === 'third' && this.state.profileType === 'consumer' ? 'Settings' : 'Enter'; 
   //       return path;
   //    } 

   //    let activeStack = '';
   //    if(tab == 'first') {
   //       activeStack = this.state.f3?.screenName || this.state.f2?.screenName || this.state.f1?.screenName || pathToRedirect()
   //    } else if (tab == 'second') {
   //       activeStack = this.state.s3?.screenName || this.state.s2?.screenName || this.state.s1?.screenName || pathToRedirect()
   //    } else if (tab == 'third') {
   //       activeStack = this.state.t3?.screenName || this.state.t2?.screenName || this.state.t1?.screenName || pathToRedirect()
   //    }
   //    this.setState({destinationInfo: activeStack});
   // }

   addDefaultOpenProfiles() {

      if (!this.context) {
         setTimeout(() => this.addDefaultOpenProfiles(), 100)
         return
      }

      const { sharedData, setSharedData } = this.context

      if (!sharedData.freshLoad)
         return

      if (!localStorage.getItem('entry')) {
         setTimeout(() => this.addDefaultOpenProfiles(), 600)
         return
      }

      const userProfiles = JSON.parse(localStorage.getItem('entry'))
      const defautlProfilesForFirstTabIndexes = [4, 3, 2, 1, 0]
      const defautlProfilesForThirdTabIndexes = [4, 3, 0, 1, 2]

      let openProfiles = [];

      defautlProfilesForFirstTabIndexes.forEach(ind => {
         openProfiles.push("/" + userProfiles[ind].username)
      });

      sharedData.tabWiseData["first"] = { ...sharedData.tabWiseData["first"], openProfiles };
      openProfiles = [];

      defautlProfilesForThirdTabIndexes.forEach(ind => {
         openProfiles.push("/" + userProfiles[ind].username)
         // window.history.pushState({ enteredBy: "pushState" }, "", "/" + userProfiles[ind].username); //move the path one by one to the history
      });

      this.props.setVisitedPaths(openProfiles);
      sharedData.tabWiseData["third"] = { ...sharedData.tabWiseData["third"], openProfiles };
      setSharedData({ ...sharedData, freshLoad: false })
      // this.props.setVisitedPaths(openProfiles);
      this.showInitialsOfOpenProfilesOnTab();
   }

   async updateOpenProfilesOnActiveTab(path) {
      const { sharedData, activeTab, setSharedData } = this.context
      let openProfiles = []
      let activeTabData = sharedData.tabWiseData[activeTab]

      if (activeTabData && activeTabData.openProfiles)
         openProfiles = activeTabData.openProfiles

      if (openProfiles && openProfiles[openProfiles.length - 1] !== path) {
         openProfiles.push(path)
      }
      sharedData.tabWiseData[activeTab] = { ...sharedData.tabWiseData[activeTab], openProfiles }
      setSharedData(() => {
         return {
            ...sharedData,
         }
      });
   }

   handleScreenDifferentiatorMouseOver(e, position) {
      this.getFolderWiseData();
      setTimeout(() => {
         handleDualTooltipContainer(e, position);
      }, 1);
   }

   async updateSubspaceStackIndicator(tabWiseData) {
      const { tab, openProfilesInStack } = getTabWithMostOpenProfilesInStack(tabWiseData)

      this.setState({ tabWithMostOpenProfiles: tab, openProfilesInStack });
      const numeralStack = openProfilesInStack === 'one' ? 'i' : openProfilesInStack === 'two' ? 'ii' : openProfilesInStack === 'three' ? 'iii' : '';
      this.setState({ numeralOpenProfilesInStack: numeralStack });

      const tabwiseOpenProfileScreenNames = getTabwiseOpenProfileScreenNames(tabWiseData);

      this.setState({
         tabwiseStackInfo: {
            first: { ...this.state.tabwiseStackInfo["first"], lengthInRoman: getRomanCountOfSubspacesInStack(tabWiseData, "first"), organizations: [...tabwiseOpenProfileScreenNames["first"] || []] },
            second: { ...this.state.tabwiseStackInfo["second"], lengthInRoman: getRomanCountOfSubspacesInStack(tabWiseData, "second"), organizations: [...tabwiseOpenProfileScreenNames["second"] || []] },
            third: { ...this.state.tabwiseStackInfo["third"], lengthInRoman: getRomanCountOfSubspacesInStack(tabWiseData, "third"), organizations: [...tabwiseOpenProfileScreenNames["third"] || []] },
         }
      })
   }

   updateFolderWiseData() {
      const { state, actions } = this.props.appContext;
      const { setFolderData } = actions;

      const { sharedData } = this.context;
      const tabWiseData = sharedData.tabWiseData;
      let keys = Object.keys(tabWiseData)
      let values = Object.values(tabWiseData)
      let data = {}

      values.forEach((val, ind) => {
         data[keys[ind]] = val
      })

      setFolderData((fData) => { return { ...fData, folder1: JSON.parse(JSON.stringify(data)) } })
   }

   getOrganizationTree(tabwiseStackInfo) {
      return (
         <>
            <div className="org-container">
               {Object.entries(tabwiseStackInfo).map(([key, stackInfo], stackInd) => {
                  if (stackInfo.organizations?.length) {
                     return (
                        <div className={`column-view ${this.state.activeTab === key ? 'active' : ''}`}>
                           {
                              stackInfo.organizations.map((org, ind) => (
                                 <>
                                    <p className='org-name'
                                       onClick={() => this.openUserProfile(org?.username, key)}
                                       num={ind}
                                       key={ind}>
                                       {showInitials(org?.screenName)}
                                       {/* <p className='fullname'>{org?.screenName}</p> */}
                                    </p>
                                 </>
                              ))
                           }
                        </div>
                     )
                  }
                  return null;
               })}
            </div>
            <div className="common-range-container">
               <div className="common-range-border"></div>
               <span className="org-range"></span>
            </div>
         </>
      );
   }

   render() {
      return (
         <footer className="ant-layout-footer">
            <div className="footer-menu menu1">
               {(this.state.pathname !== '/') && 
               <div ref={this.orgTreeRef} className='org-tree'>{this.getOrganizationTree(this.state.tabwiseStackInfo)}</div>}
               <div className="user-badge">{window.innerWidth < 767 ?
                  (this.state.activeProfile?.length > 20 ? (<p className='user-initials'>{showInitials(this.state.activeProfile)}
                     <p className='user-badge-info'>{this.state.activeProfile}</p></p>) : <div ref={this.userBadgeRef} dangerouslySetInnerHTML={{ __html: formatOrganizationName(this.state.activeProfile, this.userBadgeRef.current) }} />)
                  : (<div ref={this.userBadgeRef} dangerouslySetInnerHTML={{ __html: formatOrganizationName(this.state.activeProfile, this.userBadgeRef.current) }} />)}
               </div>
               <ul className="flex  align-center nav justify-center">
                  {/* <li><Link to={'/settings'}  onClick={this.setStorage.bind(this)} data-toggle={"tab"}   className={localStorage.getItem("footer")=="second" ? "active2 menu" : "menu"}><img class="scure" src="/images/calendar.png"/></Link></li> */}
                  {/* <li><Link to={'/'}  onClick={this.setStorage.bind(this)} data-toggle={"tab"}   className={localStorage.getItem("footer")=="second" ? "active2 menu" : "menu"}><img class="scure" src="/images/square.png"/></Link></li> */}
                  {/* <li><Link onClick={this.setStorage.bind(this)} data-toggle="tab" to="/login"  className={localStorage.getItem("footer")=="add" ? "active2 menu" : "menu"}><img src="/images/plus.png"/></Link></li> */}

                  <li>
                     {
                        this.state.tabwiseStackInfo.first.lengthInRoman &&
                        <div className='subspace-stack-indicator'>
                           <span onClick={(event) => handleGrammarContainer(event, 1, 1)}>{this.state.tabwiseStackInfo.first.lengthInRoman}</span>
                           <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                              <div className="grammar-container">{getGrammarContainer("i", "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                           </div>
                        </div>
                     }

                     <a href="javascript:void(0)" onClick={this.setStorage.bind(this, "first")} data-toggle={"tab"} className={this.state.activeTab == "first" ? "active2 menu" : "menu"}>
                        <div>
                           <NewsPaperSvg width={23} className='scure calendarGuest' />
                           <sub onMouseOver={(e) => handleTooltipContainer(e, 1)}>
                              {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                              <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                           </sub>
                        </div>
                        {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                     </a>
                     <div className='footer-initials-group'>
                        {this.state.f1?.screenName ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userName, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                        {this.state.f2?.screenName ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userName, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                        {this.state.f3?.screenName ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userName, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                     </div>
                  </li>
                  <li>
                     {
                        this.state.tabwiseStackInfo.second.lengthInRoman &&
                        <div className='subspace-stack-indicator'>
                           <span onClick={(event) => handleGrammarContainer(event, 1, 2)}>{this.state.tabwiseStackInfo.second.lengthInRoman}</span>
                           <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                              <div className="grammar-container">{getGrammarContainer("i", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                           </div>
                        </div>
                     }

                     <a href="javascript:void(0)" onClick={this.setStorage.bind(this, "second")} data-toggle="tab" className={this.state.activeTab == "second" ? "active2 menu" : "menu"}>
                        <div>
                           <CalendarSvg width={23} />
                           <sub onMouseOver={(e) => handleTooltipContainer(e, 1)}>
                              {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                              <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                           </sub>
                        </div>
                        <p className='destinationInfo'>{this.state.destinationInfo}</p>
                     </a>
                     <div className='footer-initials-group'>
                        {this.state.s1?.screenName ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userName, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                        {this.state.s2?.screenName ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userName, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                        {this.state.s3?.screenName ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userName, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                     </div>
                  </li>
                  <li>
                     {
                        this.state.tabwiseStackInfo.third.lengthInRoman &&
                        <div className='subspace-stack-indicator'>
                           <span onClick={(event) => handleGrammarContainer(event, 1, 3)}>{this.state.tabwiseStackInfo.third.lengthInRoman}</span>
                           <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                              <div className="grammar-container">{getGrammarContainer("i", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                           </div>
                        </div>
                     }

                     <a href="javascript:void(0)" onClick={this.setStorage.bind(this, "third")} data-toggle="tab" className={this.state.activeTab == "third" ? "active2 menu" : "menu"}>
                        <div>
                           <HandShakeSvg width={43} />
                           <sub onMouseOver={(e) => handleTooltipContainer(e, 1)}>
                              {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                              <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                           </sub>
                        </div>
                        {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                     </a>
                     <div className='footer-initials-group'>
                        {this.state.t1?.screenName ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userName, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                        {this.state.t2?.screenName ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userName, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                        {this.state.t3?.screenName ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userName, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}
                     </div>
                  </li>
               </ul>
               {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer(event, 1)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                  <div className="grammar-container">{getGrammarContainer()}</div></div>
                  </div> : ''} */}
               {/* <div className="web-badge">
                  <div ref={this.webBadgeRef} dangerouslySetInnerHTML={{ __html: formatOrganizationName("Timesort", this.webBadgeRef.current) }} />
               </div> */}
               {/* <div className='footer-note'>
                  <p>all dates and times subject to change</p>
               </div> */}
               {this.state.screenWidth > 1550 ?
                  <div className='screenDifferentiator-container right-differentiator'>
                     <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                        <ZZSvg />
                        <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                     </div>
                  </div> : ''
               }
            </div>
         </footer>
      )
   }
}
export default GuestFooter;