import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from 'react-number-format';
import Footer from '../Footer';
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class Chatusers extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, activecolor: 'page', perpagefo: 1, chatusers: [], itemsp: Array.from({ length: 40 }), hasMore: true,
         alphabetically_following: "", mutuality_following: "",
         usersearch: [], searchPerameter: "", statepage: 0, itemsearch: Array.from({ length: 40 }), croseIcone2: "",
      }
   }
   mainProfileColor() {
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".username").css("color", "");
      $(".username").css("color", random_color.textColor);
      $(".username").css("background-color", "");
      $(".username").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   relevancy() {
      $(".pupup8").toggle();
      this.setState({ mutuality_following: "" });
      this.setState({ alphabetically_following: "" });
      this.componentDidMount();
   }
   mutuality() {
      $(".pupup8").toggle();
      this.setState({ alphabetically_following: "" });
      this.setState({ mutuality_following: "mutuality" });
      this.componentDidMount();
   }
   alphabetically() {
      $(".pupup8").toggle();
      this.setState({ mutuality_following: "" });
      this.setState({ alphabetically_following: "alphabetically" });
      this.componentDidMount();
   }
   // fetchMoreDatafo(){

   //     setTimeout(() => {
   //     this.setState({activecolor:'first'});
   //      const variable = localStorage.getItem('userid');
   //      const token = localStorage.getItem('token');
   //      const totaluser=localStorage.getItem('chatusers');
   //      const recordp = totaluser/40;
   //      if (this.state.perpagefo >= recordp) {
   //      this.setState({ hasMore: false });   
   //      return;
   //      } 
   //      this.setState({perpagefo:this.state.perpagefo+1});
   //      fetch(this.state.baseUrl+'/api/follow/getFollowing.php?user_id='+variable+'&page='+this.state.perpagefo+'&limit=40', {
   //      method: "GET",
   //      headers: {
   //      Accept: "application/json",
   //      "Content-Type": "application/json",
   //      "Authorization": "Bearer " + token,
   //      },     
   //      })
   //      .then((response) => response.json())
   //      .then((responseJson) =>{
   //      for (var i = responseJson.response.length - 1; i >= 0; i--) 
   //      {
   //      this.state.chatusers.push(responseJson.response[i]);
   //      }
   //      if(this.state.mutuality_following!=""){
   //                this.setState({chatusers:this.state.chatusers.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse()});     	 
   //            }
   //            if(this.state.alphabetically_following!="")
   //            {
   //                this.setState({chatusers:this.state.chatusers.sort((a,b) => 
   //               a.screenName.localeCompare(b.screenName))
   //              });     	 	
   //            }
   //            else
   //            {
   //                this.setState({chatusers:this.state.chatusers});
   //            } 
   //      });
   //      this.setState({
   //          itemsp: this.state.itemsp.concat(Array.from({ length: 40 }))
   //      });
   //     }, 1000); 
   //    }
   pops() {
      $(".pupup8").toggle();
   }
   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);

         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   componentDidMount() {
      this.setState({ activecolor: 'page', searchPerameter: "", chatusers: [], itemsp: Array.from({ length: 40 }), hasMore: true });
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      document.getElementById("parentfollowing").value = "";
      fetch(this.state.baseUrl + '/api/messages/myChats.php', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (this.state.mutuality_following != "") {
               this.setState({ chatusers: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() });
            }
            if (this.state.alphabetically_following != "") {
               this.setState({
                  chatusers: responseJson.response.sort((a, b) =>
                     a.screenName.localeCompare(b.screenName))
               }, () => {
                  this.mainProfileColor();
               });
            }
            else {
               this.setState({ chatusers: responseJson.response }, () => {
                  this.mainProfileColor();
               });
            }
         });
      this.readsms();
   }
   readsms() {
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/messages/read.php',
         {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         }).then((response) => response.json())
   }


   onKeyUpValue(event) {
      this.setState({ searchPerameter: event.target.value, searchloader: true }, () => {
         this.timeout = setTimeout(function () {
            this.searchUser();
         }.bind(this), 2000);
      });
      if (this.timeout) {
         clearTimeout(this.timeout)
         this.timeout = null
      }
      if (event.target.value.length > 0) {
         this.setState({ croseIcone2: event.target.value, hasMore: true });
      }
      else {
         this.setState({ croseIcone2: "" });
      }
   }
   async searchUser() {
      if (this.state.searchPerameter != "") {
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         var response = await fetch(this.state.baseUrl + '/api/messages/searchUsersForChat.php?search=' + this.state.searchPerameter + '&page=1&&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         });
         const movies = await response.json();
         if (movies.response.length) {
            const dusers = await movies.response;
            this.setState({ usersearch: [] }, () => {
               this.setState({ usersearch: dusers, searchloader: false, statepage: 0, searchPage: 1, itemsearch: Array.from({ length: 40 }), hasMore: true }, () => {
                  this.mainProfileColor()
               });
               return;
            });
         }
         else {
            this.setState({ error: "User Not Found", hasMore: false });
         }

      }
   }
   // onKeyUpValue(event) {
   //     const token = localStorage.getItem('token');
   //     fetch(this.state.baseUrl+'/api/messages/searchUsersForChat.php?search='+event.target.value+'&page=1&&limit=40',{
   //     method: "GET",
   //     headers: {
   //     Accept: "application/json",
   //     "Content-Type": "application/json",
   //     "Authorization": "Bearer " + token,
   //     },     
   //     })
   //     .then((response) => response.json())
   //     .then((responseJson) =>{
   //     this.setState({searchPerameter:"",usersearch:responseJson.response,searchPerameter:event.target.value,statepage:0,searchPage:1,itemsearch: Array.from({ length: 40 })},()=>
   //     {
   //          this.mainProfileColor();

   //     });

   //    })
   // }
   removefollowingtext() {
      document.getElementById("parentfollowing").value = "";
      this.setState({ usersearch: [], croseIcone2: "" });
      this.componentDidMount();
   }
   fetchMoreDatafoSearch() {
      const token = localStorage.getItem('token');
      setTimeout(() => {
         this.setState({ searchPage: this.state.searchPage + 1 });
         fetch(this.state.baseUrl + '/api//messages/searchUsersForChat.php?search=' + this.state.searchPerameter + '&page=' + this.state.searchPage + '&search=' + this.state.searchPerameter + '&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.usersearch.push(responseJson.response[i]);
               }

               this.setState({ usersearch: this.state.usersearch });
            }, 3000);
      });
      this.setState({
         itemsearch: this.state.itemsearch.concat(Array.from({ length: 40 }))
      });
   }

   weekfor() {
      if (this.state.chatusers.length > 0) {
         return "loaderclass closeCd";
      }
      else {
         return "loaderclass";
      }
   }
   weekfor22() {
      if (this.state.usersearch.length > 39) {
         return "loaderclass load";
      }
      else {
         return "loaderclass";
      }
   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   goback() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   render() {
      return (
         //         <div className="newscreen1 newscreen01">
         // <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}  id="onetest_7">
         <div className={`${this.props.pos} screen1`} id="onetest_7">
            <div className="top" id="myHeader">
               <section className="header headernoti2">
                  <div className="row">
                     <div className="col-4 header_left pr-0">
                        <ul className="nav nav-tabs">
                           <li><a className="linkback" data-toggle="tab" onClick={this.goback.bind(this)}><img src="../../images/back.png" align="img" /></a></li>
                        </ul>
                     </div>
                     <div className="col-4 header_center pl-0 pr-0 text-center">
                        <h6>Timesort</h6>
                     </div>
                     <div className="col-4 header_right pl-0">
                        <ul className="uld">
                           <li className="more4"><a onClick={this.pops.bind(this)} ><img src="../../images/more2.png" align="img" /></a></li>
                        </ul>
                     </div>
                  </div>
               </section>
               <div className="header_search">
                  <form className="form-inline mt-md-0">
                     <input className="form-control" id="parentfollowing" type="text" onKeyUp={this.onKeyUpValue.bind(this)} placeholder={'Search users to message, ' + localStorage.getItem("screenname").split(" ")[0] + '!'} aria-label="Search" />
                     {(() => {
                        if (this.state.croseIcone2.length > 0) {
                           return (<span className="btn"><img className="" onClick={this.removefollowingtext.bind(this)} src="../../images/close(1).png" /></span>);
                        }
                        else {
                           return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                        }
                     })()}
                  </form>
               </div>
            </div>
            <div className="tab-c1ontent searchlst">
               <div className="row py-0 my-0">
                  <div
                     id="scrollableChat"
                     className="scrollClass newscreen1"
                     style={{
                        overflow: 'auto',
                        display: 'row',
                        flexDirection: 'row',
                        margintop: 47,
                     }}
                  >
                     {this.state.usersearch.length == 0 && this.state.chatusers == 0 ? <div className="blankcalendar blankcalendar2">
                        <div className="testc"><p>{'Messages will show up here when you  send or receive messages, ' + localStorage.getItem('screenname').split(" ")[0]}</p></div>
                        <span className="bell"><img src="images/cpl.png" /></span>
                     </div> : this.state.usersearch.length > 0 ?
                        <InfiniteScroll
                           dataLength={this.state.itemsearch.length}
                           next={this.fetchMoreDatafoSearch.bind(this)}
                           className="row"
                           style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                           inverse={false} //
                           hasMore={this.state.hasMore}
                           loader={
                              <div className={this.weekfor22()}>
                                 <img
                                    src="../../images/newgif.gif"
                                    alt="loading"
                                 />
                              </div>}
                           useWindow={false}
                           scrollableTarget="scrollableChat">
                           {this.state.usersearch.map((s, i) => {
                              return (
                                 <div className="col-6" >
                                    <div className="userin" id={s.user_id}  >
                                       <Link to={{
                                          pathname: `/Chat/${s.user_id}`,
                                          state: { username: s.username, screen: s.screenName, image: s.profileImage }
                                       }} onClick={this.setStorage.bind(this)}>
                                          <span class={s.profileImage && s.profileImage !== "" ? "imgg" : "imgg imageline"}>
                                             {s.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + s.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(s.screenName)}</span>}
                                          </span>
                                          <h6>{s.screenName}</h6>
                                          <p>{s.message ? s.message : '.....'}</p>
                                       </Link>
                                    </div>
                                 </div>
                              )
                           })}
                        </InfiniteScroll>
                        :

                        <InfiniteScroll
                           dataLength={this.state.itemsp.length}
                           //  next={this.fetchMoreDatafo.bind(this)}
                           className="row"
                           style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                           inverse={false} //
                           hasMore={this.state.hasMore}
                           loader={<div id="track" className={this.weekfor()}>
                              <img
                                 src="../../images/newgif.gif"
                                 alt="loading"
                              />
                           </div>
                           }
                           useWindow={false}
                           scrollableTarget="scrollableChat"
                        >
                           {this.state.chatusers.map((p, i) => {
                              return (
                                 <div className="col-6" >
                                    <div className="userin" id={p.user_id} >
                                       <Link to={{
                                          pathname: `/Chat/${p.user_id}`,
                                          state: { username: p.username, screen: p.screenName, image: p.profileImage }
                                       }} onClick={this.setStorage.bind(this)}>
                                          <span class={p.profileImage && p.profileImage !== "" ? "imgg" : "imgg imageline"}>{p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(p.screenName)}</span>}<img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                          </span>
                                          <h6 >{p.screenName}</h6>
                                          <p >{p.type == "message" ? p.message : 'image'}</p>
                                       </Link>
                                    </div>
                                 </div>
                              )
                           })}
                        </InfiniteScroll>
                     }


                  </div>
               </div>
            </div>
            <Footer {...this.props} />
            <div className="op pupup8">
               <div className="more5 h-100" onClick={this.pops.bind(this)} ></div>
               <ul>
                  <h5 className="text-center">sort</h5>
                  <li><a className="more5" id="secondRelevancy" onClick={this.relevancy.bind(this)} ><img src="../../images/More.png" /> relevancy</a></li>
                  <li><a className="more5" id="secondMutuality" onClick={this.mutuality.bind(this)}><img src="../../images/mut.png" /> mutuality</a></li>
                  <li><a className="more5" id="secondAlphabetically" onClick={this.alphabetically.bind(this)}><img src="../../images/az.png" /> alphabetically</a></li>
                  <button className="upbtn more5" onClick={this.pops.bind(this)}>Cancel</button>
               </ul>
            </div>

         </div>
         // </div>
      )
   }
}
export default Chatusers;