import React, { Component } from 'react';
import $ from 'jquery';
import axios from "axios";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import Slider from '@material-ui/core/Slider';
import Footer from "../Footer";
export const USERNAME_REGEX = /^[a-zA-Z0-9_-]+s*$/;
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class EditProfile extends React.Component {
   constructor(props) {
      super(props);
      let edit_id = "";
      if (localStorage.getItem('moveid') === "") {
         edit_id = "";
      }
      else {
         edit_id = new Date(localStorage.getItem('moveid'));
      }
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, static: "", loaddocument: false, hasMore: true, marks: [], maximum: "", minimum: "", monthyear: [], Nextd: "", Previousd: "",
         valuedataprofile: new Date(), checked: "", inverse: true, evntpage: 0, eusername: localStorage.getItem("username"), eventid: "",
         escreen: "", e: edit_id, imageUrl: "", currentpassword: "", newpassword: "", conformpassword: "",
         rotation: 0,
         cropsize: { width: 400, height: 400 },
         crop: { x: 0, y: 0 },
         zoom: 1,
         aspect: 1,
         ebio: ""
         , eemail: "", croped: ""
         , eweb: "", evntData: [], itemsEvnt: Array.from({ length: 10 }), profilecheck: "", profiledata: [], recomended: [], editerror: "", errortitle: "",
      }

   }
   componentDidMount() {
      localStorage.setItem("footer", "first");
      $('.iconfirst').addClass("colorclass");
      $("#second").addClass("displaynone");
      this.relevancySearch();
      if (localStorage.getItem('make_public') == 1) {
         this.setState({ checked: false });
      }
      else {
         this.setState({ checked: true });
      }
      this.setState({
         eusername: localStorage.getItem("username"),
         escreen: localStorage.getItem("screenname"),
         ebio: localStorage.getItem("bio")
         , eemail: localStorage.getItem("email")
         , eweb: localStorage.getItem("website")
      }, () => {
         //this.mainProfileColor();
      });

   }
   // setname(){
   //     if(localStorage.getItem('screenname'))
   //     {
   //     var first =localStorage.getItem('screenname').split(" ")[0]
   //     var firstn=first.charAt(0);
   //     if(localStorage.getItem('screenname').split(" ")[1])
   //     {
   //     var second =localStorage.getItem('screenname').split(" ")[1]
   //     var secondn = second.charAt(0); 
   //     this.setState({static:firstn+secondn});
   //     }
   //     else
   //     {
   //     this.setState({static:firstn});
   //     }
   //     }
   //     }
   emailedit = (e) => {
      this.setState({ eemail: e.target.value });
   }
   webedit = (e) => {
      this.setState({ eweb: e.target.value });
   }
   bioedit = (e) => {
      this.setState({ ebio: e.target.value });
   }
   relevancySearch() {
      if (JSON.parse(localStorage.getItem("entry"))) {
         this.setState({ recomended: JSON.parse(localStorage.getItem("entry")) });
      }
   }

   mainProfileColor() {
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".blankusername").css("color", "");
      $(".blankusername").css("color", random_color.textColor);
      $(".blankusername").css("background-color", "");
      $(".blankusername").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   onImageChange = e => {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
         this.setState({
            imageUrl: reader.result
         });
         if (reader.result) {
            $("#one_17").removeClass("active fades");
            $("#one_52").addClass("active fades");
         }
      };
   };

   usernameedit = (e) => {
      this.setState({ eusername: e.target.value });
   }
   screenedit = (e) => {
      this.setState({ escreen: e.target.value });
   }
   handleChecktoggle() {
      if (this.state.checked != true) {
         this.setState({
            checked: true
         })
      }
      else {
         this.setState({
            checked: false
         })
      }
   }
   setStorage() {
      localStorage.setItem("backid", "front");
      localStorage.setItem('moveid', "");
   }
   editProfile() {
      var email = "";
      var make_public = "";
      var username = "";
      const avatarSourceBase64Link = "data:image/jpeg;base64,";
      if (this.state.imageUrl != "") {
         this.setState({ imagedata: this.state.imageUrl });
      }
      if (!this.state.eusername) {
         this.setState({ editerror: 'Enter your username' });
         $(".errorpop").toggle();
         return;
      }
      if (!this.state.eusername.match(USERNAME_REGEX)) {

         this.setState({ editerror: "Uppercase letter and special characters except for " + "-" + " and " + "_" + "  are not allowed in your username," + this.state.escreen.split(" ")[0] });
         $(".errorpop").toggle();
         username = "";
         return;
      }
      else {
         username = this.state.eusername;
      }
      if (this.state.eusername.length < 6 || this.state.eusername.length > 25) {
         this.setState({ editerror: " Your username must be 6 to 25 characters, " + this.state.escreen.split(" ")[0] });
         $(".errorpop").toggle();
         username = "";
         return;
      }
      else {
         username = this.state.eusername;
      }
      if (!this.state.escreen) {
         this.setState({ editerror: 'Enter your full name' });
         $(".errorpop").toggle();
         return;
      }
      if (this.state.eemail) {
         if (!this.state.eemail.match(EMAIL_REGEX)) {
            this.setState({ editerror: "Enter a valid email address, " + this.state.escreen.split(" ")[0] });
            $(".errorpop").toggle();
            return;
         }
         email = this.state.eemail;
      } else {
         this.setState({ editerror: "Enter your email, " + this.state.escreen.split(" ")[0] });
         $(".errorpop").toggle();
         return;
      }
      if (this.state.checked == false) {
         make_public = 1;
      }
      else {
         make_public = 0;
      }
      if (email != "" && username != "") {
         const body = {
            email: this.state.eemail,
            screenName: this.state.escreen,
            bio: this.state.ebio,
            make_public: make_public,
            website: this.state.eweb,
            username: this.state.eusername,
            profileImage: this.state.imageUrl
         }
         const token = localStorage.getItem('token');
         axios.post(this.state.baseUrl + '/api/user/edit.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
               if (res.data.success == true) {
                  console.log('updateres:', res);
                  console.log('updateres:', res.data.response.profileImage);
                  localStorage.setItem("email", res.data.response.email);
                  localStorage.setItem("screenname", res.data.response.screenName);
                  localStorage.setItem("username", res.data.response.username);
                  localStorage.setItem("followers", res.data.response.followers);
                  localStorage.setItem("request", res.data.response.request);
                  localStorage.setItem("bio", res.data.response.bio);
                  localStorage.setItem("following", res.data.response.following);
                  localStorage.setItem("profileCover", res.data.response.profileCover);
                  localStorage.setItem("profileImage", res.data.response.profileImage);
                  localStorage.setItem("website", res.data.response.website);
                  localStorage.setItem("totalpost", res.data.response.totalPost);
                  localStorage.setItem('make_public', res.data.response.make_public);
                  localStorage.setItem("backid", "back");
                  this.props.history.push('/self');
               }
               else {
                  this.setState({ editerror: "res.data.message" });
                  $(".errorpop").toggle();
               }
            })
            .catch((err) => {

            })
      }
      else {
         this.setState({ editerror: 'please enter valid details' });
         $(".errorpop").toggle();
      }
   }



   rotateRefresh() {
      this.setState({ rotation: 0 });
   }
   onCropChange = crop => {
      console.log('crop', crop);
      this.setState({ crop })
   }
   setRotation = rotation => {
      console.log('rotation', rotation);
      this.setState({ rotation })
   }
   onCropComplete = async (croppedArea, croppedAreaPixels) => {
      try {
         var croppedImage = await getCroppedImg(
            this.state.imageUrl,
            croppedAreaPixels,
            this.state.rotation
         )
         this.setState({ croped: "" });
         this.setState({ croped: croppedImage });

      } catch (e) {
      }
   }
   onZoomChange = zoom => {
      console.log('zoom', zoom);
      this.setState({ zoom })
   }
   rotatechange() {
      if (this.state.rotation < 360) {
         this.setState({ rotation: this.state.rotation + 90 })
      }
      else {
         this.setState({ rotation: this.state.rotation - 90 })
      }
   }
   first() {
      $("#second").addClass("displaynone");
      $("#first").removeClass("displaynone");
      $('.iconfirst').addClass("colorclass");
      $('.iconsecond').removeClass("colorclass");
   }
   second() {
      $("#first").addClass("displaynone");
      $("#second").removeClass("displaynone");
      $('.iconsecond').addClass("colorclass");
      $('.iconfirst').removeClass("colorclass");
   }
   saveImage() {
      $("#one_17").addClass("active fades");
      $("#one_52").removeClass("active fades");
      this.setState({
         imageUrl: this.state.croped
      });
      this.componentDidMount();
   }
   goseven() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   goseven2() {
      $('#one_52').removeClass("active fades");
      $('#one_17').addClass("active fadesback");
      this.setState({
         imageUrl: "", rotation: 0, croped: "", cropsize: { width: 400, height: 400 },
         crop: { x: 0, y: 0 },
         zoom: 1,
         aspect: 1
      });
      this.componentDidMount();
   }
   cancelPic() {
      $("#one_17").addClass("active fades");
      $("#one_52").removeClass("active fades");
      this.setState({
         imageUrl: "", rotation: 0, croped: "", cropsize: { width: 400, height: 400 },
         crop: { x: 0, y: 0 },
         zoom: 1,
         aspect: 1
      });
      this.componentDidMount();
   }
   render() {
      return (
         //  <div className="newscreen1 newscreen01">
         //  <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"} id="one_17">
         <>
            <div className={`${this.props.pos} screen1`} id="one_17">
               <div className="top" id="myHeader">
                  <section className="header headeredit">
                     <div className="row">
                        <div className="col-4 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><a className="linkback" onClick={this.goseven.bind(this)} data-toggle="tab" ><img src="../../images/back.png" align="img" /></a></li>
                           </ul>
                        </div>
                        <div className="col-4 header_center pl-0 pr-0 text-center">
                           <h6>edit profile</h6>
                        </div>
                        <div className="col-4 header_right pl-0">
                        </div>
                     </div>
                  </section>
               </div>
               <div className="tab-content  pt-0">
                  <div className="form-signin login edituser signup">
                     <div className="form-group text-center" >
                        <div className="mailupload">
                           <span className="upload"><input type='file' onChange={this.onImageChange.bind(this)} />

                              {(() => {
                                 if (this.state.imageUrl == "") {
                                    if (localStorage.getItem('profileImage') != "") {
                                       return (<img className="w-100" src={this.state.baseUrl + localStorage.getItem('profileImage')} alt="your image" />)
                                    }
                                    else {
                                       return (<span className="blankusername">{localStorage.getItem("staticname")}</span>)
                                    }
                                 }
                                 else {
                                    return (

                                       <img className="w-100" src={this.state.imageUrl} alt="your image" />
                                    );
                                 }
                              })()}
                           </span>

                           <span className="camera">
                              <i className="fas fa-camera"></i>
                           </span>
                        </div>
                     </div>

                     <div className="form-group">
                        <label>username</label>
                        <input type="text" className="form-control" value={this.state.eusername} name="this.state.eusername" onChange={this.usernameedit.bind(this)} placeholder="username" />
                     </div>

                     <div className="form-group">
                        <label>full name</label>
                        <input type="text" value={this.state.escreen} name="this.state.escreen" className="form-control" onChange={this.screenedit.bind(this)} placeholder="full name" />
                     </div>
                     <div className="form-group">
                        <label>email</label>
                        <input type="email" value={this.state.eemail} onChange={this.emailedit.bind(this)} name="this.state.eemail" className="form-control" placeholder="email" />
                     </div>
                     <div className="form-group">
                        <label>bio</label>
                        <input type="text" value={this.state.ebio != "null" ? this.state.ebio : ""} onChange={this.bioedit.bind(this)} name="this.state.ebio" className="form-control" placeholder="bio" />
                     </div>

                     <div className="form-group">
                        <label>website</label>
                        <input type="text" name="this.state.eweb" onChange={this.webedit.bind(this)} value={this.state.eweb != "null" ? this.state.eweb : ""} className="form-control" placeholder="website" />
                     </div>
                     {/* <!--  <div className="form-group mb-0">
            <label>password</label>
            <input type="password" name="password" id="inputPassword" className="form-control" placeholder="password" required="">
            </div> --> */}
                     <div className="form-group mb-0">
                        <h6>
                           privacy


                           {(() => {
                              if (this.state.checked == false) {
                                 return (
                                    <div className="public">public<label className="switch">
                                       <input type="checkbox"
                                          onChange={this.handleChecktoggle.bind(this)}
                                          value={this.state.checked} checked={this.state.checked} />
                                       <span className="slider round"></span>
                                    </label>
                                    </div>
                                 );
                              }
                              else {
                                 return (<div className="public">private<label className="switch">
                                    <input type="checkbox"
                                       onChange={this.handleChecktoggle.bind(this)}
                                       value={this.state.checked} checked={this.state.checked} />
                                    <span className="slider round"></span>
                                 </label>
                                 </div>);
                              }
                           })()}
                        </h6>
                     </div>

                     <p>If your account is private, then users will have to make a request to follow you.</p>
                     <button className="btn btn-lg btn-block" onClick={this.editProfile.bind(this)} type="submit">update</button>
                  </div>
               </div>
               <Footer {...this.props} />
               <div className="op errorpop">
                  <div className="errorbtn error h-100"></div>
                  <ul>
                     <li>{this.state.editerror}</li>

                     <button className="upbtn errorbtn  onClick={this.cancelClick.bind(this)}">ok</button>
                  </ul>
               </div>
            </div>

            {/* ----------------52------------ */}
            <div className="screen1" id="one_52">
               <div className="top" id="myHeader">
                  <section className="header headernoti2">
                     <div className="row">
                        <div className="col-2 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><a className="linkback" onClick={this.goseven2.bind(this)} data-toggle="tab" ><img src="../../images/back.png" align="img" /></a></li>
                           </ul>
                        </div>
                        <div className="col-8 header_center pl-0 pr-0 text-center">
                           <h6>Selected Image</h6>
                        </div>
                        <div className="col- header_right pl-0">
                           <ul className="uld">
                              <li className="more4"></li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <div className="header_search">

                  </div>
               </div>
               <div className="tab-c1ontent searchlst">
                  <div className="progress-kbar screen1">
                     <h5><a className="close" onClick={this.cancelPic.bind(this)}><i className="fas fa-times"></i></a>Move and zoom image to adjust<a className="save" onClick={this.saveImage.bind(this)}><i className="fas fa-check"></i></a></h5>
                     <div className="crop-container">
                        <Cropper
                           image={this.state.imageUrl}
                           crop={this.state.crop}
                           zoom={this.state.zoom}
                           aspect={this.state.aspect}
                           cropShape="round"
                           restrictPosition={true}
                           rotation={this.state.rotation}
                           onCropChange={this.onCropChange.bind(this)}
                           onCropComplete={this.onCropComplete.bind(this)}
                           onZoomChange={this.onZoomChange.bind(this)}
                           onRotationChange={this.setRotation.bind(this)}
                        />
                     </div>
                     <div className="controlsclass">

                        <Slider
                           value={this.state.zoom}
                           min={1}
                           max={3}
                           step={0.1}
                           aria-labelledby="Zoom"
                           onChange={(e, zoom) => this.onZoomChange(zoom)}
                           classes={{ container: 'slider' }}
                           id="first"
                        />
                        <div id="second">
                           <img src="../../images/cros2.png" className="up1" onClick={this.rotateRefresh.bind(this)} />
                           <Slider
                              value={this.state.rotation}
                              min={0}
                              max={360}
                              step={1}
                              aria-labelledby="Rotation"
                              classes={{ container: 'slider' }}
                              onChange={(e, rotation) => this.setRotation(rotation)}

                           />
                           <img src="../../images/90.png" className="up2 " onClick={this.rotatechange.bind(this)} />
                        </div>
                        <div className="iconbtn row text-center">
                           <div className="col-6">
                              <img src="../../images/zoom.png" className="iconfirst" onClick={this.first.bind(this)} />
                           </div>
                           <div className="col-6">
                              <img src="../../images/rotate.png" className="iconsecond" onClick={this.second.bind(this)} />
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
               <Footer {...this.props} />
            </div>
         </>
         // </div>
      )
   }
};
export default EditProfile