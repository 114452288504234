import axios from 'axios';
import React, { useState } from "react";
import CustomPopUp from "../../CustomPopup";
import FormSubmitLoader from "../FormSubmitLoader";

export default function UploadProducerAccounts() {
    const [jsonData, setJsonData] = useState('');
    const [jsonError, setJsonError] = useState('');
    const [apiResponsePopup, setApiResponsePopup] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleTextSubmit = (event) => {
        event.preventDefault();

        let hasError = false;

        if (!jsonData.trim()) {
            setJsonError('fill the Producer Accounts information');
            hasError = true;
        } else {
            try {
                const parsedData = JSON.parse(jsonData);
                if (!Array.isArray(parsedData) || !parsedData.every(item => typeof item === 'object')) {
                    setJsonError('producer accounts information must be an array of objects');
                    hasError = true;
                } else {
                    setJsonError('');
                }
            } catch (error) {
                setJsonError('producer accounts information is not valid JSON');
                hasError = true;
            }
        }

        if (!hasError) {
            const parsedData = JSON.parse(jsonData);
            const dataArray = Object.values(parsedData);

            let url = `${process.env.REACT_APP_BASE_URL_NODE}/api/users/bulk`

            // submit the request to the database
            submitResponse(url, dataArray);
        }
    };

    const submitResponse = async (url, data) => {
        setIsLoading(true);
        const requestData = {
            'data': data
        };

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        }

        await axios.post(url, requestData, {
            headers
        }).then((response) => {
            console.log("save response", response);
            if (response?.data?.success) {
                setIsLoading(false);
                setResponseMessage(JSON.stringify(response?.data,'', 4));
                setApiResponsePopup(true);
            }
        }).catch((error) => {
            setIsLoading(false);
            setResponseMessage(error);
            setApiResponsePopup(true);
        });
    }

    const handleJsonChange = (event) => {
        setJsonData(event.target.value);
        setJsonError('');
    };

    return (
        <>

            <div className="uploadInformationBlock">
                <h2>Upload Producer Accounts</h2>
                <form onSubmit={handleTextSubmit}>
                    <div className="inputGroup">
                        <label htmlFor="jsonData">Enter Producer Accounts information here:</label>
                        <textarea
                            spellCheck="false"
                            id="jsonData"
                            name="jsonData"
                            value={jsonData}
                            onChange={handleJsonChange}
                            rows="10"
                            cols="50"
                            placeholder="Enter Producer Accounts information here"
                        />
                        {jsonError && <p className="error">{jsonError}</p>}
                    </div>
                    <button className="submitBtn" type="submit">Upload Producer Information</button>
                </form>

                {apiResponsePopup && (
                    <CustomPopUp
                        isOpen={apiResponsePopup}
                        onClose={() => setApiResponsePopup(false)}
                        popup={{
                            heading: "Upload Result",
                            message: `${responseMessage}`,
                            onClick: () => {
                                setApiResponsePopup(false);
                            },
                        }}
                        multipleButton={false}
                    />
                )}
            </div>
            {isLoading && <FormSubmitLoader />}
        </>
    )
}