import { modifyHref, processCustomTableContent } from "./manageCaptions";

export const customCommandsAndTeXCommands = {
    "u": { texCommand: (content1, content2 = "") => `\\underline{${content1}}${content2}`, delimiter: "$" },
    "b": { texCommand: (content1, content2 = "") => `\\textcolor{#C80404}{\\textbf{${content1}}}${content2}`, delimiter: "$" },
    "l": { texCommand: (content1, content2 = "") => `<a target='_blank' href="${modifyHref(content1)}">${content2 || content1}</a>`, delimiter: "" },
    // "l": { texCommand: (content1, content2 = "") => `\\colorbox{#FFD7D7}{\\textcolor{black}{\\href{${modifyHref(content1)}}{${content2 || content1}}}}`, delimiter: "$" },
    "h": { texCommand: (content1, content2 = "") => `\\colorbox{#FFE1C8}{${content1}}${content2}`, delimiter: "$" },
    "t": { texCommand: (content1, content2 = "") => `\\begin{aligned}${processCustomTableContent(content1)}\\end{aligned}${content2}`, delimiter: "$$" }
};

export const customSpecialCommandsAndTeXCommands = {
    "!": { texCommand: (content1, content2 = "") => `<i>${content1}${content2}</i>`, delimiter: "" },
}

export const customCommands = Object.keys(customCommandsAndTeXCommands)
export const customSpecialCommands = Object.keys(customSpecialCommandsAndTeXCommands)

// Combine custom commands into a single regex pattern
export const customCommandsPattern = customCommands.join('|');
export const customSpecialCommandsPattern = customSpecialCommands.join('|');

/**
 * 
 * @param {String} command 
 * @param {String} content1 
 * @param {String} content2 
 * @returns 
 */
export function getTeXCommand(command, content1, content2) {
    if (!(command && content1))
        return ""
    return (customCommandsAndTeXCommands[command] || customSpecialCommandsAndTeXCommands[command]).texCommand(content1, content2)
}

/**
 * 
 * @param {String} command 
 * @returns 
 */
export function getDelimiter(command) {
    if (!command)
        return ""

    return (customCommandsAndTeXCommands[command] || customSpecialCommandsAndTeXCommands[command]).delimiter
}

export function encloseTeXCommandInDelimiter(command, processedTeXCommand) {
    if (!(command && processedTeXCommand))
        return ""

    const delimiter = getDelimiter(command)
    return delimiter + processedTeXCommand + delimiter;
}
