import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from 'react-number-format';
import Footer from '../Footer';
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class Myfollowing extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, activecolor: 'page', perpagefo: 1, following: [], itemsp: Array.from({ length: 40 }), hasMore: true,
         alphabetically_following: "", mutuality_following: "",
         followingSearch: [], searchPerameter: "", statepage: 0, itemsearch: Array.from({ length: 40 }), croseIcone2: "",
         baseUrlNode: process.env.REACT_APP_BASE_URL_NODE,
      }
   }
   mainProfileColor() {
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".username").css("color", "");
      $(".username").css("color", random_color.textColor);
      $(".username").css("background-color", "");
      $(".username").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   relevancy() {
      $(".pupup8").toggle();
      this.setState({ mutuality_following: "" });
      this.setState({ alphabetically_following: "" });
      this.componentDidMount();
   }
   mutuality() {
      $(".pupup8").toggle();
      this.setState({ alphabetically_following: "" });
      this.setState({ mutuality_following: "mutuality" });
      this.componentDidMount();
   }
   alphabetically() {
      $(".pupup8").toggle();
      this.setState({ mutuality_following: "" });
      this.setState({ alphabetically_following: "alphabetically" });
      this.componentDidMount();
   }
   fetchMoreDatafo() {
      setTimeout(() => {
         this.setState({ activecolor: 'first' });
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         const totaluser = localStorage.getItem('following');
         const localToken = localStorage.getItem("timeToken");
         const recordp = totaluser / 40;
         if (this.state.perpagefo >= recordp) {
            this.setState({ hasMore: false });
            return;
         }
         this.setState({ perpagefo: this.state.perpagefo + 1 });
         //fetch(this.state.baseUrl+'/api/follow/getFollowing.php?user_id='+variable+'&page='+this.state.perpagefo+'&limit=40', {
         fetch(this.state.baseUrlNode + '/api/myfollowing?limit=40&user_id=' + variable + '&page=' + this.state.perpagefo + '&own=true', {
            //fetch(this.state.baseUrl+'/api/follow/getFollowing.php?user_id='+variable+'&page='+this.state.perpagefo+'&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + localToken,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.following.push(responseJson.response[i]);
               }
               if (this.state.mutuality_following != "") {
                  this.setState({ following: this.state.following.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() }, () => {
                     this.mainProfileColor();
                  });
               }
               if (this.state.alphabetically_following != "") {
                  this.setState({
                     following: this.state.following.sort((a, b) =>
                        a.screenName.localeCompare(b.screenName))
                  }, () => {
                     this.mainProfileColor();
                  });
               }
               else {
                  this.setState({ following: this.state.following }, () => {
                     this.mainProfileColor();
                  });
               }
            });
         this.setState({
            itemsp: this.state.itemsp.concat(Array.from({ length: 40 }))
         });
      }, 1000);
   }
   pops() {
      $(".pupup8").toggle();
   }
   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         console.log('ssssssssss', secondn);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   componentDidMount() {
      this.setState({ activecolor: 'page', perpagefo: 1, searchPerameter: "", following: [], itemsp: 0, itemsp: Array.from({ length: 40 }), hasMore: true });
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const localToken = localStorage.getItem("timeToken");
      var pagepro = 1;
      document.getElementById("parentfollowing").value = "";
      fetch(this.state.baseUrlNode + '/api/myfollowing?limit=40&user_id=' + variable + '&page=' + this.state.perpagefo + '&own=true', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //"Authorization": "Bearer " + localToken,
            "x-access-token": localToken
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            //console.log(responseJson.response)
            console.log('responseJson.response', responseJson);
            if (this.state.mutuality_following != "") {
               this.setState({ following: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() }, () => {
                  this.mainProfileColor();
               });
            }
            if (this.state.alphabetically_following != "") {
               this.setState({
                  following: responseJson.response.sort((a, b) =>
                     a.screenName.localeCompare(b.screenName))
               }, () => {
                  this.mainProfileColor();
               });
            }
            else {
               this.setState({ following: responseJson.response }, () => {
                  this.mainProfileColor();
               });
            }
         });
   }
   onKeyUpValue(event) {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const localToken = localStorage.getItem("timeToken");
      //fetch(this.state.baseUrl+'/api/follow/followingSearch.php?user_id='+variable+'&page=1&search='+event.target.value+'&limit=40',{
      fetch(this.state.baseUrlNode + '/api/myFollowingSearch?limit=40&user_id=' + variable + '&page=1&search=' + event.target.value + '&own=' + true, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": localToken
            //"Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({ followingSearch: [], followingSearch: responseJson.response }, () => {
               this.mainProfileColor();
            });
         });
      this.setState({ searchPerameter: "", searchPerameter: event.target.value, statepage: 0, searchPage: 1 });
      this.setState({
         itemsearch: Array.from({ length: 40 })
      });
      if (event.target.value.length > 0) {
         this.setState({ croseIcone2: event.target.value });
      }
      else {
         this.setState({ croseIcone2: "" });
      }
   }
   removefollowingtext() {
      document.getElementById("parentfollowing").value = "";
      this.setState({ followingSearch: [], croseIcone2: "" });
      this.componentDidMount();
   }
   fetchMoreDatafoSearch() {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const totaluser = localStorage.getItem('followers');
      const pageno = 40;
      this.state.statepage = +40;
      this.setState({ statepage: this.state.statepage });
      if (this.state.followingSearch.length >= this.state.statepage) {
         this.state.searchPage = +1;
         this.setState({ searchPage: this.state.searchPage });
      }
      else {
         this.setState({ hasMore: false });
         return;
      }
      setTimeout(() => {

         fetch(this.state.baseUrl + '/api/follow/followingSearch.php?user_id=' + variable + '&page=' + this.state.searchPage + '&search=' + this.state.searchPerameter + '&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.followingSearch.push(responseJson.response[i]);
               }
               this.setState({
                  followingSearch: this.state.followingSearch
               }, () => {
                  this.mainProfileColor();
               });
            }, 3000);
      });

      this.setState({
         itemsearch: this.state.itemsearch.concat(Array.from({ length: 40 }))
      });
   }
   weekfor() {
      if (this.state.following.length > 0) {
         return "loaderclass load";
      }
      else {
         return "loaderclass closeCd";
      }
   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   goback() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   render() {
      return (
         //         <div className="newscreen1 newscreen01">
         // <div className="screen1 active fades"  id="onetest_7">
         <div className={`${this.props.pos} screen1`} id="onetest_7">
            <div className="top" id="myHeader">
               <section className="header headernoti2">
                  <div className="row">
                     <div className="col-4 header_left pr-0">
                        <ul className="nav nav-tabs">
                           <li><a className="linkback" data-toggle="tab" onClick={this.goback.bind(this)}><img src="../../images/back.png" align="img" /></a></li>
                        </ul>
                     </div>
                     <div className="col-4 header_center pl-0 pr-0 text-center">
                        <h6>following</h6>
                     </div>
                     <div className="col-4 header_right pl-0">
                        <ul className="uld">
                           <li className="more4"><a onClick={this.pops.bind(this)} ><img src="../../images/more2.png" align="img" /></a></li>
                        </ul>
                     </div>
                  </div>
               </section>
               <div className="header_search">
                  <form className="form-inline mt-md-0">
                     <input className="form-control" id="parentfollowing" type="text" onKeyUp={this.onKeyUpValue.bind(this)} placeholder="search users you’re following" aria-label="Search" />
                     {(() => {
                        if (this.state.croseIcone2.length > 0) {
                           return (<span className="btn"><img className="" onClick={this.removefollowingtext.bind(this)} src="../../images/close(1).png" /></span>);
                        }
                        else {
                           return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                        }
                     })()}
                  </form>
               </div>
            </div>
            <div className="tab-c1ontent searchlst">
               <div class={this.state.followingSearch.length > 0 || this.state.following.length > 0 ? "loaderclass closeCd" : "loaderclass"}><img src="../../images/newgif.gif" alt="loading" /></div>
               <div className="row py-0 my-0">
                  <div
                     id="scrollableDiv"
                     className="scrollClass newscreen1"
                     style={{
                        overflow: 'auto',
                        display: 'row',
                        flexDirection: 'row',
                        margintop: 47,
                     }}
                  >
                     {(() => {
                        if (this.state.followingSearch.length > 0) {
                           return (
                              <InfiniteScroll
                                 dataLength={this.state.itemsearch.length}
                                 next={this.fetchMoreDatafoSearch.bind(this)}
                                 className="row"
                                 style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                 inverse={false} //
                                 hasMore={this.state.hasMore}
                                 loader={
                                    <div className={this.state.followingSearch.length > 0 ? "loaderclass " : "loaderclass closeCd"}>
                                       <img
                                          src="../../images/newgif.gif"
                                          alt="loading"
                                       />
                                    </div>}
                                 // endMessage={
                                 //        <p style={{ textAlign: "center" }}>
                                 //          <b>Yay! You have seen it all</b>
                                 //        </p>
                                 //      }
                                 useWindow={false}
                                 scrollableTarget="scrollableDiv">
                                 {this.state.followingSearch.map((s, i) => {
                                    return (
                                       <div className="col-6" >
                                          <div className="userin" id={s.user_id}  >
                                             <Link to={{
                                                pathname: `/${s.username}`,
                                                state: { id: s.user_id }
                                             }} onClick={this.setStorage.bind(this)}
                                             >
                                                <span class="imgg">
                                                   {s.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + s.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(s.screenName)}</span>}
                                                </span>
                                                <h6>{s.screenName}</h6>
                                                {/* <p>{s.mutual} mutual</p> */}
                                             </Link>
                                          </div>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           )
                        }
                        else {
                           return (
                              <InfiniteScroll
                                 dataLength={this.state.itemsp.length}
                                 next={this.fetchMoreDatafo.bind(this)}
                                 className="row"
                                 style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                 inverse={false} //
                                 hasMore={this.state.hasMore}
                                 loader={<div id="track" className={this.weekfor()}>
                                    <img
                                       src="../../images/newgif.gif"
                                       alt="loading"
                                    />
                                 </div>
                                 }
                                 // endMessage={
                                 //        <p style={{ textAlign: "center" }}>
                                 //          <b>Yay! You have seen it all</b>
                                 //        </p>
                                 //      }
                                 useWindow={false}
                                 scrollableTarget="scrollableDiv"
                              >
                                 {this.state.following.map((p, i) => {
                                    return (
                                       <div className="col-6" >
                                          <div className="userin" id={p.user_id} >
                                             <Link to={{
                                                pathname: `/${p.username}`,
                                                state: { id: p.user_id }
                                             }} onClick={this.setStorage.bind(this)}
                                             >
                                                <span class="imgg">{p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(p.screenName)}</span>}<img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                                </span>
                                                <h6 >{p.screenName}</h6>
                                                {/* <p >{p.mutual} mutual</p> */}
                                             </Link>
                                          </div>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           )
                        }
                     })()}


                  </div>
               </div>
            </div>
            <Footer {...this.props} />
            <div className="op pupup8">
               <div className="more5 h-100" onClick={this.pops.bind(this)} ></div>
               <ul>
                  <h5 className="text-center">sort</h5>
                  <li><a className="more5" id="secondRelevancy" onClick={this.relevancy.bind(this)} ><img src="../../images/More.png" /> relevancy</a></li>
                  <li><a className="more5" id="secondMutuality" onClick={this.mutuality.bind(this)}><img src="../../images/mut.png" /> mutuality</a></li>
                  <li><a className="more5" id="secondAlphabetically" onClick={this.alphabetically.bind(this)}><img src="../../images/az.png" /> alphabetically</a></li>
                  <button className="upbtn more5" onClick={this.pops.bind(this)}>Cancel</button>
               </ul>
            </div>

         </div>
         // </div>
      )
   }
}
export default Myfollowing;