import React, { useEffect, useState } from "react";
import CustomPopUp from "./CustomPopup";

function Single() {
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const Validation =()=>{
    var { pass } = document.forms[0];
    if(pass.value=='')
    {
      setErrorMessages({ name: "pass", message: 'password is required' });
      return false;
    }
    return true;
  }
  const handleSubmit =async(event)=>   {
    console.log('under event');
    event.preventDefault();
    let verified=Validation();
    if(verified)
    {
      try{
      var { pass } = document.forms[0];
      if(pass.value=="TSStage")
      {
        localStorage.setItem('root','hii');
        window.location.reload();
      }else{
        setOpenPopup(true);
      }
    }
    catch(e){
      console.log('e',e.message);
    }
    }
  };

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <div className="form login">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" required />
          {renderErrorMessage("pass")}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <>
      <div className="app-login">
        <div className="login-form-login">
          <div className="title">Sign In</div>
          {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
        </div>
      </div>
      {openPopup  && 
      <CustomPopUp
        isOpen={openPopup}
        onClose={() => setOpenPopup(false)}
        popup={{
          heading: "Invalid identifier",
          message: 'Enter a valid identifier.',
          onClick: () => {
            setOpenPopup(false);
          },
        }}
        multipleButton={false}
      />}
      </>
  );
}

export default Single;