/**
 * Retrieves the value of a specified key from an object, including nested objects.
 * 
 * @param {object} obj - The object from which to retrieve the value.
 * @param {string} key - The name of the key to retrieve the value of. Use a dot (.) between keys to access nested objects.
 * @example 
 * const obj = {
 *    a: 1,
 *    b: {
 *       c: 5 
 *    }
 * };
 * 
 * getValue(obj, 'a'); // Returns 1
 * getValue(obj, 'b.c'); // Returns 5
 * @returns {*} - The value associated with the specified key, or undefined if the key does not exist.
 */
export function getValue(obj, key) {
    // Check if obj is an object and key is a string
    if (typeof obj !== 'object' || typeof key !== 'string') {
        return undefined;
    }

    // Split the key string by dots to handle nested keys
    const nestedKeys = key.split('.');

    // Initialize value with a copy of the original object
    let value = { ...obj };

    // Iterate through each nested key and update the value accordingly
    nestedKeys.forEach(k => {
        value = value[k];
    });

    return value; // Return the final value
}
