import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ContentEditable from 'react-contenteditable';
import axios from "axios";
import { Player, ControlBar } from 'video-react';
import $ from 'jquery';
import { Button } from 'reactstrap';
import moment from 'moment';
import Footer from '../Footer';
import InfiniteScroll from "react-infinite-scroll-component";
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class SinglePage extends React.Component {
   constructor(props) {

      super(props);
      let edit_id = null;
      const path = this.props.location.pathname;
      const params = this.props.location.pathname.split('/');
      if (params[params.length - 1] != 'SinglePage') {
         edit_id = params[params.length - 1];
      }
      this.contentEditable = React.createRef();
      this.state = {
         contentEditable: "timesort",
         parentComments: [], editstatus: "", editeventImage: "", editmediaType: "", editwhocansee: "", editwhocanseemeta: [], editwithwho: [], editheight: "", editwidth: "", editinclude_in_feed: "", baseUrl: process.env.REACT_APP_BASE_URL,
         eventid: edit_id, perpage: 1, itemsComents: Array.from({ length: 10 }), hasMore: true, mutuality_comment: "", alphabetically_comment: "", loading: "false", posttext2: "", posttext: "", colorIcon: "blur",
         childcommentid: "", suggestionsList: [], popName: "", popId: "", suggestionText: "", tagtype: "", editcount: 0, sendLoding: false, index: 0, follower: "", screenName: "",
         editerror: "", errortitle: "", m_route: "", duserid: "", U_name: "", dcomment: "", baseUrl: process.env.REACT_APP_BASE_URL, m_name: "", m_image: "",

      }
      this.weekDays = this.weekDays.bind(this);
   }
   mainProfileColor() {
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".username").css("color", "");
      $(".username").css("color", random_color.textColor);
      $(".username").css("background-color", "");
      $(".username").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   componentDidMount() {
      $(".upbuttonB").click(function () {
         $(".pupup1B").toggle();
      });
      $(".upbutton21").click(function () {
         $(".pupup2x").toggle();
      });
      $(".errorbtn").click(function () {
         $(".errorpop").toggle();
      });
      $(".posttt").click(function () {
         $(".pupup3post22").toggle();
      });

      var e = this.state.eventid;
      this.setState({ m_route: localStorage.getItem('userpoint'), parentComments: [], static: "", editstatus: "", editeventImage: "", editmediaType: "", editwhocansee: "", editwhocanseemeta: [], editwithwho: [], editheight: "", editwidth: "", editinclude_in_feed: "" });
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/event/getEventById.php?eventID=' + e, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.success == true) {
               this.setState({
                  m_image: responseJson.response[0].profileImage, U_name: responseJson.response[0].username, m_name: responseJson.response[0].screenName, editlike: responseJson.response[0].likedByUsers, editcount: responseJson.response[0].commentCounts, editcomment: responseJson.response[0].commentByUsers, editpostOn: responseJson.response[0].postedOn, editstatus: responseJson.response[0].status, editeventImage: responseJson.response[0].eventImage, editmediaType: responseJson.response[0].mediaType,
                  editwhocansee: responseJson.response[0].whocansee, editwhocanseemeta: responseJson.response[0].whocanseemeta, editwithwho: responseJson.response[0].withwho,
                  editheight: responseJson.response[0].height, editwidth: responseJson.response[0].width, editinclude_in_feed: responseJson.response[0].include_in_feed
               }, () => {
                  var first = this.state.m_name;
                  var firstn = first.charAt(0);
                  if (this.state.m_name[1]) {
                     var second = this.state.m_name[1]
                     var secondn = second.charAt(0);
                     this.setState({ static: firstn + secondn }, () => {
                        this.mainProfileColor();
                     });
                  }
                  else {
                     this.setState({ static: firstn }, () => {
                        this.mainProfileColor();
                     });
                  }
               });
            }
            this.getParentComment(e);
            this.setState({ eventid: e, perpage: 1, itemsComents: Array.from({ length: 10 }), hasMore: true });
         })

   }
   changeCurrentTime(seconds) {
      return () => {
         const { player } = this.player.getState();
         this.player.seek(player.currentTime + seconds);
      };
   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   editpop() {
      $(".pupup2x").toggle();
   }
   gotodelete() {
      this.setState({ editerror: 'Are you sure you would like to delete this temporal information, ' + localStorage.getItem('screenname').split(" ")[0] + '?', errortitle: "Delete temporal information" });
      $(".errorpop").toggle();
   }
   deleteComment(e) {
      if (this.state.m_route == "main" || e.currentTarget.dataset.div_user == localStorage.getItem('userid')) {
         this.setState({
            editerror: 'Are you sure you would like to delete this comment, ' + localStorage.getItem('screenname').split(" ")[0] + '?', errortitle: "Delete Comment",
            duserid: e.currentTarget.dataset.div_user, dcomment: e.currentTarget.id, index: e.currentTarget.dataset.div_index
         });
         $(".errorpop").toggle();
      }

   }
   getevent(e) {
      var id = e.currentTarget.id;
      this.setState({ editstatus: "", editeventImage: "", editmediaType: "", editwhocansee: "", editwhocanseemeta: [], editwithwho: [], editheight: "", editwidth: "", editinclude_in_feed: "" });
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/event/getEventById.php?eventID=' + id, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.success == true) {
               this.setState({
                  editlike: responseJson.response[0].likedByUsers, editcount: responseJson.response[0].commentCounts, editcomment: responseJson.response[0].commentByUsers, editpostOn: responseJson.response[0].postedOn, editstatus: responseJson.response[0].status, editeventImage: responseJson.response[0].eventImage, editmediaType: responseJson.response[0].mediaType,
                  editwhocansee: responseJson.response[0].whocansee, editwhocanseemeta: responseJson.response[0].whocanseemeta, editwithwho: responseJson.response[0].withwho,
                  editheight: responseJson.response[0].height, editwidth: responseJson.response[0].width, editinclude_in_feed: responseJson.response[0].include_in_feed
               });
            }
            var timestamp = responseJson.response[0].postedOn;
            this.getPostOnDetail(timestamp);
         })
         .catch((error) => {
         })
   }
   weekDays = (dayNumber) => {
      if (dayNumber == 0) {
         return "Sunday";
      }
      if (dayNumber == 6) {
         return "Saturday";
      }
      if (dayNumber == 5) {
         return "Friday";
      }
      if (dayNumber == 4) {
         return "Thursday";
      }
      if (dayNumber == 3) {
         return "Wednesday";
      }
      if (dayNumber == 2) {
         return "Tuesday";
      }
      if (dayNumber == 1) {
         return "Monday";
      }
   }

   alphabetically_comment() {
      var e = this.state.eventid;
      this.setState({ mutuality_comment: "", perpage: 1, itemsComents: Array.from({ length: 10 }), hasMore: true });
      this.setState({ alphabetically_comment: "alphabetically" });
      this.getParentComment(e);
   }
   relevancy_comment() {
      var e = this.state.eventid;
      this.setState({ mutuality_comment: "", perpage: 1, itemsComents: Array.from({ length: 10 }), hasMore: true });
      this.setState({ alphabetically_comment: "" });
      this.getParentComment(e);
   }
   mutuality_comment() {
      var e = this.state.eventid;
      this.setState({ alphabetically_comment: "", perpage: 1, itemsComents: Array.from({ length: 10 }), hasMore: true });
      this.setState({ mutuality_comment: "mutuality" });
      this.getParentComment(e);
   }
   getParentComment(e) {
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/comment/getParentComments.php?eventID=' + e, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.response.length < 9) {
               $('#march').addClass('closeCd');
               this.setState({ hasMore: false });
            }
            if (this.state.mutuality_comment != "") {
               this.setState({ parentComments: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() }, () => {
                  this.mainProfileColor();
               });
            }
            if (this.state.alphabetically_comment != "") {
               this.setState({ parentComments: responseJson.response.sort((a, b) => a.screenName.localeCompare(b.screenName)) }, () => {
                  this.mainProfileColor();
               });
            }
            else {
               this.setState({ parentComments: responseJson.response, loading: "false" }, () => {
                  this.mainProfileColor();
               });
            }
         }).catch((error) => { console.log(error); })
   }
   submitdeletecomment() {
      $(".errorpop").toggle();
      var token = localStorage.getItem('token');
      if (this.state.errortitle == "Remove Follower") {
         const body = { profileId: this.state.popId }
         axios.post(this.state.baseUrl + '/api/follow/removeFollower.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
               console.log('res');
            }).catch((error) => {
               console.log(error);
            })
      }
      if (this.state.errortitle == "Block " + this.state.popName) {
         const body = { profileId: this.state.popId }
         axios.post(this.state.baseUrl + '/api/user/blockUser.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
               console.log('res');
            }).catch((error) => {
               console.log(error);
            })
      }
      if (this.state.errortitle == "Delete Comment") {
         this.state.parentComments.splice(this.state.index, 1);
         this.setState({ parentComments: this.state.parentComments });
         const body = {
            eventID: this.state.eventid,
            commentID: this.state.dcomment,
            parentCommentBy: this.state.duserid,
            commentBy: this.state.duserid,
            isParent: 1
         }
         axios.post(this.state.baseUrl + '/api/comment/deleteComment.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
               this.setState({ editcount: this.state.editcount - 1 })
               console.log('res');
            }).catch((error) => {
               console.log('e', error);
            })
      }
   }
   findHashtags(searchText) {
      var regexp = /\B\#\w+\b/g;
      var result = searchText.match(regexp);
      if (result) {
         const filterTags = (result) => result.filter((v, i) => result.indexOf(v) === i);
         const hashTags = filterTags(result);
         if (hashTags) {
            const tags = hashTags.join().replace(/#/gi, "");
            this.setState({ hashTagstext: tags });
            return tags;
         }
      }
      return null;
   }
   sendcomment() {
      this.setState({ sendLoding: true });
      if (this.state.childcommentid == "") {
         var parent = 0;
      }
      else {
         var parent = this.state.childcommentid;
      }
      if (this.state.posttext != "") {
         var post = this.state.posttext.replace('<br>', '');
         var searchText = post.replace(/(<([^>]+)>)/ig, '');
         var body = {
            eventID: this.state.eventid,
            comment: searchText,
            parentID: parent,
            commentAt: Math.floor(new Date() / 1000),
            user_id: localStorage.getItem('userid'),
            hashtags: this.findHashtags(searchText)
         };
         const token = localStorage.getItem('token');
         axios.post(this.state.baseUrl + '/api/comment/addComment.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         }).then((res) => {
            var e = this.state.eventid;
            this.getParentComment(e);
            $('#labelholder').removeClass('newlabel');
            setTimeout(function () {
               document.getElementById("type").focus();
            }, 1000);
            this.setState({ posttext2: "", posttext: "", colorIcon: "blur", sendLoding: false });
         });
      }
   }

   fetchMoreComment() {
      setTimeout(() => {
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         const totaluser = this.state.editcount;
         const recordp = totaluser / 10;
         if (this.state.perpage >= recordp) {
            $('#march').addClass('closeCd');
            this.setState({ hasMore: false });
            return;
         }
         this.setState({ perpage: this.state.perpage + 1 });
         fetch(this.state.baseUrl + '/api/comment/getParentComments.php?eventID=' + this.state.eventid + '&page=' + this.state.perpage + '&user_id=' + variable + '&limit=10', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response.length == 0) {
                  $('.commestsn').addClass('lastcomment');
                  this.setState({ hasMore: false });
               }
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.parentComments.push(responseJson.response[i]);
               }
               if (this.state.mutuality_comment != "") {
                  this.setState({ parentComments: this.state.parentComments.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() }, () => {
                     this.mainProfileColor();
                  });
               }
               if (this.state.alphabetically_comment != "") {
                  this.setState({
                     parentComments: this.state.parentComments.sort((a, b) =>
                        a.screenName.localeCompare(b.screenName))
                  }, () => {
                     this.mainProfileColor();
                  });
               }
               else {
                  this.setState({ parentComments: this.state.parentComments }, () => {
                     this.mainProfileColor();
                  });
               }
            });
         this.setState({
            itemsComents: this.state.itemsComents.concat(Array.from({ length: 10 }))
            , loading: "false"
         });

      }, 1000);

   }
   posttext = evt => {
      var con1 = evt.target.value.replace(/<\/?span[^>]*>/g, "");
      var content = con1.replace(/<([^.]+)>.*?<\/\1>/ig, '');
      var content2 = content.replace('<br>', "");
      if (content2 == "" | content2 == "<br>") {
         $('#labelplaceholder').removeClass('newlabel');
         $('#labelholder').removeClass('newlabel');
         $('#labelholderto').removeClass('newlabel');
         this.setState({ colorIcon: "blur" });
      }
      else {
         $('#labelplaceholder').addClass('newlabel');
         $('#labelholder').addClass('newlabel');
         $('#labelholderto').addClass('newlabel');
         this.setState({ colorIcon: "" });
      }
      this.setState({ posttext: evt.target.value });
      var str = evt.target.value; // your string here
      str = str.replace(/<\/?span[^>]*>/g, "");
      str = str.replace(';', "");
      const regex = /(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/g;
      const replaced2 = str.replace(regex, value => `<span>${value}</span>`);
      this.setState({ posttext2: replaced2 });
      if (evt.target.value && evt.target.value.length > 0) {
         var n = evt.target.value.split(" ");
         var lastWord = n[n.length - 1];
         if (lastWord.includes("#")) {
            if (lastWord && !lastWord.includes("'")) {
               this.getHashTags(lastWord);
            }
         }
         else if (lastWord.includes("@")) {
            if (lastWord && !lastWord.includes("'")) {
               this.getMentions(lastWord);
            }
         }
         else {
            this.resetSuggestions();
         }
      }
      else {
         this.resetSuggestions();
      }
   }
   resetSuggestions() {
      var suggestionText = "";
      $('.sugetionList').addClass('listb');
      this.setState({ suggestionsList: [], tagtype: "" });
   }

   blockuserpop() {
      this.setState({ errortitle: "Block " + this.state.popName, editerror: "Are you sure would like to block " + this.state.screenName + ", " + localStorage.getItem("screenname").split(" ")[0] }, () => {
         $(".errorpop").toggle();
      });
   }
   removeuserpop() {
      this.setState({ errortitle: "Remove Follower", editerror: "Are you sure would like to remove " + this.state.screenName + " as a follower, " + localStorage.getItem("screenname").split(" ")[0] }, () => {
         $(".errorpop").toggle();
      });
   }
   getMentions(lastWord) {
      const input = $('<span>').html(lastWord).text();
      var arr = input.split("@");
      if (arr.length > 1 && arr[arr.length - 1] != "") {
         var suggestionText = arr[arr.length - 1];
         const token = localStorage.getItem('token');
         fetch(this.state.baseUrl + '/api/event/getMention.php?search=' + suggestionText, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response) {
                  if (this.state.posttext && this.state.posttext.length > 0) {
                     this.setState({ suggestionsList: responseJson.response, tagtype: "mention" });
                     $('.sugetionList').removeClass('listb');
                  }
                  else {
                     this.resetSuggestions();
                  }
               } else {
                  this.resetSuggestions();
               }

            }).catch((error) => {
            })
      }
      else {
         this.setState({ suggestionsList: [] });
      }
   }
   gettag(e) {
      var value = e.target.id;
      if (this.state.tagtype == "hash") {
         var my = this.state.posttext.split(" ").slice(0, -1).join(" ") + " " + "#" + value;
         const regex = /#(.+?)(?=[\s.,:,]|$)|@(.+?)(?=[\s.,:,]|$)/ig;
         const replaced2 = my.replace(regex, value => `<span>${value}</span>`);
         this.setState({ posttext2: replaced2 });
         this.setState({ posttext: my });
         var editable = $('#type');
         setTimeout(function () {
            document.getElementById("type").focus();
         }, 1000);
         $('.sugetionList').addClass('listb');
      }
      else {
         var my = this.state.posttext.split(" ").slice(0, -1).join(" ") + " " + "@" + value;
         const regex = /#(.+?)(?=[\s.,:,]|$)|@(.+?)(?=[\s.,:,]|$)/ig;
         const replaced2 = my.replace(regex, value => `<span>${value}</span>`);
         this.setState({ posttext2: replaced2 });
         this.setState({ posttext: my });
         var editable = $('#type');
         setTimeout(function () {

            document.getElementById("type").focus();
         }, 1000);
         $('.sugetionList').addClass('listb');
      }
   }
   getHashTags(lastWord) {
      const input = $('<span>').html(lastWord).text();
      var arr = input.split("#");
      if (arr.length > 1 && arr[arr.length - 1] != "") {
         var suggestionText = arr[arr.length - 1];
         const token = localStorage.getItem('token');
         fetch(this.state.baseUrl + '/api/event/getHashtag.php?hashtag=' + suggestionText, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response) {
                  if (this.state.posttext && this.state.posttext.length > 0) {
                     this.setState({ suggestionsList: responseJson.response, tagtype: "hash" });

                     $('.sugetionList').removeClass('listb');
                  }
                  else {
                     this.resetSuggestions();
                  }
               } else {
                  this.resetSuggestions();
               }
            }).catch((error) => {
            })
      }
      else {
         this.setState({ suggestionsList: [] });
      }
   }
   getdateformat(datedata) {
      if (datedata) {
         var postedDate = moment.unix(datedata).format("YYYY-MM-DD");
         var currentDate = moment(new Date()).format("YYYY-MM-DD");
         var eventDate = datedata;
         var date = "";
         var duration = moment.duration(moment(postedDate).diff(moment(currentDate)));
         var daysDifference = duration.asDays();
         if (daysDifference <= -8 || daysDifference > 7) {
            const day = moment.unix(eventDate).format("D");
            const month = moment.unix(eventDate).format("MMM");
            const time = moment.unix(eventDate).format("h:mma");
            var lasttag = "";
            switch (day % 10) {
               case 1:
                  if (day != 11) {
                     lasttag = "st";
                  } else {
                     lasttag = "th";
                  }
                  break;
               case 2:
                  if (day != 12) {
                     lasttag = "nd";
                  } else {
                     lasttag = "th";
                  }
                  break;
               case 3:
                  if (day != 13) {
                     lasttag = "rd";
                  } else {
                     lasttag = "th";
                  }
                  break;
               default: lasttag = "th";
                  break;
            }
            date = `${month} ${day}${lasttag}, ${time}`;
            return date;
         }
         if (daysDifference < -1 && daysDifference > -8) {
            date = "last" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference < 0 && daysDifference >= -1) {
            date = "yesterday at " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 0 && daysDifference < 1) {
            date = moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 1 && daysDifference < 2) {
            date = "tomorrow at " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 2 && daysDifference < 7) {
            date = "this" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference == 7) {
            date = "next" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
      }
   }
   collectBio(value) {
      var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      if (more) {
         if (more.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < more.length; index++) {
                  $("." + more[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/' + more[index].substring(1),
                     })
                     localStorage.setItem("backid", "front");
                  }.bind(this));
               }
            }.bind(this));
         }
      }
   }
   cellectMention(value) {
      var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      if (more) {
         if (more.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < more.length; index++) {
                  $("." + more[index].substring(1)).click(function () {
                     this.props.history.push({
                        pathname: '/' + more[index].substring(1),
                     })
                     localStorage.setItem("backid", "front");
                  }.bind(this));
               }
            }.bind(this));
         }
      }
   }
   checkComment() {
      if (this.state.parentComments.length > 8) {
         return "loaderclass load";
      }
      else {
         return "loaderclass";
      }
   }
   popuplist(e) {
      $('.pupup1B').addClass('closeBd');
      $('.pupup1B').removeClass('closeCd');
      this.setState({ popName: e.currentTarget.dataset.div_name, popId: e.currentTarget.id, follower: e.currentTarget.dataset.div_follow, screenName: e.currentTarget.dataset.div_screen });
   }
   mainpop() {
      $('.pupup1B').addClass('closeBd');
      $('.pupup1B').removeClass('closeCd');
      this.setState({ popName: this.state.m_name.split(" ")[0], popId: this.state.c_userid, screenName: this.state.m_name });
   }
   closepop() {
      $('.pupup1B').addClass('closeCd');
      $('.pupup1B').removeClass('closeBd');
   }
   seeMore() {
      var id = this.state.popId;
      $('.pupup1B').removeClass('closeBd');
      $('#Top' + id).addClass('closeBd');
      setTimeout(function () {
         $('#Top' + id).removeClass('closeBd');
      }, 3000);


   }
   seeLess() {
      var id = this.state.popId;
      $('.pupup1B').removeClass('closeBd');
      $('#Less' + id).addClass('closeBd');
      setTimeout(function () {
         $('#Less' + id).removeClass('closeBd');
      }, 1000);
   }

   goChat() {

      $('.pupup1B').addClass('closeCd');
      $('.pupup1B').removeClass('closeBd');
      $('#one_90').addClass('active fades');
      $('#one_90').removeClass('active fades');
   }
   commentLike(e) {
      var mainid = e.currentTarget.id;
      var like = e.currentTarget.dataset.div_like;
      var objIndex = this.state.parentComments.findIndex((obj => obj.commentID == mainid));
      if (like == 1) {
         if (e.currentTarget.div_id > 0) {
            var count = e.currentTarget.div_id - 1;
         }
         else {
            var count = 0;
         }
         if (objIndex !== -1) {
            this.state.parentComments[objIndex].isLiked = 0;
            this.state.parentComments[objIndex].likesCount = count;
            this.setState({ parentComments: this.state.parentComments });
         }
      }
      if (like == 0) {
         if (e.currentTarget.div_id > 0) {
            var count = e.currentTarget.div_id + 1;
         }
         else {
            var count = 1;
         }
         if (objIndex !== -1) {
            this.state.parentComments[objIndex].isLiked = 1;
            this.state.parentComments[objIndex].likesCount = count;
            this.setState({ parentComments: this.state.parentComments });
         }
      }
      const body = {
         user_id: e.currentTarget.dataset.div_name,
         commentID: e.currentTarget.id,
         eventID: this.state.eventid
      };
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/comment/likeComment.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            console.log('res');
         })
         .catch((error) => {
            console.log('Invalid', error);
         })
   }
   gotouser() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }

   likepost() {
      $("#singlelike").toggleClass("thumbs-down");
      var hascc = $('div').hasClass('thumbs-down');
      if (hascc == true) {
         setTimeout(function () {
            $('.thumbs-down').removeClass('thumbs-down');
         }, 1500);
      }
      const body = {
         user_id: localStorage.getItem('userid'),
         eventID: this.state.eventid,
      }
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/event/addOrRemoveLike.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            console.log('res');
         });
   }

   collectMentions(value) {
      var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      var hash = value.match(/(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      if (more || hash) {
         if (more !== null && more.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < more.length; index++) {
                  $("." + more[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/' + more[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
         if (hash !== null && hash.length > 0) {
            console.log()
            $(document).ready(function () {
               for (let index = 0; index < hash.length; index++) {
                  $("." + hash[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/tag/' + hash[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
      }
   }
   fetchafterfilter(jstatus) {
      this.collectMentions(jstatus);
      var regex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/ig;
      var replaced2 = jstatus.replace(regex, value => `<a href=${"https://" + value} class='hashtag_link' target='_blank'>${value}</a>`);
      var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig;
      var replaced7 = replaced2.replace(mention_preg, value => `<a class=${value.substring(1)}>${value}</a>`);
      var regex2 = /!+([a-zA-Z0-9_]+)/ig;
      var replaced3 = replaced7.replace(regex2, value => `<span>${value}</span>`);
      var replaced = replaced3.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return replaced;
   }
   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }

   render() {
      return (
         <div>
            {/* <div className="newscreen1 newscreen01">
<div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}  id="one_15"> */}

            <div className={`${this.props.pos} screen1`} id="one_15">
               <div className="top newbg" id="myHeader">
                  {/* <!-- --------- header ---------------> */}
                  <section className="header">
                     <div className="row">
                        <div className="col-4 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li>
                                 <a className="linkback" onClick={this.gotouser.bind(this)} data-toggle="tab"><img src="../../images/back.png" align="img" /></a>
                              </li>
                           </ul>
                        </div>
                        <div className="col-4 pl-0 pr-0 header_center text-center">
                           <h6>Timesort</h6>
                        </div>
                        <div className="col-4 header_right pl-0">
                           <ul className="uld">
                              <li className="posttt"><a><img src="../../images/more2.png" align="img" /></a></li>
                           </ul>
                        </div>
                     </div>
                  </section>
               </div>
               <div className="tab-content kkk">
                  <div
                     id="scrollableDivComment"
                     className="scrollClass pt-0 newscreen1"
                     style={{
                        overflow: 'auto',
                        display: 'row',
                        flexDirection: 'row',
                        margintop: 47,
                     }}
                  >
                     <InfiniteScroll
                        dataLength={this.state.itemsComents.length}
                        next={this.fetchMoreComment.bind(this)}
                        style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                        inverse={false} //

                        hasMore={true}
                        loader={
                           <div id="march" className={this.checkComment()}>
                              <img
                                 src="../../images/newgif.gif"
                                 alt="loading"
                              />
                           </div>}
                        endMessage={
                           <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                           </p>}
                        useWindow={false}
                        scrollableTarget="scrollableDivComment"
                     >
                        <div className="sectionone">
                           <div className="mb-2 ">
                              <div className="postlikeB" id="singlelike" onDoubleClick={this.likepost.bind(this)}>
                                 <div className="userin userinpost">
                                    <a><Link to={{
                                       pathname: `/${this.state.U_name}`,
                                       state: { id: this.state.c_userid }
                                    }} onClick={this.setStorage.bind(this)}
                                    >{this.state.m_image != "" ? <img src={this.state.baseUrl + this.state.m_image} align="images" /> : <span className="username">{this.fetchmakeprofile(this.state.m_name)}</span>} </Link></a>
                                    <h4><Link to={{
                                       pathname: `/${this.state.U_name}`,
                                       state: { id: this.state.c_userid }
                                    }} onClick={this.setStorage.bind(this)}
                                    >{this.state.m_name}{'\u00A0'}</Link>
                                       <span>{this.getdateformat(this.state.editpostOn)}</span>
                                    </h4>
                                    <h5 dangerouslySetInnerHTML={{ __html: this.fetchafterfilter(this.state.editstatus) }} />
                                 </div>
                                 <div id={'Top' + this.state.c_userid} class="likeadd"><img class="w-100" src="../../images/More.png" alt="image" /></div>
                                 <div id={'Less' + this.state.c_userid} class="likeadd"><img class="w-100" src="../../images/setting_dont_follow_back.png" alt="image" /></div>
                                 <div className="post ">
                                    {(() => {
                                       if (this.state.editmediaType == "video") {
                                          return (
                                             <div class="video-div">
                                                <Player
                                                   ref={player => {
                                                      this.player = player;
                                                   }}
                                                   autoPlay={true}>
                                                   <source src={this.state.baseUrl + this.state.editeventImage} />
                                                   <ControlBar autoHide={false} className="my-class load" />
                                                </Player>
                                                <ul class="videoulkap pb-3">
                                                   <li><Button onClick={this.changeCurrentTime(-10).bind(this)} ><img class="img1" src="../../images/Backwards.png" />
                                                      <span>10</span></Button>
                                                   </li>
                                                   <li><img class="img2" src="../../images/pause.png" /></li>
                                                   <li><Button onClick={this.changeCurrentTime(10).bind(this)} ><img class="img3" src="../../images/Forwards.png" />
                                                      <span>10</span></Button>
                                                   </li>
                                                </ul>
                                             </div>);
                                       }
                                       else if (this.state.editmediaType == "image") {
                                          return (
                                             <img className="w-100 mt-1" src={this.state.baseUrl + this.state.editeventImage} alt="image" />
                                          );
                                       }
                                    }
                                    )()}
                                    <div className="hearti">
                                       <img className="w-100" src="../../images/cklick.png" alt="image" />
                                    </div>
                                 </div>
                              </div>
                              <ul className={this.state.editmediaType == null || this.state.editmediaType == "" ? "videoul videoulpost mt3 status" : "videoul videoulpost mt3"}>
                                 <li><a className="comentbt" ><img src="../../images/comment.png" defaultStyle="height:20px;"
                                    alt="icon" /> {(() => {
                                       if (this.state.editcount != 0) {
                                          return (<span>{this.state.editcount}</span>);
                                       }
                                    })()}</a>
                                    {this.state.m_route == "main" ? <a onClick={this.editpop.bind(this)}><img className="option ml-3" src="../../images/option.png"
                                       align="icon" /></a> : <a onClick={this.mainpop.bind(this)}><img className="option ml-3" src="../../images/option.png"
                                          align="icon" /></a>}

                                 </li>
                              </ul>
                           </div>
                           <div className={this.state.hasMore == false ? "commestsn democlass1 commestsn_endbutton" : "commestsn democlass1"}>
                              {this.state.parentComments.map((j, x) => {
                                 return (<div className="mb-2">
                                    <div className="userin" id={j.commentID} data-div_user={j.user_id} data-div_index={x} onDoubleClick={this.deleteComment.bind(this)}>
                                       <a><Link to={{
                                          pathname: `/${j.username}`,
                                          state: { id: j.user_id }
                                       }} onClick={this.setStorage.bind(this)}
                                       >{j.profileImage != "" ? <img src={this.state.baseUrl + j.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(j.screenName)}</span>}</Link></a>
                                       <h4>
                                          <Link to={{
                                             pathname: `/${j.username}`,
                                             state: { id: j.user_id }
                                          }} onClick={this.setStorage.bind(this)}
                                          ><j>{j.screenName}</j></Link>
                                       </h4>
                                       <h5 dangerouslySetInnerHTML={{ __html: this.fetchafterfilter(j.comment) }} />
                                    </div>
                                    <div id={'Top' + j.user_id} class="likeadd"><img class="w-100" src="../../images/More.png" alt="image" /></div>
                                    <div id={'Less' + j.user_id} class="likeadd"><img class="w-100" src="../../images/setting_dont_follow_back.png" alt="image" /></div>
                                    <ul className="videoul mt3">
                                       <li className="mmnt">
                                          <a>
                                             <span id={j.commentID} className={"heart" + j.commentID} data-div_scname={j.screenName} data-div_name={j.user_id} data-div_like={j.isLiked} onClick={this.commentLike.bind(this)} data-div_id={j.likesCount}>
                                                {j.isLiked == 1 ? <img src="../../images/cb.png" alt="icon" defaultStyle="height:20px;" /> : <img src="../../images/c.png" alt="icon" defaultStyle="height:20px;" />}</span>
                                             <span id={j.commentID} data-div_name={j.likesCount} data-div_class={j.screenName} data-div_id={j.user_id} className={" moveleft count" + j.commentID}>
                                                <Link to={{
                                                   pathname: `/Likes/${j.screenName.split(' ')[0]}/${this.state.eventid}/${j.user_id}/${j.commentID}/${j.likesCount}`
                                                }}
                                                   onClick={this.setStorage.bind(this)}
                                                >
                                                   <a className="link"  >{j.likesCount != 0 ? j.likesCount : ""}</a></Link></span> </a>
                                          <span className="link" className={(j.commentByUsers.length > 0 && j.likesCount != 0) ? "ml-3 comentbt " : "ml-3 comentbt fffff"} ><Link to={{
                                             pathname: `/CommentOn/${j.commentID}`,
                                             state: { m_route: this.state.m_route, eventid: this.state.eventid, username: j.username, commentid: j.commentID, userid: j.user_id, comment: j.comment, islike: j.isLiked, name: j.screenName, image: j.profileImage, likes: j.likesCount, commentCount: j.chieldCount }
                                          }} onClick={this.setStorage.bind(this)}
                                          >
                                             <img src="../../images/comment.png" defaultStyle="height:20px;" alt="icon" /> <span className="moveleft">{j.chieldCount > 0 ? j.chieldCount : ""}</span></Link></span>
                                          {localStorage.getItem('userid') ? j.user_id != localStorage.getItem('userid') ? <a className="upbuttonB" data-div_follow={j.isFollowers} data-div_screen={j.screenName} data-div_name={j.screenName.split(" ")[0]} onClick={this.popuplist.bind(this)} id={j.user_id}><img className="option ml-3" src="../../images/option.png" align="icon" /></a> : "" : ""}
                                       </li>
                                       {j.likedByUsers.length > 0 ? <li><img src="../../images/c.png" alt="icon" />{j.likedByUsers.length ? j.likedByUsers.map((m, t) => {
                                          return (<span><a href="#"><Link to={{
                                             pathname: `/${m.username}`,
                                             state: { id: m.user_id }
                                          }} onClick={this.setStorage.bind(this)}
                                          >{m.screenName}</Link></a><span>,</span> </span>)
                                       }) : ""}</li> : ""}
                                       {j.commentByUsers.length > 0 ? <li><img src="../../images/d.png" alt="icon" />{j.commentByUsers.length ? j.commentByUsers.map((m, t) => {
                                          return (<span><a href="#"><Link to={{
                                             pathname: `/${m.username}`,
                                             state: { id: m.user_id }
                                          }} onClick={this.setStorage.bind(this)}
                                          >{m.screenName}</Link></a><span>,</span> </span>)
                                       }) : ""}</li> : ""}
                                    </ul>
                                 </div>)
                              })}
                              {/* <form className="intype">
      <input type="text" className="form-control" id="type" placeholder={"Comment on your post," +localStorage.getItem('screenname').split(" ")[0]+"!"}/>
      <button type="submit"><img src="images/sendicon.png"/></button>
   </form> */}



                           </div>
                        </div>
                     </InfiniteScroll>
                  </div>
               </div>
               <div className="sugetionList sugetionListcomment listb">
                  <ul className="sugetionul">
                     {(() => {
                        if (this.state.suggestionsList.length > 0) {
                           return (
                              this.state.suggestionsList.map((c, i) => {
                                 if (this.state.tagtype == "hash") {
                                    return (<li className="sugetionli" id={c.hashtag} onClick={this.gettag.bind(this)}>{'#' + c.hashtag}</li>)
                                 }
                                 else {
                                    return (<li className="sugetionli" id={c.username} onClick={this.gettag.bind(this)}>{"@" + c.username}</li>)
                                 }
                              }
                              ))
                        }
                     })()}
                  </ul>
               </div>
               <div className="intype commentdiv">
                  <div defaultStyle="position:relative;">
                     <div className={this.state.sendLoding == true ? "loaderclass load" : "loaderclass closeCd"}>
                        <img
                           src="../../images/newgif.gif"
                           alt="loading"
                        />
                     </div>
                     <ContentEditable
                        id="type"
                        className="form-control1"
                        innerRef={this.contentEditable}
                        html={this.state.posttext2} // innerHTML of the editable div
                        disabled={false}       // use true to disable editing
                        onChange={this.posttext.bind(this)} // handle innerHTML change
                        tagName='article' // Use a custom HTML tag (uses a div by default)
                     />
                     <label id="labelholder" >{localStorage.getItem('screenname') ? "Comment on your post, " + localStorage.getItem('screenname').split(" ")[0] + "!" : ""}</label>
                     <button onClick={this.sendcomment.bind(this)} type="btn">{this.state.colorIcon == "blur" ? <img src="../../images/sendicon.png" /> : <img src="../../images/ip.png" />}</button>
                  </div>
               </div>
               <Footer {...this.props} />
               <div className="op pupup3post22">
                  <div className="posttt h-100"></div>
                  <ul>
                     <h5 className="text-center">sort</h5>
                     <li><a className="posttt" id="secondRelevancy" onClick={this.relevancy_comment.bind(this)} ><img src="../../images/More.png" /> relevancy</a></li>
                     <li><a className="posttt" id="secondMutuality" onClick={this.mutuality_comment.bind(this)}><img src="../../images/mut.png" /> mutuality</a></li>
                     <li><a className="posttt" id="secondAlphabetically" onClick={this.alphabetically_comment.bind(this)}><img src="../../images/az.png" /> alphabetically</a></li>
                     <button className="upbtn posttt">Cancel</button>
                  </ul>
               </div>

               <div className="op pupup1B">
                  <div className="upbuttonB h-100" onClick={this.closepop.bind(this)}></div>
                  <ul>
                     <li><a data-div_id={this.state.popId} onClick={this.seeMore.bind(this)}><img src="../../images/More.png" /> see more like this</a></li>
                     <li><a data-div_id={this.state.popId} onClick={this.seeLess.bind(this)}><img src="../../images/setting_dont_follow_back.png" /> see less like this</a></li>
                     <li><a data-div_id={this.state.popId} onClick={this.goChat.bind(this)} ><img src="../../images/setting_request.png" />{'message ' + this.state.popName} </a></li>
                     {this.state.follower == 1 ? <li><a className="more" onClick={this.removeuserpop.bind(this)}><img src="../../images/remove.png" /> remove follower</a></li> : ""}
                     <li><a onClick={this.blockuserpop.bind(this)} ><img src="../../images/block.png" />{'block ' + this.state.popName}</a></li>
                     <li><span data-div_id={this.state.popId} className="link">
                        <Link to={{
                           pathname: `/Report/${this.state.popId}`,
                           state: { name: this.state.screenName }
                        }} onClick={this.setStorage.bind(this)}>
                           <img src="../../images/report.png" /> {'report ' + this.state.popName}</Link> </span></li>
                     <button className="upbtn upbuttonB" onClick={this.closepop.bind(this)}>Cancel</button>
                  </ul>
               </div>
               <div className="op pupup2x">
                  <div className="upbutton21 h-100"></div>
                  <ul>
                     <li><a className="link" id={this.state.eventid} onClick={this.getevent.bind(this)} href="#one_16"><img src="../../images/edt.png" /> Edit</a></li>
                     <li><a onClick={this.gotodelete.bind(this)}><img src="../../images/dlt.png" /> Delete</a></li>
                     <button className="upbtn upbutton21">Cancel</button>
                  </ul>
               </div>
               <div className="op errorpop mainpop">
                  <div className="errorbtn error h-100"></div>
                  <ul>
                     <li className="lipost">{this.state.errortitle}</li>
                     <li>{this.state.editerror}</li>
                     <div className="newpop">
                        <button className="upbtn errorbtn">CANCEL</button>
                        <button className="upbtn errorbtn" onClick={this.submitdeletecomment.bind(this)}>YES</button>
                     </div>
                  </ul>
               </div>
            </div>
         </div>

         //</div>

      );
   };
};



export default SinglePage;