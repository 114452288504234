import React, { Component } from 'react';
import { withRouter, BrowserRouter, Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import ContentEditable from 'react-contenteditable';
import axios from "axios";
import { Player, ControlBar } from 'video-react';
import $ from 'jquery';
import { Button } from 'reactstrap';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import Footer from "../Footer";


class CommentOn extends React.Component {
   constructor(props) {

      super(props);
      let edit_id = null;
      const path = this.props.location.pathname;
      const params = this.props.location.pathname.split('/');
      edit_id = params[params.length - 1];

      this.contentEditable = React.createRef();
      this.state = {
         contentEditable: "timesort",
         childcomment2: "",
         childname: "",
         childimage: "",
         childlike: "",
         childcount: "",
         childliked: "",
         childcommentid: "",
         childuserid: "", sendLoding: false,
         eventid: "", colorIcon: "blur", foleOne: "",
         childcomment: [], suggestionsList: [],
         posttext2: "", posttext: "", m_route: "",
         baseUrl: process.env.REACT_APP_BASE_URL, edit_id: edit_id, popName: "", popId: "",
         mutuality_comment: "", perpage: 1, itemsComents: Array.from({ length: 10 }), hasMore: true, alphabetically_comment: "", screenval: "",
         like_userid: "", commentId: "", totalLikes: "", perpage: 1, searchPerameter: "", commentsLikes: [], items: 0, items: Array.from({ length: 40 }), hasMore: true,
         like_userid: "", commentId: "", totalLikes: "", userName: "", followerSearch: [], alphabetically_likes: "", mutuality_likes: "",
         statepage: 0, searchPage: 1, itemsearchfo: Array.from({ length: 40 }), static: "", childuser: "",
      }
   }
   componentDidUpdate() {
      if (this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] !== "" && this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] !== undefined) {
         if (this.state.edit_id !== this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1]) {
            this.setState({ edit_id: this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] }, () => {
               this.setfunction();
               $('#one_22').removeClass('active fades');
               setTimeout(() => {
                  $('#one_22').addClass('active fades');
               }, 300);
            });
         }
      }
   }
   componentDidMount() {
      $(".upbutton").click(function () {
         $(".pupup2").toggle();
      });
      $(".upbuttonB").click(function () {
         $(".pupup1B").toggle();
      });
      $(".upbuttonBd").click(function () {
         $(".pupup1B2").toggle();
      });
      $(".upbutton2").click(function () {
         $(".pupup2").toggle();
      });
      $(".upbutton21").click(function () {
         $(".pupup2x").toggle();
      });
      $(".errorbtn31").click(function () {
         $(".errorpopx").toggle();
      });
      // $(".errorbtn").click(function(){
      // $(".errorpop").toggle();
      // });
      $(".remove").click(function () {
         $(".pupup3").toggle();
      });
      $(".more").click(function () {
         $(".pupup4").toggle();
      });
      $(".more2 ").click(function () {
         $(".pupup5 ").toggle();
      });
      $(".more3").click(function () {
         $(".pupup6").toggle();
      });
      $(".close").click(function () {
         $(".pupup6").toggle();
      });
      $(".more3btn").click(function () {
         $(".pupup6").toggle();
      });
      $(".more4").click(function () {
         $(".pupup7").toggle();
      });
      $(".more5").click(function () {
         $(".pupup8").toggle();
      });
      $(".more6").click(function () {
         $(".pupup9").toggle();
      });
      $(".calenderof").click(function () {
         $(".pupupcalander").toggle();
      });
      $(".upbuttonpost").click(function () {
         $(".pupuppost").toggle();
      });
      $(".removepost").click(function () {
         $(".pupup3post").toggle();
      });
      $(".posttt").click(function () {
         $(".pupup3post22").toggle();
      });
      this.setfunction();
   }
   setfunction() {
      this.setState({
         m_route: this.props.location.state.m_route,
         childuser: this.props.location.state.username,
         childcomment2: this.props.location.state.comment,
         childname: this.props.location.state.name,
         childimage: this.props.location.state.image,
         childlike: this.props.location.state.likes,
         childcount: this.props.location.state.commentCount,
         childliked: this.props.location.state.islike,
         childcommentid: this.props.location.state.commentid,
         childuserid: this.props.location.state.userid,
         eventid: this.props.location.state.eventid,
         posttext2: "", posttext: "", hasMore: true, itemsComents: Array.from({ length: 10 }), perpage: 1
      });
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/comment/getChildComments.php?commentID=' + this.state.edit_id + '&eventID=' + this.props.location.state.eventid, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.response.length < 9) {
               $('#march').addClass('closeCd');
               this.setState({ hasMore: false });
            }
            if (this.state.mutuality_comment != "") {
               this.setState({ childcomment: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() });
            }
            if (this.state.alphabetically_comment != "") {
               this.setState({
                  childcomment: responseJson.response.sort((a, b) =>
                     a.screenName.localeCompare(b.screenName))
               });
            }
            else {
               this.setState({ childcomment: responseJson.response, loading: "false" });
            }
         }).catch((error) => { console.log(error); })
      var first = localStorage.getItem('screenname').split(" ")[0]
      var firstn = first.charAt(0);
      if (localStorage.getItem('screenname').split(" ")[1]) {
         var second = localStorage.getItem('screenname').split(" ")[1]
         var secondn = second.charAt(0);
         this.setState({ static: firstn + secondn });
      }
      else {
         this.setState({ static: firstn });
      }
   }



   gotodelete() {
      this.setState({ editerror: 'Are you sure you would like to delete this temporal information, ' + localStorage.getItem('screenname').split(" ")[0] + '?', errortitle: "Delete temporal information" });
      $(".errorpop").toggle();
   }
   deleteComment(e) {
      if (this.state.m_route == "main" || e.currentTarget.dataset.div_user == localStorage.getItem('userid')) {
         this.setState({ editerror: 'Are you sure you would like to delete this comment, ' + localStorage.getItem('screenname').split(" ")[0] + '?', errortitle: "Delete Comment", duserid: e.currentTarget.dataset.div_user, dcomment: e.currentTarget.id });
         $(".errorpop").toggle();
      }
   }

   weekDays = (dayNumber) => {
      if (dayNumber == 0) {
         return "Sunday";
      }
      if (dayNumber == 6) {
         return "Saturday";
      }
      if (dayNumber == 5) {
         return "Friday";
      }
      if (dayNumber == 4) {
         return "Thursday";
      }
      if (dayNumber == 3) {
         return "Wednesday";
      }
      if (dayNumber == 2) {
         return "Tuesday";
      }
      if (dayNumber == 1) {
         return "Monday";
      }
   }


   changeCurrentTime(seconds) {
      return () => {
         const { player } = this.player.getState();
         this.player.seek(player.currentTime + seconds);
      };
   }
   alphabetically_comment() {
      $('.pupup3post22').removeClass('closeBd');
      var e = this.state.eventid;
      this.setState({ mutuality_comment: "", perpage: 1, itemsComents: Array.from({ length: 10 }), hasMore: true });
      this.setState({ alphabetically_comment: "alphabetically" });
      if (this.state.foleOne == "") {
         this.componentDidMount();
      }
      else {
         this.refresh();
      }
   }
   relevancy_comment() {
      $('.pupup3post22').removeClass('closeBd');
      var e = this.state.eventid;
      this.setState({ mutuality_comment: "", perpage: 1, itemsComents: Array.from({ length: 10 }), hasMore: true });
      this.setState({ alphabetically_comment: "" });
      if (this.state.foleOne == "") {
         this.componentDidMount();
      }
      else {
         this.refresh();
      }
   }
   mutuality_comment() {
      $('.pupup3post22').removeClass('closeBd');
      var e = this.state.eventid;
      this.setState({ alphabetically_comment: "", perpage: 1, itemsComents: Array.from({ length: 10 }), hasMore: true });
      this.setState({ mutuality_comment: "mutuality" });
      if (this.state.foleOne == "") {
         this.componentDidMount();
      }
      else {
         this.refresh();
      }
   }
   cancelpop() {
      $(".errorpop").toggle();
   }
   submitdeletecomment() {
      $(".errorpop").toggle();
      const body = {
         eventID: this.state.eventid,
         commentID: this.state.dcomment,
         parentCommentBy: this.state.duserid,
         commentBy: this.state.duserid,
         isParent: 1
      }
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/comment/deleteComment.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            console.log('res');
            var e = this.state.eventid;
            if (this.state.foleOne == "") {
               this.componentDidMount();
            }
            else {
               this.refresh();
            }
         }).catch((error) => {
            console.log('e', error);
         })
   }
   refresh() {
      if (this.state.childcommentid != "") {
         const token = localStorage.getItem('token');
         fetch(this.state.baseUrl + '/api/comment/getChildComments.php?commentID=' + this.state.childcommentid + '&eventID=' + this.state.eventid, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (this.state.mutuality_comment != "") {
                  this.setState({ childcomment: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() });
               }
               if (this.state.alphabetically_comment != "") {
                  this.setState({
                     childcomment: responseJson.response.sort((a, b) =>
                        a.screenName.localeCompare(b.screenName))
                  });
               }
               else {
                  this.setState({ childcomment: responseJson.response, loading: "false" });
               }
            })
      }
   }
   findHashtags(searchText) {
      var regexp = /\B\#\w+\b/g;
      var result = searchText.match(regexp);
      if (result) {
         const filterTags = (result) => result.filter((v, i) => result.indexOf(v) === i);
         const hashTags = filterTags(result);
         if (hashTags) {
            const tags = hashTags.join().replace(/#/gi, "");
            this.setState({ hashTagstext: tags });
            return tags;
         }
      }
      return null;
   }
   sendcomment() {
      this.setState({ sendLoding: true });
      if (this.state.childcommentid == "") {
         var parent = 0;
      }
      else {
         var parent = this.state.childcommentid;
      }
      if (this.state.posttext != "") {
         var post = this.state.posttext.replace('<br>', '');
         var searchText = post.replace(/(<([^>]+)>)/ig, '');
         var body = {
            eventID: this.state.eventid,
            comment: searchText,
            parentID: parent,
            commentAt: Math.floor(new Date() / 1000),
            user_id: localStorage.getItem('userid'),
            hashtags: this.findHashtags(searchText)
         };
         const token = localStorage.getItem('token');
         axios.post(this.state.baseUrl + '/api/comment/addComment.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         }).then((res) => {
            if (this.state.foleOne == "") {
               this.componentDidMount();
            }
            else {
               this.refresh();
            }
            $('#labelholderto').removeClass('newlabel');

            setTimeout(function () {
               document.getElementById("type").focus();
            }, 1000);
            this.setState({ posttext2: "", posttext: "", colorIcon: "blur", sendLoding: false });
         });
      }
   }
   fetchMoreComment() {
      setTimeout(() => {
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         const totaluser = this.state.editcount;
         const recordp = totaluser / 10;
         if (this.state.hasMore == false) {
            $('#march').addClass('closeCd');
            this.setState({ hasMore: false });
            return;
         }
         this.setState({ perpage: this.state.perpage + 1 });
         fetch(this.state.baseUrl + '/api/comment/getChildComments.php?commentID=' + this.state.childcommentid + '&eventID=' + this.state.eventid + '&page=' + this.state.perpage + '&limit=10', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response.length == 0) {
                  $('#march').addClass('closeCd');
                  $('.commestsn').addClass('lastcomment');
                  this.setState({ hasMore: false });
               }
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.childcomment.push(responseJson.response[i]);
               }
               if (this.state.mutuality_comment != "") {
                  this.setState({ childcomment: this.state.childcomment.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() });
               }
               if (this.state.alphabetically_comment != "") {
                  this.setState({
                     childcomment: this.state.childcomment.sort((a, b) =>
                        a.screenName.localeCompare(b.screenName))
                  });
               }
               else {
                  this.setState({ childcomment: this.state.childcomment });
               }
            });
         this.setState({
            itemsComents: this.state.itemsComents.concat(Array.from({ length: 10 }))
            , loading: "false"
         });
      }, 1000);

   }
   posttext = evt => {
      var con1 = evt.target.value.replace(/<\/?span[^>]*>/g, "");
      var content = con1.replace(/<([^.]+)>.*?<\/\1>/ig, '');
      var content2 = content.replace('<br>', "");
      if (content2 == "" | content2 == "<br>") {

         $('#labelplaceholder').removeClass('newlabel');
         $('#labelholder').removeClass('newlabel');
         $('#labelholderto').removeClass('newlabel');
         this.setState({ colorIcon: "blur" });
      }
      else {
         $('#labelplaceholder').addClass('newlabel');
         $('#labelholder').addClass('newlabel');
         $('#labelholderto').addClass('newlabel');
         this.setState({ colorIcon: "" });
      }
      this.setState({ posttext: evt.target.value });
      var str = evt.target.value; // your string here
      str = str.replace(/<\/?span[^>]*>/g, "");
      str = str.replace(';', "");
      const regex = /(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/g;
      const replaced2 = str.replace(regex, value => `<span>${value}</span>`);

      this.setState({ posttext2: replaced2 });
      if (evt.target.value && evt.target.value.length > 0) {
         var n = evt.target.value.split(" ");
         var lastWord = n[n.length - 1];
         if (lastWord.includes("#")) {
            if (lastWord && !lastWord.includes("'")) {
               this.getHashTags(lastWord);
            }
         }
         else if (lastWord.includes("@")) {
            if (lastWord && !lastWord.includes("'")) {
               this.getMentions(lastWord);
            }
         }
         else {
            this.resetSuggestions();
         }
      }
      else {
         this.resetSuggestions();
      }
   }
   resetSuggestions() {
      var suggestionText = "";
      $('.sugetionList').addClass('listb');
      this.setState({ suggestionsList: [], tagtype: "" });
   }
   getMentions(lastWord) {
      const input = $('<span>').html(lastWord).text();
      var arr = input.split("@");
      if (arr.length > 1 && arr[arr.length - 1] != "") {
         var suggestionText = arr[arr.length - 1];
         const token = localStorage.getItem('token');
         fetch(this.state.baseUrl + '/api/event/getMention.php?search=' + suggestionText, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response) {

                  if (this.state.posttext && this.state.posttext.length > 0) {
                     this.setState({ suggestionsList: responseJson.response, tagtype: "mention" });
                     $('.sugetionList').removeClass('listb');
                  }
                  else {
                     this.resetSuggestions();
                  }
               } else {
                  this.resetSuggestions();
               }

            }).catch((error) => {
            })
      }
      else {
         this.setState({ suggestionsList: [] });
      }
   }
   gettag(e) {
      var value = e.target.id;
      if (this.state.tagtype == "hash") {
         var my = this.state.posttext.split(" ").slice(0, -1).join(" ") + " " + "#" + value;
         const regex = /#(.+?)(?=[\s.,:,]|$)|@(.+?)(?=[\s.,:,]|$)/ig;
         const replaced2 = my.replace(regex, value => `<span>${value}</span>`);
         this.setState({ posttext2: replaced2 });
         this.setState({ posttext: my });
         var editable = $('#type');
         setTimeout(function () {
            document.getElementById("type").focus();
         }, 1000);
         $('.sugetionList').addClass('listb');
      }
      else {
         var my = this.state.posttext.split(" ").slice(0, -1).join(" ") + " " + "@" + value;
         const regex = /#(.+?)(?=[\s.,:,]|$)|@(.+?)(?=[\s.,:,]|$)/ig;
         const replaced2 = my.replace(regex, value => `<span>${value}</span>`);
         this.setState({ posttext2: replaced2 });
         this.setState({ posttext: my });
         var editable = $('#type');
         setTimeout(function () {
            document.getElementById("type").focus();
         }, 1000);
         $('.sugetionList').addClass('listb');
      }
   }
   getHashTags(lastWord) {
      const input = $('<span>').html(lastWord).text();
      var arr = input.split("#");
      if (arr.length > 1 && arr[arr.length - 1] != "") {
         var suggestionText = arr[arr.length - 1];
         const token = localStorage.getItem('token');
         fetch(this.state.baseUrl + '/api/event/getHashtag.php?hashtag=' + suggestionText, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response) {
                  if (this.state.posttext && this.state.posttext.length > 0) {
                     this.setState({ suggestionsList: responseJson.response, tagtype: "hash" });
                     $('.sugetionList').removeClass('listb');
                  }
                  else {
                     this.resetSuggestions();
                  }
               } else {
                  this.resetSuggestions();
               }
            }).catch((error) => {
            })
      }
      else {
         this.setState({ suggestionsList: [] });
      }
   }
   getdateformat(datedata) {
      if (datedata) {
         var postedDate = moment.unix(datedata).format("YYYY-MM-DD");
         var currentDate = moment(new Date()).format("YYYY-MM-DD");
         var eventDate = datedata;
         var date = "";
         var duration = moment.duration(moment(postedDate).diff(moment(currentDate)));
         var daysDifference = duration.asDays();
         if (daysDifference <= -8 || daysDifference > 7) {
            const day = moment.unix(eventDate).format("D");
            const month = moment.unix(eventDate).format("MMM");
            const time = moment.unix(eventDate).format("h:mma");
            var lasttag = "";
            switch (day % 10) {
               case 1:
                  if (day != 11) {
                     lasttag = "st";
                  } else {
                     lasttag = "th";
                  }
                  break;
               case 2:
                  if (day != 12) {
                     lasttag = "nd";
                  } else {
                     lasttag = "th";
                  }
                  break;
               case 3:
                  if (day != 13) {
                     lasttag = "rd";
                  } else {
                     lasttag = "th";
                  }
                  break;
               default: lasttag = "th";
                  break;
            }
            date = `${month} ${day}${lasttag}, ${time}`;
            return date;
         }
         if (daysDifference < -1 && daysDifference > -8) {
            date = "last" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference < 0 && daysDifference >= -1) {
            date = "yesterday at " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 0 && daysDifference < 1) {
            date = moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 1 && daysDifference < 2) {
            date = "tomorrow at " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 2 && daysDifference < 7) {
            date = "this" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference == 7) {
            date = "next" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
      }
   }
   checkComment() {
      if (this.state.childcomment.length > 8) {
         return "loaderclass load";
      }
      else {
         return "loaderclass comentoncomment_loder";
      }
   }
   popuplist(e) {
      $('.pupup1B').addClass('closeBd');
      $('.pupup1B').removeClass('closeCd');
      this.setState({ popName: e.currentTarget.dataset.div_name, popId: e.currentTarget.id });
      ;
   }
   closepop() {
      $('.pupup1B').addClass('closeCd');
      $('.pupup1B').removeClass('closeBd');
   }
   morethis() {
      $('.pupup3post22').addClass('closeBd');
   }
   morecancel() {
      $('.pupup3post22').removeClass('closeBd');
   }
   seeMore() {
      var id = this.state.popId;
      $('.pupup1B').removeClass('closeBd');
      $('#Top' + id).addClass('closeBd');
      setTimeout(function () {
         $('#Top' + id).removeClass('closeBd');
      }, 3000);
   }
   seeLess() {
      var id = this.state.popId;
      $('.pupup1B').removeClass('closeBd');
      $('#Less' + id).addClass('closeBd');
      setTimeout(function () {
         $('#Less' + id).removeClass('closeBd');
      }, 1000);
   }

   goChat() {

      $('.pupup1B').addClass('closeCd');
      $('.pupup1B').removeClass('closeBd');
      $('#one_90').addClass('active fades');
      $('#one_90').removeClass('active fades');
   }
   commentLike(e) {
      var mainid = e.currentTarget.id;
      if (this.state.childcommentid == mainid) {
         if (this.state.childliked == 1) {
            if (this.state.childlike > 0) {
               var count = this.state.childlike - 1;
            }
            else {
               var count = 0;
            }
            this.setState({ childlike: count, childliked: 0 })
         }
         if (this.state.childliked == 0) {
            if (this.state.childlike > 0) {
               var count = this.state.childlike + 1;
            }
            else {
               var count = 1;
            }
            this.setState({ childlike: count, childliked: 1 })
         }

      }
      else {
         var like = e.currentTarget.dataset.div_like;
         var objIndex = this.state.childcomment.findIndex((obj => obj.commentID == mainid));
         if (like == 1) {
            if (e.currentTarget.div_id > 0) {
               var count = e.currentTarget.div_id - 1;
            }
            else {
               var count = 0;
            }
            if (objIndex !== -1) {
               this.state.childcomment[objIndex].isLiked = 0;
               this.state.childcomment[objIndex].likesCount = count;
               this.setState({ childcomment: this.state.childcomment });
            }
         }
         if (like == 0) {
            if (e.currentTarget.div_id > 0) {
               var count = e.currentTarget.div_id + 1;
            }
            else {
               var count = 1;
            }
            if (objIndex !== -1) {
               this.state.childcomment[objIndex].isLiked = 1;
               this.state.childcomment[objIndex].likesCount = count;
               this.setState({ childcomment: this.state.childcomment });
            }
         }
      }

      const body = {
         user_id: e.currentTarget.dataset.div_name,
         commentID: e.currentTarget.id,
         eventID: this.state.eventid
      }
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/comment/likeComment.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            console.log('res');
         })
         .catch((error) => {
            console.log('Invalid', error);
         })
   }




   // collectParent(value)
   // {
   //   var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
   //   if(more)
   //   {
   //   if(more.length>0)
   //   {
   //     $(document).ready(function() {
   //      for (let index = 0; index < more.length; index++) {
   //         $("."+more[index].substring(1)).unbind().click(function() {
   //           this.props.history.push({
   //              pathname: '/'+more[index].substring(1),
   //            })
   //            localStorage.setItem("backid","front");
   //         }.bind(this));
   //      }
   //     }.bind(this));
   //     }
   //   }
   // }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   collectMentions(value) {
      var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      var hash = value.match(/(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      if (more || hash) {
         if (more !== null && more.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < more.length; index++) {
                  $("." + more[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/' + more[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
         if (hash !== null && hash.length > 0) {
            console.log()
            $(document).ready(function () {
               for (let index = 0; index < hash.length; index++) {
                  $("." + hash[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/tag/' + hash[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
      }
   }
   fetchafterfilter(jstatus) {
      this.collectMentions(jstatus);
      var regex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/ig;
      var replaced2 = jstatus.replace(regex, value => `<a href=${"https://" + value} class='hashtag_link' target='_blank'>${value}</a>`);
      var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig;
      var replaced7 = replaced2.replace(mention_preg, value => `<a class=${value.substring(1)}>${value}</a>`);
      var regex2 = /!+([a-zA-Z0-9_]+)/ig;
      var replaced3 = replaced7.replace(regex2, value => `<span>${value}</span>`);
      var replaced = replaced3.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return replaced;
   }
   gobackbutton() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   render() {
      return (
         <div>
            {/* <div className="newscreen1 newscreen01">
   <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}  id="one_22"> */}
            <div className={`${this.props.pos} screen1`} id="one_22">
               <div className="top newbg" id="myHeader">
                  {/* <!-- --------- header ---------------> */}
                  <section className="header">
                     <div className="row">
                        <div className="col-4 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li>
                                 <a className="linkback">

                                    <span onClick={this.gobackbutton.bind(this)}><img src="../../images/back.png" align="img" /></span>

                                 </a>
                              </li>
                           </ul>
                        </div>
                        <div className="col-4 pl-0 pr-0 header_center text-center">
                           <h6>Timesort</h6>
                        </div>
                        <div className="col-4 header_right pl-0">
                           <ul className="uld">
                              <li className=""><a onClick={this.morethis.bind(this)}><img src="../../images/more2.png" align="img" /></a></li>
                           </ul>
                        </div>
                     </div>
                  </section>
               </div>
               <div className="tab-content kkk">
                  <div
                     id="scrollableDivComment"
                     className="scrollClass pt-0 newscreen1"
                     style={{
                        overflow: 'auto',
                        display: 'row',
                        flexDirection: 'row',
                        margintop: 47,
                     }}
                  >
                     <InfiniteScroll
                        dataLength={this.state.itemsComents.length}
                        next={this.fetchMoreComment.bind(this)}
                        style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                        inverse={false} //

                        hasMore={true}
                        loader={
                           <div id="march" className={this.checkComment()}>
                              <img
                                 src="../../images/newgif.gif"
                                 alt="loading"
                              />
                           </div>}
                        endMessage={
                           <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                           </p>}
                        useWindow={false}
                        scrollableTarget="scrollableDivComment"
                     >
                        <div className="sectionone">
                           <div className="mb-2">
                              <div className="postlikeB">
                                 <div className="userin userinpost">
                                    <a><Link to={{
                                       pathname: `/${this.state.childuser}`,
                                       state: { id: this.state.childuserid }
                                    }} onClick={this.setStorage.bind(this)}
                                    ><img src={this.state.baseUrl + this.state.childimage} align="images" /></Link></a>
                                    <h4>
                                       <Link to={{
                                          pathname: `/${this.state.childuser}`,
                                          state: { id: this.state.childuserid }
                                       }} onClick={this.setStorage.bind(this)}
                                       ><j>{this.state.childname}</j></Link>
                                    </h4>

                                    <h5 dangerouslySetInnerHTML={{ __html: this.fetchafterfilter(this.state.childcomment2) }} />
                                    <div id={'Top' + this.state.childuserid} class="likeadd"><img class="w-100" src="../../images/More.png" alt="image" /></div>
                                    <div id={'Less' + this.state.childuserid} class="likeadd"><img class="w-100" src="../../images/setting_dont_follow_back.png" alt="image" /></div>
                                    <ul className="videoul mt3">
                                       <li className="mmnt link3k">
                                          <a>
                                             <span id={this.state.childcommentid} className={"heart" + this.state.childcommentid} onClick={this.commentLike.bind(this)} data-div_un={this.state.childuser} data-div_name={this.state.childuserid}
                                                data-div_id={this.state.childlike} data-div_un={this.state.childuser}>
                                                <span>{this.state.childliked == 1 ? <img src="../../images/cb.png" alt="icon" defaultStyle="height:20px;" /> : <img src="../../images/c.png" alt="icon" defaultStyle="height:20px;" />}</span>
                                             </span>
                                             <span
                                                className={" moveleft count" + this.state.childcommentid}>
                                                <Link to={{
                                                   pathname: "/Likes",
                                                   state: { id: this.state.childcommentid, name: this.state.childlike, class: this.state.childname, div_id: this.state.childuserid, eventid: this.state.eventid }
                                                }} onClick={this.setStorage.bind(this)}
                                                   className={" moveleft count" + this.state.childcommentid}><a className="link">{this.state.childlike != 0 ? this.state.childlike : ""}</a></Link>

                                             </span>
                                          </a>
                                          <a className="link" href="#one_15" className="ml-3 comentbt" href="#">
                                             <img src="../../images/comment.png" defaultStyle="height:20px;" alt="icon" /> {this.state.childcount != 0 ? <span>{this.state.childcount}</span> : ""}</a>
                                          <a data-div_name={this.state.childname.split(" ")[0]} onClick={this.popuplist.bind(this)} id={this.state.childuserid} ><img className="option ml-3" src="../../images/option.png"
                                             align="icon" /></a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           <div className={this.state.hasMore == false ? "commestsn democlass1 commestsn_endbutton" : "commestsn democlass1"}>
                              {this.state.childcomment ? this.state.childcomment.map((j, x) => {
                                 return (<div className="mb-2">
                                    <div className="userin" id={j.commentID} data-div_user={j.user_id} onDoubleClick={this.deleteComment.bind(this)}>
                                       <a ><Link to={{
                                          pathname: `/${j.username}`,
                                          state: { id: j.user_id }
                                       }} onClick={this.setStorage.bind(this)}
                                       ><img src={this.state.baseUrl + j.profileImage} align="images" /></Link></a>
                                       <h4>
                                          <j><Link to={{
                                             pathname: `/${j.username}`,
                                             state: { id: j.user_id }
                                          }} onClick={this.setStorage.bind(this)}>
                                             {j.screenName}</Link></j>
                                       </h4>
                                       <h5 dangerouslySetInnerHTML={{ __html: this.fetchafterfilter(j.comment) }} />
                                    </div>
                                    <div id={'Top' + j.user_id} class="likeadd"><img class="w-100" src="../../images/More.png" alt="image" /></div>
                                    <div id={'Less' + j.user_id} class="likeadd"><img class="w-100" src="../../images/setting_dont_follow_back.png" alt="image" /></div>
                                    <ul className="videoul mt3">
                                       <li className="mmnt">
                                          <a>
                                             <span id={j.commentID} className={"heart" + j.commentID} data-div_like={j.isLiked} data-div_un={j.username} data-div_name={j.user_id} onClick={this.commentLike.bind(this)} data-div_id={j.likesCount}>
                                                {j.isLiked == 1 ? <img src="../../images/cb.png" alt="icon" defaultStyle="height:20px;" /> : <img src="../../images/c.png" alt="icon" defaultStyle="height:20px;" />}</span>
                                             <span id={j.commentID}
                                                className={" moveleft count" + j.commentID}>
                                                <Link to={{
                                                   pathname: `/Likes/${j.screenName.split(' ')[0]}/${this.state.eventid}/${j.user_id}/${j.commentID}/${j.likesCount}`
                                                }}
                                                   onClick={this.setStorage.bind(this)}>
                                                   <a className="link">{j.likesCount != 0 ? j.likesCount : ""}</a></Link>
                                             </span> </a>

                                          <Link to={{
                                             pathname: `/CommentOn/${j.commentID}`,
                                             state: { m_route: this.state.m_route, eventid: this.state.eventid, username: j.username, commentid: j.commentID, userid: j.user_id, comment: j.comment, islike: j.isLiked, name: j.screenName, image: j.profileImage, likes: j.likesCount, commentCount: j.chieldCount }
                                          }} onClick={this.setStorage.bind(this)} className={(j.commentByUsers.length > 0 && j.likesCount != 0) ? "ml-3 comentbt " : "ml-3 comentbt fffff"} >
                                             <img src="../../images/comment.png" defaultStyle="height:20px;" alt="icon" /> <span className="moveleft">{j.chieldCount > 0 ? j.chieldCount : ""}</span></Link>
                                          {localStorage.getItem('userid') ? j.user_id != localStorage.getItem('userid') ? <a className="upbuttonB" data-div_name={j.screenName.split(" ")[0]} onClick={this.popuplist.bind(this)} id={j.user_id}><img className="option ml-3" src="../../images/option.png" align="icon" /></a> : "" : ""}
                                       </li>
                                       {j.likedByUsers.length > 0 ? <li className="hrcmi"><img src="../../images/c.png" alt="icon" />{j.likedByUsers.length > 0 ? j.likedByUsers.map((m, t) => {
                                          return (<span><Link to={{
                                             pathname: `/${m.username}`,
                                             state: { id: m.user_id }
                                          }} onClick={this.setStorage.bind(this)} >
                                             {m.screenName}</Link><span>,</span> </span>)
                                       }) : ""}</li> : ""}
                                       {j.commentByUsers.length > 0 ? <li className="hrcmi"><img src="../../images/d.png" alt="icon" />{j.commentByUsers.length > 0 ? j.commentByUsers.map((m, t) => {
                                          return (<span><Link to={{
                                             pathname: `/${m.username}`,
                                             state: { id: m.user_id }
                                          }} onClick={this.setStorage.bind(this)} >
                                             {m.screenName}</Link><span>,</span> </span>)
                                       }) : ""}</li> : ""}
                                    </ul>
                                 </div>)
                              })

                                 : <div id="march" className="loaderclass">
                                    <img
                                       src="../../images/newgif.gif"
                                       alt="loading"
                                    />
                                 </div>}
                           </div>
                        </div>
                     </InfiniteScroll>
                  </div>
               </div>
               <div className="sugetionList sugetionListcomment listb">
                  <ul className="sugetionul">
                     {(() => {
                        if (this.state.suggestionsList.length > 0) {
                           return (
                              this.state.suggestionsList.map((c, i) => {
                                 if (this.state.tagtype == "hash") {
                                    return (<li className="sugetionli" id={c.hashtag} onClick={this.gettag.bind(this)}>{'#' + c.hashtag}</li>)
                                 }
                                 else {
                                    return (<li className="sugetionli" id={c.username} onClick={this.gettag.bind(this)}>{"@" + c.username}</li>)
                                 }
                              }
                              ))
                        }
                     })()}
                  </ul>
               </div>
               {this.state.childcommentid != "" ?
                  <div className="intype commentdiv">
                     <div defaultStyle="position:relative;">
                        <div className={this.state.sendLoding == true ? "loaderclass load" : "loaderclass closeCd"}>
                           <img
                              src="../../images/newgif.gif"
                              alt="loading"
                           />
                        </div>
                        <ContentEditable
                           id="typeOne"
                           className="form-control1"
                           innerRef={this.contentEditable}
                           html={this.state.posttext2} // innerHTML of the editable div
                           disabled={false}       // use true to disable editing
                           onChange={this.posttext.bind(this)} // handle innerHTML change
                           tagName='article' // Use a custom HTML tag (uses a div by default)
                        />
                        <label id="labelholderto" >{localStorage.getItem('screenname') ? "Comment on " + this.state.childname.split(" ")[0] + "'s comment, " + localStorage.getItem('screenname').split(" ")[0] + "!" : ""}</label>
                        <button onClick={this.sendcomment.bind(this)} type="btn">{this.state.colorIcon == "blur" ? <img src="../../images/sendicon.png" /> : <img src="../../images/ip.png" />}</button>
                     </div>
                  </div>
                  : ""}
               <Footer {...this.props} />
               <div className="op pupup3post22">
                  <div className="h-100" onClick={this.morecancel.bind(this)}></div>
                  <ul>
                     <h5 className="text-center">sort</h5>
                     <li><a href="#" className="" id="secondRelevancy" onClick={this.relevancy_comment.bind(this)} ><img src="../../images/More.png" /> relevancy</a></li>
                     <li><a href="#" className="" id="secondMutuality" onClick={this.mutuality_comment.bind(this)}><img src="../../images/mut.png" /> mutuality</a></li>
                     <li><a href="#" className="" id="secondAlphabetically" onClick={this.alphabetically_comment.bind(this)}><img src="../../images/az.png" /> alphabetically</a></li>
                     <button className="upbtn" onClick={this.morecancel.bind(this)} >Cancel</button>
                  </ul>
               </div>

               <div className="op pupup1B">
                  <div className="upbuttonB h-100" onClick={this.closepop.bind(this)}></div>
                  <ul>
                     <li><a data-div_id={this.state.popId} onClick={this.seeMore.bind(this)}><img src="../../images/More.png" /> see more like this</a></li>
                     <li><a data-div_id={this.state.popId} onClick={this.seeLess.bind(this)} href="#"><img src="../../images/setting_dont_follow_back.png" /> see less like this</a></li>
                     <li><a data-div_id={this.state.popId} onClick={this.goChat.bind(this)} ><img src="../../images/setting_request.png" />{'message ' + this.state.popName} </a></li>
                     <li><a data-div_id={this.state.popId} href="#"><img src="../../images/remove.png" /> remove follower</a></li>
                     <li><a data-div_id={this.state.popId} href="#"><img src="../../images/block.png" />{'block ' + this.state.popName}</a></li>
                     <li><a data-div_id={this.state.popId} className="link" href="#one_18"><img src="../../images/report.png" /> {'report ' + this.state.popName} </a></li>
                     <button className="upbtn upbuttonB" onClick={this.closepop.bind(this)}>Cancel</button>
                  </ul>
               </div>
               <div className="op errorpop mainpop">
                  <div className="errorbtn error h-100"></div>
                  <ul>
                     <li className="lipost">{this.state.errortitle}</li>
                     <li>{this.state.editerror}</li>
                     <div className="newpop">
                        <button className="upbtn errorbtn" onClick={this.cancelpop.bind(this)}>CANCEL</button>
                        <button className="upbtn errorbtn" onClick={this.submitdeletecomment.bind(this)}>YES</button>
                     </div>
                  </ul>
               </div>
            </div>

         </div>





         // </div>

      );
   };
};



export default CommentOn;