import axios from 'axios';
import React, { useState } from 'react';
import CustomPopUp from "../../CustomPopup";
import AddCommands from './addCommands';

const DisplayCommandTable = ({ commandData, setDisplayCommandTable, handleRefetchCommands }) => {
    const [editCommand, setEditCommand] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [isEditCommand, setIsEditCommand] = useState(false);
    const [openDeleteCommandPopup, setOpenDeleteCommandPopup] = useState(false);
    const [deleteCommand, setDeleteCommand] = useState('');
    const headers = {
        Accept: "application/json",
        'Content-Type': 'application/json',
    }

    const handleAddEditCommand = (id, isEdit) => {
        setEditCommand(commandData.find((data) => data._id === id));
        setIsEditCommand(isEdit);
        setShowForm(true);
    }

    const handleDeleteCommand = async (id) => {
        const url = `${process.env.REACT_APP_BASE_URL_NODE}/api/commands/${id}/`;
        await axios.delete(url, { headers }).then((response) => {
            if (response?.data?.status) {
                handleRefetchCommands(true);
            }
        })
    }

    return (
        <>
            {showForm && (<AddCommands editCommand={editCommand} setShowForm={setShowForm} isEditCommand={isEditCommand} handleRefetchCommands={handleRefetchCommands} />)}
            <div className='batchFilesWrapper'>
                <div className="uploadCommadsBlock">
                    <h2>Image Upload Commands</h2>
                    <div className='flex-wrapper'>
                        <button className="backButton" onClick={() => setDisplayCommandTable(false)}>Back</button>
                        <button className="addButton" onClick={() => handleAddEditCommand(null, false)}>Add</button>
                    </div>
                    <div className='tableWrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Command</th>
                                    <th>Updated At</th>
                                    <th>View/Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {commandData?.length ? commandData.map((data) => {
                                    return (
                                        <tr>
                                            <td>{data?.name}</td>
                                            <td>{(data?.command.substring(0, 60) + (data?.command?.length > 60 ? '...' : ''))}</td>
                                            <td>{data?.updatedAt}</td>
                                            <td><button onClick={(e) => handleAddEditCommand(data?._id, true)}>View/Edit</button></td>
                                            <td><button onClick={() => {
                                                setOpenDeleteCommandPopup(true);
                                                setDeleteCommand(data?._id);
                                            }} className='deleteButton'>Delete</button></td>
                                        </tr>
                                    )
                                }) : (
                                    <tr>
                                        <td colSpan="5">No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {openDeleteCommandPopup && (
                        <CustomPopUp
                            isOpen={openDeleteCommandPopup}
                            onClose={() => setOpenDeleteCommandPopup(false)}
                            popup={{
                                heading: "Command deletion",
                                message: `Are you sure you would like to delete this command?`,
                                onClick: () => {
                                    handleDeleteCommand(deleteCommand);
                                },
                            }}
                            multipleButton={true}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default DisplayCommandTable;
