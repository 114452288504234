import React, { Component } from 'react';
import axios from "axios";
import { Link  } from "react-router-dom";
import { removeProfileDataFromStorage } from '../../utils/profiles/manageProfilesList';
import { MyContext } from '../../context';
import CustomPopUp from '../../CustomPopup';
import FormSubmitLoader from '../FormSubmitLoader';
class SignUp extends  React.Component {
   static contextType = MyContext
   constructor(props) {
      super(props);
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL,
         fullName: "", identifier: "", email: "", password: "", static: "",
         baseUrlNode: process.env.REACT_APP_BASE_URL_NODE,
         pathname: this.props.location.pathname,
         openPopup: false,
         signupFailedErrorMsg: "",
         showLoader: false,
      }
   }

   componentDidMount() {
      const { sharedData } = this.context

      if (sharedData) {
         if (sharedData.pageWiseData[this.props.parentPathName] && sharedData.pageWiseData[this.props.parentPathName][this.state.pathname])
            this.setState({ ...sharedData.pageWiseData[this.props.parentPathName][this.state.pathname] })
         
         this.setState({ pathname: this.props.location.pathname })
      }
   }

   componentWillUnmount() {
      console.log("CHild SignUp Exiting");
      this.updateSharedDataInContext()
   }

   updateSharedDataInContext() {
      const { sharedData, setSharedData } = this.context
      sharedData.pageWiseData[this.props.parentPathName] = {
         ...sharedData.pageWiseData[this.props.parentPathName],
         [this.state.pathname]:this.state
      }
      
      setSharedData({
         ...sharedData,
      });
   }

   handleChangeFull(e)
   {
      this.setState({ fullName:e.target.value});
   }
   handleChangeUserName(e)
   {
      this.setState({ identifier:e.target.value});
   }
   handleChangeSignEmail(e)
   {
      this.setState({ email:e.target.value});
   }
   handleChangeSignPassword(e)
   {
      this.setState({ password:e.target.value});
   }
   onSubmit()
   {
      if (this.state.fullName == "" || this.state.identifier == "" || this.state.email == "" || this.state.password == "")
      {
         this.setState({ openPopup: true });
         return;
      }
      this.setState({ showLoader: true })
      const body={
         email: this.state.email,
         password: this.state.password,
         screenName: this.state.fullName,
         username: this.state.identifier,
         profileType: "consumer"
      };
      axios.post(this.state.baseUrl+"/api/user/add.php", body)
      .then((res) => {
      if(res.data.code==200){
         this.saveDb(res.data.response);
      }
      })
      .catch((error) => {
         this.setState({ showLoader:false, signupFailedPopup: true, signupFailedErrorMsg: error?.response?.data?.message });
         console.log("SignUp failed. Error: ",error?.response?.data || error.message);
      })
   }

   saveDb(db){
      db.password = this.state.password;
      db.profileType="consumer";
      axios.post(this.state.baseUrlNode+'/api/add',db)
      .then((res) => {
      if(res.data.status=='success')
      {
         localStorage.setItem("timeToken", res.data.token);
         localStorage.setItem("email", db.email);
         localStorage.setItem("screenname", db.screenName);
         localStorage.setItem("totalpost", db.totalPost);
         localStorage.setItem("username", db.username);
         localStorage.setItem("followers", db.followers);
         localStorage.setItem("request", db.request);
         localStorage.setItem("bio", db.bio);
         localStorage.setItem("following", db.following);
         localStorage.setItem("profileCover", db.profileCover);
         localStorage.setItem("profileImage", db.profileImage);
         localStorage.setItem("userid", db.user_id);
         localStorage.setItem("token", db.token);
         localStorage.setItem('make_public',db.make_public);
         localStorage.setItem("website", db.website);
         localStorage.setItem("profileType","consumer")
         
         var first =localStorage.getItem('screenname')?.split(" ")[0]
         var firstn=first.charAt(0);
         if(localStorage.getItem('screenname')?.split(" ")[1])
         {
            var second =localStorage.getItem('screenname').split(" ")[1]
            var secondn = second.charAt(0); 
            localStorage.setItem("staticname", firstn+secondn);
         }
         else
         {
            localStorage.setItem("staticname", firstn);
         }
         removeProfileDataFromStorage()
         this.props.history.push('/');
         window.location.reload();
      }
      }).catch((error) => {
         this.setState({ signupFailedPopup: true, signupFailedErrorMsg: error?.response?.data?.message });
         console.log("SignUp failed. Error: ",error?.response?.data || error.message);
      })
         .finally(() => this.setState({ showLoader: false }))
   
   }
   

render()
{
return(
<>
   <form  className="form-signin login signup">
      <h3>Sign up</h3>
      <div className="form-group" >
         <label>full name</label>
         <input type="text" name="fullname" className="form-control" onChange={this.handleChangeFull.bind(this)} value={this.state.fullName} placeholder="full name"/>
      </div>
      <div className="form-group">
         <label>identifier</label>
            <input type="text" name="username" id="username" className="form-control" onChange={this.handleChangeUserName.bind(this)} value={this.state.identifier} placeholder="identifier"/>
      </div>
      <div className="form-group">
         <label>email</label>
            <input type="email" name="email" className="form-control" onChange={this.handleChangeSignEmail.bind(this)} value={this.state.email} placeholder="email"/>
      </div>
      <div className="form-group mb-0">
         <label>password</label>
            <input type="password" name="password" id="inputPassword" onChange={this.handleChangeSignPassword.bind(this)} value={this.state.password} className="form-control" placeholder="password" required=""/>
      </div>
      <button className="btn btn-lg btn-block"  onClick={this.onSubmit.bind(this)} type="button">Sign up</button>
      <p>By signing up, you agree to Timesort's <Link to={'/tos'} target='_blank' rel="noopener noreferrer">Terms of Service</Link> & <Link to={'/privacy-policy'} target='_blank' rel="noopener noreferrer">Privacy Policy</Link>.</p>
      <h6 className="text-center">
         { this.props.showPath ?
            <Link to={"/login"} onClick={this.props.updateView} className="link" data-toggle="tab" >Login</Link>
         :  <Link to={"/login"} className="link" data-toggle="tab">
               Login
            </Link>}
      </h6>
   </form>
      {
         this.state.showLoader && <FormSubmitLoader />
      }
   {this.state.openPopup && (
      <CustomPopUp
         isOpen={this.state.openPopup}
         onClose={() => this.setState({ openPopup: false })}
         popup={{
            heading: "Unfulfilled fields",
            message: 'Fill in all fields.',
            onClick: () => {
               this.setState({ openPopup: false });
            },
         }}
         multipleButton={false}
      />
   )} 
   {this.state.signupFailedPopup && (
      <CustomPopUp
         isOpen={this.state.signupFailedPopup}
         onClose={() => this.setState({ signupFailedPopup: false })}
         popup={{
            heading: "Signup failed",
            message: this.state.signupFailedErrorMsg || 'Fill all the fields correctly.',
            onClick: () => {
               this.setState({ signupFailedPopup: false, signupFailedErrorMsg:'' });
            },
         }}
         multipleButton={false}
      />
   )}
</>
)}
};
export default SignUp