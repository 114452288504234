const wordReg = /[A-Za-z]/

export function showInitials(name) {
    if (!name)
        return ''

    const words = name.split(' ');

    let initials = words[0][0].toUpperCase();
    initials += getSecondLetter(words);

    return initials
}

export function showInitialOfFullName(name) {
    if (!name) {
        return '';
    }

    let initials = '';
    const words = name.split(' ');
    const wordsToBeRemoved = ['at', 'of', 'and']; 

    words.forEach(word => {
        if (!wordsToBeRemoved.includes(word.toLowerCase())) {
            initials += word[0].toUpperCase();
        }
    });
    
    return initials;
}

/**
 *  
 * @param {[string]} words 
 * @returns 
 */
function getSecondLetter(wordsArray) {
    const words = JSON.parse(JSON.stringify(wordsArray))
    let secondLetter = ''

    if (words.length > 1) {
        secondLetter = words[words.length - 1][0];
        words.pop()
    } else {
        secondLetter = words[0][1];

        if (words[0].length <= 2)
            return secondLetter

        words[0] = words[0].substring(1, words[0].length)
    }

    if (!wordReg.test(secondLetter))
        return getSecondLetter(words)

    return secondLetter
}
