import { findUser } from "../../Services/api"
import { removeDuplicateObjectsFromArray } from "../arrayHelper"

export async function searchProfile(input, existingProfiles, pageNo) {
    if (!input)
        return []

    const numberOfProfilesToSend = 60
    let foundProfilesInExistingProfiles = searchProfileWithin(input, existingProfiles);

    if (foundProfilesInExistingProfiles.length >= numberOfProfilesToSend)
        return foundProfilesInExistingProfiles.slice(0, numberOfProfilesToSend)

    let mostVisitedProfiles = localStorage.getItem("mostVisitedProfiles")
    let profiles = []

    if (mostVisitedProfiles)
        profiles = JSON.parse(mostVisitedProfiles)

    profiles = await findUser(input, pageNo, 0, numberOfProfilesToSend)
    return removeDuplicateObjectsFromArray([...foundProfilesInExistingProfiles, ...profiles]).slice(0, numberOfProfilesToSend)
}

/**
 * 
 * @param {string} search 
 * @param {[Object]} listOfProfiles 
 */
function searchProfileWithin(search, listOfProfiles) {
    let loggedInUserId = localStorage.getItem("userid")
    return listOfProfiles.filter(user =>
        (user.username.includes(search) || user.screenName.includes(search)) && (user.user_id != loggedInUserId)
    )
}
