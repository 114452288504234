import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import Footer from '../Footer';
import InfiniteScroll from "react-infinite-scroll-component";
import { css } from '@emotion/css';
const ROOT_CSS = css({
   height: 582,
   width: 100
});
class Chat extends React.Component {
   constructor(props) {
      super(props);
      let edit_id = null;
      const path = this.props.location.pathname;
      const params = this.props.location.pathname.split('/');
      if (params[params.length - 1] != 'Chat') {
         edit_id = params[params.length - 1];
      }
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL,
         chatid: edit_id, items: Array.from({ length: 15 }), postType: "", bottom: true, loadings: false, imageVideoUrl: "", loader: false, filewithpath: "", sms: "", block: false, perpagefo: 1, loaded: true, errortitle: "", message_id: "", editerror: "", hasMore: true, chats: [], perpage: 0, image: "", screen: "", image: ""
      }


   }
   componentDidMount() {
      $(".errorbtn").click(function () {
         $(".errorpop").toggle();
      });
      this.setState({ username: this.props.location.state.username, screen: this.props.location.state.screen, image: this.props.location.state.image, loader: false });
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/messages/getUserMessagesById.php?user_id=' + this.state.chatid, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.success == true && responseJson.response.length > 0) {
               // var variable=responseJson.response[responseJson.response.length-1].messageID;
               this.setState({ chats: responseJson.response, loaded: false, items: Array.from({ length: 15 }), hasMore: true });
               //       console.log("variable",variable);
               // $("#one_89 #scrollableDivSms").animate({
               //    scrollTop: $('#'+variable).offset().top - $("#one_89 #scrollableDivSms").offset().top + $("#one_89 #scrollableDivSms").scrollTop()
               // });
               $("#one_89 #scrollableDivSms").animate({ scrollTop: $(document).height() }, 0);
               // var hash="#hash"+responseJson.response[responseJson.response.length-5].messageID;
               // window.location.hash = hash;


               this.setState({ loader: true });


            }
         });
   }

   fetchMoreComment() {
      // const savedHeight = document.body.offsetHeight;

      if (this.state.block == false) {
         $('#scrollableDivSms').addClass('scrollbarhiden');
         var variable = this.state.chats[0].messageID;
         this.setState({ loaded: true, loadings: true });
         setTimeout(() => {
            this.setState({ activecolor: 'first' });
            const token = localStorage.getItem('token');
            this.setState({ perpagefo: this.state.perpagefo + 1 });
            fetch(this.state.baseUrl + '/api/messages/getUserMessagesById.php?user_id=' + this.state.chatid + '&page=' + this.state.perpagefo + '&limit=15', {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {
                  if (responseJson.response.length > 0) {
                     for (var i = responseJson.response.length - 1; i >= 0; i--) {
                        this.state.chats.unshift(responseJson.response[i]);
                     }
                     //  $("#one_89 #scrollableDivSms").animate({
                     //  scrollTop: $('#'+variable).offset().top - $("#one_89 #scrollableDivSms").offset().top + $("#one_89 #scrollableDivSms").scrollTop()
                     //  });
                     this.setState({ chats: this.state.chats, items: this.state.items.concat(Array.from({ length: 15 })), hasMore: true, loaded: false, loadings: false }, () => {
                        var hash = "#hash" + responseJson.response[responseJson.response.length - 1].messageID;
                        window.location.hash = hash;
                        setTimeout(() => {
                           $('#scrollableDivSms').removeClass('scrollbarhiden');
                        }, 1000);

                     });
                  }
                  else {
                     this.setState({ block: true, loadings: false, loaded: false }, () => {
                        setTimeout(() => {
                           $('#scrollableDivSms').removeClass('scrollbarhiden');
                        }, 1000);
                     });
                  }
               })
         }, 2000);
      }

   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   gotouser() {
      localStorage.setItem("backid", "front");
      this.props.history.push('/' + this.state.username);
   }
   goback() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   checkComment() {
      if (this.state.loaded == false || this.state.block == true || this.state.chats.length == 0) {
         return 'loaderclass closeCd';
      }
      else {
         return 'loaderclass';
      }
   }
   sendsms() {
      this.setState({ bottom: true });
      if (this.state.sms.length > 0 || this.state.filewithpath.name && this.state.postType == "image") {
         var token = localStorage.getItem('token');
         var date = Date.now();
         var final = date / 1000;
         var formData = new FormData();
         if (this.state.filewithpath.name && this.state.postType == "image") {
            formData.append("image", this.state.filewithpath,
               this.state.filewithpath.name);
            formData.append("width", 800);
            formData.append("height", 1000);
            formData.append("isSentByMe", 1);
            formData.append("messageTo", this.state.chatid);
            formData.append("messageOn", final);
            var object = { image: this.state.imageVideoUrl, time: "11", height: 1000, width: 800, isSentByMe: 1, messageTo: this.state.chatid, messageOn: final, message: "" };
         }
         if (this.state.sms.length > 0) {
            formData.append("image", "");
            formData.append("isSentByMe", 1);
            formData.append("messageTo", this.state.chatid);
            formData.append("messageOn", final);
            formData.append("message", this.state.sms);
            var object = { image: null, isSentByMe: 1, messageTo: this.state.chatid, messageOn: final, message: this.state.sms };
         }
         this.state.chats.push(object);
         $("#one_89 #scrollableDivSms").animate({ scrollTop: $(document).height() }, 1000);
         this.setState({ chats: this.state.chats });
         var body = formData;
         axios.post(this.state.baseUrl + '/api/messages/postMessage.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
               // $("#one_89 #scrollableDivSms").animate({ scrollTop: $(document).height() }, 1000);
            }).catch((error) => {
               console.log(error);
            });
         this.setState({ sms: "", imageVideoUrl: "", postType: "", filewithpath: "", block: true });
      }
   }
   seeLess() {
      $(".pupup1B").toggle();
      $('#four1').addClass('closeBd');
      setTimeout(function () {
         $('#four1').removeClass('closeBd');
      }, 3000);
   }
   seeMore() {
      $(".pupup1B").toggle();
      $('#five1').addClass('closeBd');
      setTimeout(function () {
         $('#five1').removeClass('closeBd');
      }, 3000);
   }
   blockuserpop() {
      $(".pupup4").toggle();
      this.setState({ errortitle: "Block", editerror: "Are you sure would like to block " + this.state.screen + ", " + localStorage.getItem("screenname").split(" ")[0] + "?" }, () => {
         $(".errorpop").toggle();
      })
   }
   blockuser() {
      var token = localStorage.getItem('token');
      if (this.state.errortitle == "Block") {
         const body = { profileId: this.state.chatid }
         axios.post(this.state.baseUrl + '/api/user/blockUser.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
            }).catch((error) => {
               console.log(error);
            })
      }
      if (this.state.errortitle == "Delete Message") {
         var objIndex = this.state.chats.findIndex((obj => obj.messageID == this.state.message_id));
         if (objIndex !== -1) {
            this.state.chats.splice(objIndex, 1);
            this.setState({ chats: this.state.chats });
         }
         const body = { messageID: this.state.message_id };
         fetch(this.state.baseUrl + '/api/messages/deleteMessageById.php?messageID=' + this.state.message_id, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .catch((error) => {
               console.log(error);
            })
      }
   }
   deletesms(e) {
      this.setState({ message_id: e.currentTarget.id, errortitle: "Delete Message", editerror: "Are you sure would like to delete this message, " + localStorage.getItem("screenname").split(" ")[0] + "?" }, () => {
         $(".errorpop").toggle();
      });
   }
   typeing(e) {
      this.setState({ sms: e.target.value });
   }
   option() {
      $(".pupup1B").toggle();
   }


   onImageChange = e => {
      let file = e.target.files[0];
      this.setState({ filewithpath: file });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
         var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
         if (streetaddress == "data:image") {
            this.setState({ postType: "image" });
         }
         if (streetaddress == "data:video") {
            this.setState({ postType: "video" });
         }
         if (this.state.postType != "") {
            this.setState({ imageVideoUrl: reader.result });
         }
      };
   }
   closeImage() {
      this.setState({ imageVideoUrl: "", postType: "", filewithpath: "" });
   }
   changescroll() {
      if (this.state.chats.length > 0) {
         return "scrollClass showfile newscreen1";
      }
      else {
         return "scrollClass newscreen1";
      }
   }

   render() {
      return (
         <div>
            {/* <div className="newscreen1 newscreen01">
<div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}  id="one_89"> */}
            <div className={`${this.props.pos} screen1`} id="one_89">
               <div className="top newbg" id="myHeader">
                  {/* <!-- --------- header ---------------> */}
                  <section className="header headernoti2 header_msg">
                     <div className="row">
                        <div className="col-2 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><span defaultStyle="opacity:1" className="linkback" onClick={this.goback.bind(this)}><img className="backin" src="../../images/back.png" align="img" /></span></li>
                           </ul>
                        </div>
                        <div onClick={this.gotouser.bind(this)} className="col-8 header_center pl-0 pr-0 text-center">
                           <div class="chatusermain"> <div className="chatuserimage"><img src={this.state.baseUrl + this.state.image} /></div>
                              <h3>{this.state.screen}</h3>
                              <p>{this.state.username}</p>
                           </div></div>
                        <div className="col-2 header_right pl-0">
                           <ul className="uld">
                              <li className="more" onClick={this.option.bind(this)}><img src="../../images/more.png" align="img" /></li>
                           </ul>
                        </div>
                     </div>
                  </section>
               </div>
               <div className="tab-content messages ">
                  <div id="five1" class="likeaddto kapi"><img class="w-100" src="../../images/More.png" alt="image" /></div>
                  <div class="likeaddtop kapi" id="four1"><img class="w-100" src="../../images/setting_dont_follow_back.png" alt="image" /></div>
                  {/* <ScrollToBottom  className={this.state.bottom==true ? "newscreen1 w-100" :""}>  */}
                  <div
                     id={"scrollableDivSms"}
                     className={this.changescroll()}
                     style={{
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        margintop: 47,
                     }}
                  >

                     <InfiniteScroll
                        dataLength={this.state.chats.length}
                        next={this.fetchMoreComment.bind(this)}
                        style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                        inverse={true}

                        hasMore={true}
                        loader={
                           <div id="march" className={this.checkComment()}>
                              <img
                                 src="../../images/newgif.gif"
                                 alt="loading"
                              />
                           </div>}
                        endMessage={
                           <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                           </p>}

                        scrollableTarget="scrollableDivSms"
                     >
                        <div className="sectionone">

                           <div className={this.state.hasMore == false ? "commestsn democlass1 commestsn_endbutton" : this.state.chats.length <= 15 ? "commestsn democlass1 firstdate" : "commestsn democlass1 firstdate"}>


                              {this.state.chats.map((j, x) => {
                                 return (<>
                                    {j.isSentByMe == 0 ? <><span id={'hash' + j.messageID}></span><div id={j.messageID} onDoubleClick={this.deletesms.bind(this)} className={j.image !== null ? "message-left messageimage-left" : "message-left"}>
                                       {j.image == null ? <div className="underchat">
                                          <img src={this.state.baseUrl + this.state.image} />
                                       </div> : ""}
                                       {j.image !== null ? <div className="upimg"><img src={j.time ? j.image : this.state.baseUrl + j.image} /></div> : <h5>{j.message}</h5>}
                                    </div></> : <><span id={'hash' + j.messageID}></span><div id={j.messageID} onDoubleClick={this.deletesms.bind(this)} className="message-right">
                                       {j.image !== null && j.message && j.message !== null ? <><div className="upimg"><img src={j.time ? j.image : this.state.baseUrl + j.image} /></div><h5>{j.message}</h5></> : j.image !== null ? <div className="upimg"><img src={j.time ? j.image : this.state.baseUrl + j.image} /></div> : <h5>{j.message}</h5>}
                                    </div></>}
                                 </>
                                 )
                              })}

                           </div>
                        </div>
                     </InfiniteScroll>
                  </div>
                  {/* </ScrollToBottom>  */}
               </div>
               <div className="intype commentdiv chatinput">
                  {this.state.imageVideoUrl !== "" ? <div class="showhide"><div className="crosebtn" onClick={this.closeImage.bind(this)}><img class="closeImgae" src="../../images/closeImage.png" /></div><img src={this.state.imageVideoUrl} /></div> : ""}
                  <span className="upload"><input type='file' onChange={this.onImageChange.bind(this)} />
                     <img src="../../images/setting_press.png" />
                  </span>
                  <textarea id="type" value={this.state.sms} onChange={this.typeing.bind(this)} className="form-control1" placeholder={"Send a message to " + this.state.screen.split(" ")[0] + ", " + localStorage.getItem("screenname").split(" ")[0] + "!"} />
                  <button onClick={this.sendsms.bind(this)} type="btn">{this.state.sms.length > 0 || this.state.filewithpath !== "" ? <img src="../../images/ip.png" /> : <img src="../../images/sendicon.png" />}</button>
               </div>
               <Footer {...this.props} />
               <div className="op pupup1B">
                  <div className="upbuttonB h-100" onClick={this.option.bind(this)}></div>
                  <ul>
                     <li><a onClick={this.seeMore.bind(this)}><img src="../../images/More.png" /> see more like this</a></li>
                     <li><a onClick={this.seeLess.bind(this)}><img src="../../images/setting_dont_follow_back.png" /> see less like this</a></li>
                     <li><a className="more" onClick={this.blockuserpop.bind(this)}><img src="../../images/block.png" />{"block " + this.state.screen.split(" ")[0]}</a></li>
                     <li><span data-div_id={this.state.chatid} className="link">
                        <Link to={{
                           pathname: `/Report/${this.state.chatid}`,
                           state: { name: this.state.screen }
                        }} onClick={this.setStorage.bind(this)}>
                           <img src="../../images/report.png" /> {'report ' + this.state.screen.split(" ")[0]}</Link> </span></li>
                     <button className="upbtn upbuttonB" onClick={this.option.bind(this)}>Cancel</button>
                  </ul>
               </div>
               <div className="op errorpop mainpop">
                  <div className="errorbtn error h-100"></div>
                  <ul>
                     <li className="lipost">{this.state.errortitle}</li>
                     <li>{this.state.editerror}</li>
                     <div className="newpop">
                        <button className="upbtn errorbtn">CANCEL</button>
                        <button className="upbtn errorbtn" onClick={this.blockuser.bind(this)}>YES</button>
                     </div>
                  </ul>
               </div>
            </div>
         </div>
         //</div>
      );
   };
};
export default Chat;

