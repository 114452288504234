import React from 'react';

const CustomPopUp = ({ isOpen, onClose, popup, multipleButton }) => {
    if (!isOpen) return null;

    const handleButtonClick = () => {
        popup.onClick();
        onClose();
    }

    return (
        <div className={`popup ${isOpen ? 'open' : ''}`}>
            <div className="cover-block" onClick={onClose}></div>
            <div className="content">
                <div className='heading'>
                    {popup && (
                        <h4 className="text-center width-100">{popup.heading}</h4>
                    )}
                </div>
                <div className='message'>
                    {popup && (
                        <p className="text-left width-100">{popup.message}</p>
                    )}
                </div>
                {!multipleButton ? (<div className='operators'>
                    <button className="operators-button" onClick={onClose}>OKAY</button>
                </div>)
                    : (<div className='operators'>
                        <button className="operators-button" onClick={onClose}>CANCEL</button>
                        <button className="operators-button" onClick={handleButtonClick}>YES</button>
                    </div>
                    )}
            </div>
        </div>
    );
};

export default CustomPopUp;