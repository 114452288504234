import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Footer from '../Footer';
import $, { isEmptyObject } from 'jquery';
import InfiniteScroll from "react-infinite-scroll-component";
import { userListForLogin, usersListForGuets, fetchSortedUser } from '../../Services/api';
import { convertToTeX } from '../../utils/math';
import { getProfilesList } from '../../utils/profiles/manageProfilesList';
import { searchProfile } from '../../utils/profiles/searchProfiles';
import { showInitials } from '../../utils/profiles/shared';
import { MyContext } from '../../context';
import { formatSearchResult } from '../../utils/math';
import { getListBySortingType } from '../../utils/profiles/manageProfilesList';
import { sortArrayOfObjects } from '../../utils/arrayHelper';
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];

export const homepagePathname = "/"
class SecondTab extends React.Component {
   static contextType = MyContext
   constructor(props) {

      super(props);
      this.state = {
         contentEditable: "timesort", reco: [],
         searchPerameter: "", slectedConst: "", slectedVar: "", searchinguser: [], recomended: [], activitySortedUsers: [], temporallySortedUsers: [],
         baseUrl: process.env.REACT_APP_BASE_URL, searchloader: false, error: "",
         slectedConst: "",
         hasMoreUser: true,
         page: 2,
         baseUrlNode: process.env.REACT_APP_BASE_URL_NODE,
         inputValue: "",
      }

   }
   componentDidMount() {

      const { sharedData, setActiveTab } = this.context

      setActiveTab("second")

      if (sharedData && sharedData.pageWiseData[homepagePathname] && sharedData.pageWiseData[homepagePathname].state)
         this.setState({ ...sharedData.pageWiseData[homepagePathname].state })

      localStorage.setItem("footer", "second");
      if (JSON.parse(localStorage.getItem("entry")) > 0 | JSON.parse(localStorage.getItem("entry")) !== null) {
         this.setState({ recomended: JSON.parse(localStorage.getItem("entry")) }, () => {
            //this.mainProfileColor();
         });

         // initially filter data for temporally/activity
         // this.getSortedUser('temporally', JSON.parse(localStorage.getItem("entry")));
         // this.getSortedUser('activity', JSON.parse(localStorage.getItem("entry")));
      }
      // fetch(this.state.baseUrlNode+'/api/recomended',{
      //    method: "GET",
      //    headers: {
      //    "x-access-token":localStorage.getItem("timeToken"),
      //    Accept: "application/json",
      //    "Content-Type": "application/json",
      //    },     
      //    })
      //    .then((response) => response.json())
      //    .then((responseJson) =>{
      //       this.setState({reco:responseJson.data});
      //       //console.log('responseJson ################################',responseJson.data);
      //    }).catch((error)=>{
      //       console.log('error ################################',error.message);
      //    });
      // if(JSON.parse(localStorage.getItem("entry")) <= 0 | JSON.parse(localStorage.getItem("entry")) == null)
      // {
      // setTimeout(() => {
      // const token = localStorage.getItem('timeToken');
      // if(token && token!==null)
      // {
      //    this.registeruser(1);
      // }
      // else
      // {
      //    this.nonregister(1);
      // }
      getProfilesList().then((res) => {
         if (res.length > 0) {
            this.setState((prevState) => ({
               recomended: [...res],
               hasMoreUser: true
            }));
         // initially filter data for temporally/activity
         // this.getSortedUser('temporally', res);
         // this.getSortedUser('activity', res);
         }
         if (res.length == 0 || res.length < 60) {
            this.setState({ hasMoreUser: false });
         }
      })
      // }, 15000);
      // }
      // else
      // {

      // this.setState({recomended:[],recomended:JSON.parse(localStorage.getItem("entry"))},()=>
      // {
      // //this.mainProfileColor();
      // });
      // }
   }
   displayOpenProfiles(openProfiles) {
      this.addProfilePathsToHistory(openProfiles);
      this.redirectToLastVisitedProfile(openProfiles);
   }

   redirectToLastVisitedProfile(openProfiles) {
      this.props.history.push(openProfiles[openProfiles.length - 1], { enteredBy: "push" });
   }

   addProfilePathsToHistory(openProfiles) {
      openProfiles.slice(0, openProfiles.length - 1).forEach(path => {
         window.history.pushState({ enteredBy: "pushState" }, "", path);
      });
   }
   componentWillUnmount() {
      this.updateSharedDataInContext()
   }

   updateSharedDataInContext() {
      const { sharedData, setSharedData } = this.context
      let openProfiles = []

      if (sharedData.pageWiseData[homepagePathname] && sharedData.pageWiseData[homepagePathname].openProfiles)
         openProfiles = sharedData.pageWiseData[homepagePathname].openProfiles

      sharedData.pageWiseData[homepagePathname] = { ...sharedData.pageWiseData[homepagePathname], state: this.state, openProfiles }

      setSharedData({
         ...sharedData,
      });
   }

   fetchMoreUserData = () => {
      this.fetchMoreUser(this.state.page);
   };

   async fetchMoreUser(page) {
      const token = localStorage.getItem('timeToken');
      if (token && token !== null) {
         let newData = await userListForLogin(page);
         if (newData.length > 0) {
            this.setState((prevState) => ({
               recomended: [...prevState.recomended, ...newData],
               page: page + 1,
            }));
         }

         if (newData.length == 0 || newData.length < 60) {
            this.setState({ hasMoreUser: false });
         }
      } else {
         let newData = await usersListForGuets(page);
         if (newData.length > 0) {
            this.setState((prevState) => ({
               recomended: [...prevState.recomended, ...newData],
               page: page + 1,
            }));
         }

         if (newData.length == 0 || newData.length < 60) {
            this.setState({ hasMoreUser: false });
         }
      }

      // initially filter data for temporally/activity
      // this.getSortedUser('temporally', this.state.recomended);
      // this.getSortedUser('activity', this.state.recomended);
   }

   async registeruser(page) {
      // fetch(this.state.baseUrl+'/api/group/user.php',{
      //    method: "GET",
      //    headers: {
      //    Accept: "application/json",
      //    "Content-Type": "application/json",
      //    "Authorization": "Bearer " + token,
      //    },     
      //    })
      //    .then((response) => response.json())
      //    .then((responseJson) =>{
      //    this.setState({recomended:responseJson.response});
      //    localStorage.setItem("entry", JSON.stringify(this.state.recomended));
      //    }).catch((error)=>{
      //    console.log(error);
      //    })

      // fetch(this.state.baseUrlNode+'/api/recomended',{
      //    method: "GET",
      //    headers: {
      //    "x-access-token":token,
      //    Accept: "application/json",
      //    "Content-Type": "application/json",
      //    },     
      //    })
      //    .then((response) => response.json())
      //    .then((responseJson) =>{
      //       this.setState({recomended:responseJson.data},()=>{
      //          console.log('recognize',this.state.recomended);
      //          //this.mainProfileColor();
      //       });
      //       localStorage.setItem("entry", JSON.stringify(responseJson.data));
      //       //console.log('responseJson ################################',responseJson.data);
      //    }).catch((error)=>{
      //       console.log('error ################################',error.message);
      //    });
      let data = await userListForLogin(page);
      this.setState({ recomended: data }, () => {
         //this.mainProfileColor();
      });
   }

   async nonregister(page) {
      // fetch(this.state.baseUrl+'/api/group/dummyusers.php',{
      //    method: "GET",
      //    headers: {
      //    Accept: "application/json",
      //    "Content-Type": "application/json",
      //    },     
      //    })
      //    .then((response) => response.json())
      //    .then((responseJson) =>{
      //    this.setState({recomended:responseJson.response});
      //    localStorage.setItem("entry", JSON.stringify(this.state.recomended));
      //    }).catch((error)=>{
      //    console.log(error);
      //    })
      // fetch(this.state.baseUrlNode+'/api/guestRecomended',{
      //    method: "GET", 
      //    })
      //    .then((response) => response.json())
      //    .then((responseJson) =>{
      //       this.setState({reco:responseJson.data});
      //       localStorage.setItem("entry", JSON.stringify(responseJson.data));
      //       //this.mainProfileColor();
      //       //console.log('responseJson ################################',responseJson.data);
      //    }).catch((error)=>{
      //       console.log('error ################################',error.message);
      //    });

      let data = await usersListForGuets(page);
      this.setState({ recomended: data }, () => {
         //this.mainProfileColor();
      });

   }



   userSearch(event) {
      this.setState({ searchPerameter: event.target.value, searchloader: true }, () => {
         this.timeout = setTimeout(async function () {
            // this.searchUser();
            let profiles = await searchProfile(this.state.searchPerameter, this.state.recomended)
            this.setState({ searchinguser: profiles, searchloader: false })
         }.bind(this), 300);
      });
      if (this.timeout) {
         clearTimeout(this.timeout)
         this.timeout = null
      }
   }
   async searchUser() {
      if (this.state.searchPerameter != "") {
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('timeToken');
         //const token = localStorage.getItem('token');
         try {
            var response = await fetch(this.state.baseUrlNode + '/api/searching?search=' + this.state.searchPerameter + '&page=1&limit=16&user_id=' + variable, {
               //var response =  await fetch(this.state.baseUrl+'/api/user/search.php?search='+this.state.searchPerameter+'&page=1&limit=16&user_id='+variable,{
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  //"Authorization": "Bearer " + token,
                  "x-access-token": token,
               },
            });
            const movies = await response.json();
            if (movies.status == 'success') {
               if (movies.response.length) {
                  const dusers = await movies.response;
                  this.setState({ searchinguser: [] }, () => {
                     this.setState({ searchinguser: dusers, searchloader: false }, () => {
                        this.mainProfileColor()
                     });
                     return;
                  });
               }
               else {
                  this.setState({ error: "User Not Found" });
               }
            }
            else {
               this.setState({ searchloader: false, error: "User Not Found" });
            }

         }
         catch (error) {
            console.log(error, "tab");
         }
      }
   }
   mainProfileColor() {
      for (let index = 0; index < this.state.recomended.length; index++) {
         var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
         $(".multiuser" + index + ", .imgg").css("color", "");
         $(".multiuser" + index + ", .imgg").css("color", random_color.textColor);
         $(".multiuser" + index + ", .imgg").css("background-color", "");
         $(".multiuser" + index + ", .imgg").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
      }
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".multiuser, .imgg").css("color", "");
      $(".multiuser, .imgg").css("color", random_color.textColor);
      $(".multiuser, .imgg").css("background-color", "");
      $(".multiuser, .imgg").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   removeSearch() {
      document.getElementById("lemoncals").value = "";
      this.setState({ searchinguser: [], searchPerameter: "" });
   }
   relevancySearch() {
      this.setState({ slectedVar: "", recomended: [] });
      if (JSON.parse(localStorage.getItem("entry"))) {
         this.setState({ recomended: JSON.parse(localStorage.getItem("entry")) });
      }
   }
   mutualitySearch() {
      this.setState({ slectedVar: "mutual" });
   }
   alphabeticallySearch() {
      this.setState({ slectedVar: "alpha" });
   }
   PopularitySearch() {
      this.setState({ slectedVar: "popular" });
   }
   flipSearch() {
      this.setState({ slectedVar: "flip" });
   }
   PseudorandomlySearch() {
      this.setState({ slectedVar: "shuffle" });
   }
   temporallySearch() {
      this.setState({ slectedVar: "temporal" });
   }
   activitySearch() {
      this.setState({ slectedVar: "active" });
   }
   mutualitySearchList() {
      $(".pupup3").toggle();
      this.setState({ slectedConst: "mutual" });
      if (this.state.searchPerameter == "") {
         this.setState({ slectedVar: "mutual" });
      }
   }
   alphabeticallySearchList() {
      $(".pupup3").toggle();
      this.setState({ slectedConst: "alpha" });
      if (this.state.searchPerameter == "") {
         this.setState({ slectedVar: "alpha" });
      }
   }
   relevancySearchList() {
      $(".pupup3").toggle();
      if (this.state.searchPerameter == "") {
         this.relevancySearch()
      }
      this.setState({ slectedConst: "" });

      fetch(this.state.baseUrl + '/api/user/search.php?search=' + this.state.searchPerameter + '&page=1&limit=16&user_id=' + localStorage.getItem('userid'), {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token'),
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({ searchinguser: responseJson.response }, () => {
               //this.mainProfileColor();
            });
         });
   }


   filterUserin(data) {
      if (this.state.slectedConst == "") {
         return data;
      }
      else if (this.state.slectedConst == "mutual") {
         return data.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse();
      }
      else if (this.state.slectedConst == "alpha") {
         return data.sort((a, b) => a.screenName.localeCompare(b.screenName));
      }
   }
   openpop() {
      $(".pupup3").toggle();
   }
   fetchmakeprofile(data) {
      console.log('one', data);
      var first = data && data.split(" ") ? data.split(" ")[0] : "";
      var firstn = first.charAt(0);

      if (data && data.split(" ") && data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
  }
   async getSortedUser(sortingType, users) {
      const newUserList = users ? users : JSON.parse(localStorage.getItem('entry'));
      const result = await fetchSortedUser(sortingType,newUserList);
      if(sortingType == 'temporally') {
         this.setState({ temporallySortedUsers: result });
      } else if (sortingType == 'activity') {
         this.setState({ activitySortedUsers: result });
      }
   }
   filterUser(data) {
      if (this.state.slectedVar == "") {
         return data;
      }
      else if (this.state.slectedVar == "mutual") {
         const list = getListBySortingType({sortingType: 'Mutuality'});
         return list;
      }
      else if (this.state.slectedVar == "alpha") {
         return data.sort((a, b) => a.screenName.localeCompare(b.screenName));
      } 
      else if (this.state.slectedVar == "popular") {
         return sortArrayOfObjects(data, 'total_visits', false)
      }
      else if (this.state.slectedVar == "flip") {
         return data.reverse();
      }
      else if (this.state.slectedVar == "shuffle") {
         const shuffledData = this.shuffleArray(data);
         return shuffledData;
      }
      else if (this.state.slectedVar == "temporal") {
         return sortArrayOfObjects(data, 'eventStats.totalEventsIn3Months', false)
      }
      else if (this.state.slectedVar == "active") {
         return sortArrayOfObjects(data, 'eventStats.closestEventInSeconds', true)
      }
   }
   setStorage(user) {
      const activeTab = this.context.activeTab
      const { appContext, location } = this.props;
      const { state, actions } = appContext;

      if (activeTab == 'second' && location.pathname == '/' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 1) {
         actions.setHomePageActive({
            status: false,
            activeHomePageSubspace: null,
         });
      }

      localStorage.setItem("backid", "front");

      this.props.setVisitedPaths((prevPaths) => [...prevPaths, user.username]);
      if(this.state.searchPerameter != '') {
         this.setState({searchPerameter: '', inputValue: ''});
      }
   }

   handleMouseOverTab = (event, username, actionType) => {
      const targetElement = event.target;
      // let parentDiv = targetElement?.parentNode;
      // while (!parentDiv.classList.contains('higherLevelGroup')) {
      //    parentDiv = parentDiv.parentNode;
      // }
      // if(parentDiv) {
      //    const linkElement = parentDiv.querySelector('.hoveredLink');
      //    if (linkElement) {
      //       if(actionType == 'leave') {
      //          linkElement.innerText = '';
      //          linkElement.style.padding = '';
      //          return
      //       }
      //       linkElement.innerText = `timesort.com/${username}`;
      //       linkElement.style.padding = '2px 5px';
      //    }
      // }
      const elementRect = targetElement?.getBoundingClientRect();
      const elementX = elementRect?.left + window?.scrollX;
      const elementY = elementRect?.top + window?.scrollY;
      let elementR = window.innerWidth - (elementRect?.right + window.scrollX);
      const fullnameElement = targetElement?.querySelector(".fullname");
      const tooltip = targetElement?.querySelector("p");
      if (fullnameElement) {
         fullnameElement.style.top = `calc(${elementY + targetElement?.offsetHeight}px - 2px)`;
         if (fullnameElement?.classList.contains("right-aligned") && fullnameElement?.classList.contains("fullName")) {
            fullnameElement.style.right = `${elementR}px`;
         } else {
            fullnameElement.style.left = `${elementX}px`;
         }
         fullnameElement.style.maxWidth = 'max-content';
      } else if (tooltip) {
         const nameElement = targetElement?.querySelector(".fullname");
         if (tooltip && tooltip?.classList.contains('fullName')) {
            tooltip.style.top = `calc(${elementY + targetElement?.offsetHeight}px - 2px)`;
            if(tooltip?.classList.contains('right-aligned') && tooltip?.classList.contains('fullName')) {
               tooltip.style.right = `${elementR}px`;
            } else {
               tooltip.style.left = `${elementX}px`;
            }
            tooltip.style.maxWidth = 'max-content';
         } else if (nameElement) {
            nameElement.style.top = `calc(${elementY + targetElement?.offsetHeight}px - 2px)`;
            if (targetElement?.classList.contains("right-aligned") && targetElement?.classList.contains("fullname")) {
               nameElement.style.right = `${elementR}px`;
            } else {
               nameElement.style.left = `${elementX}px`;
            }
            nameElement.style.maxWidth = 'max-content';
         }
      }
   };


   renderUserGroups = () => {
      const allUsers = this.filterUser(this.state.recomended);
      const Allelements = [];
      const upperGroupSize = 192

      for (let g = 0; g < Math.ceil(allUsers.length / upperGroupSize); g++) {
         const users = allUsers.slice(g * upperGroupSize, (g + 1) * upperGroupSize);
         const userGroups = [];
         let groupSize;

         for (let i = 0; i < users.length; i += groupSize) {
            let higherLevelGroupClass = '';

            if (i / 64 / 2 === 0) {
               groupSize = 64; // First group with 64 elements
               higherLevelGroupClass = 'firstGroup';
            // } else if (i == 192) {
            //    groupSize = 64; // Last group with 64 elements
            //    higherLevelGroupClass = 'lastGroup';
            } else {
               groupSize = 128; // Middle groups with 128 elements
               higherLevelGroupClass = 'middleGroup';
            }

            const group = users.slice(i, i + groupSize);
            const parentDivs = [];
            // const hoveredLink = (<p className='hoveredLink'></p>);

            for (let j = 0; j < group.length; j += 32) {
               const subgroup = group.slice(j, j + 32);
               const parentDiv = (
                  <div className={`parentDiv group-${i / 32}`} key={i + j}>
                     {subgroup.map((u, k) => (
                        <Link className="col-3" key={k}
                           onClick={this.setStorage.bind(this, u)}
                           to={{
                              pathname: `/${u.username}`,
                              state: { id: u.user_id }
                           }}
                        >
                           <span
                              // to={{
                              //    pathname: `/${u.username}`,
                              //    state: { id: u.user_id }
                              // }}
                              className="link"
                           // data-id={i + j + k}
                           // id={u?.user_id}

                           // data-div_name="#two_3"
                           // onClick={this.setStorage.bind(this, u)}
                           // data-toggle="tab"
                           >
                              {u.profileImage !== "" ? (
                                 <img className="u_img" src={this.state.baseUrl + u.profileImage} align="images" />
                              ) : (
                                 ""
                              )}
                              <p onMouseOver={(e) => this.handleMouseOverTab(e, u?.username, 'over')}>{showInitials(u?.screenName)}
                                 <p className={`fullName ${(typeof this.props.abc == 'string') && (j == 32 || j == 96) && (k != 0 && (k % 4) != 0)  ? 'right-aligned' : 'left-aligned'}`}>{u?.screenName}</p>
                              </p>
                           </span>
                        </Link>
                     ))}
                  </div>
               );

               parentDivs.push(parentDiv);
            }

            userGroups.push(
               <div className={`higherLevelGroup ${higherLevelGroupClass}`} key={i / 32}>
                  {parentDivs}
                  {/* {hoveredLink} */}
               </div>
            );
            // console.log({ higherLevelGroupClass, isLastGroup, parentDivs, userGroups });
         }
         Allelements.push(
            <div className='rowBlock'>
               {userGroups}
            </div>
         );
      }
      return Allelements;
   };
   render() {
      return (
         //<div className="newscreen1 newscreen01">
         //<div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback nabc" : "screen1 active fades abc"}  id="one_3">
         <div className={`${this.props.pos} screen1 active`} id="one_3">
            <div className="top" id="myHeader">
               {/* header  */}
               <section className="header1">
                  <div className="search">
                     <form className="form-inline mt-md-0">
                        <input className="form-control" spellCheck="false" id="lemoncals" onChange={(e) => this.setState({ inputValue: e.target.value })} value={this.state.inputValue} type="text" onKeyUp={this.userSearch.bind(this)} placeholder={localStorage.getItem('screenname') ? "Search Timesort, " + localStorage.getItem('screenname').split(" ")[0] + "!" : "Search Timesort"} aria-
                           label="Search" />
                        {(() => {
                           if (this.state.searchPerameter != "") {
                              return (<span className="btn closeBtn" onClick={() => { this.setState({ searchPerameter: "", inputValue: "" }) }} dangerouslySetInnerHTML={{ __html: convertToTeX('\\times') }}></span>);
                           }
                           // else
                           // {
                           // return(<span className="btn"><span>\(\bullet\)</span></span>);
                           // }
                        })()}
                     </form>
                     {/* <img className="remove" onClick={this.openpop.bind(this)} src="../../images/Filter.png"/> */}
                  </div>
                  {this.state.searchloader == false ? this.state.searchPerameter != "" && this.state.searchinguser.length <= 0 ? <div className="loaderclass notfound">
                     <p>absent temporal information</p>
                  </div> : "" :
                     this.state.searchinguser.length <= 0 ?
                        <div className="loaderclass userloder">
                           <img
                              src="../../images/newgif.gif"
                              alt="loading"
                           />
                        </div> : ""
                  }
                  {/* {
                     this.state.searchPerameter == "" ?
                        <div className="row bttndiv">
                           <div className="col-6 header_left ">
                              <a onClick={this.relevancySearch.bind(this)} href="#">relevancy</a>
                           </div>
                           <div className="col-4 header_center text-center">
                     <a onClick={this.mutualitySearch.bind(this)} href="#">mutuality</a>
                  </div> 
                           <div className="col-6 header_right ">
                              <a onClick={this.alphabeticallySearch.bind(this)} href="#">alphabetically</a>
                           </div>
                        </div> : ""
                  } */}
               </section>
            </div>
            {/* {
     this.state.searchloader == true &&  this.state.searchPerameter != "" && this.state.searchinguser.length>0 ?
     <div className="loaderclass userlodertop">
         <img
             src="../../images/newgif.gif"
             alt="loading"
          /> 
          </div>:""
   } */}
            {
               this.state.searchinguser.length == null && this.state.searchPerameter != "" && this.state.searchinguser.length > 0 && this.state.searchloader == true ?
                  <div className="loaderclass userlodertop">
                     <img
                        src="../../images/newgif.gif"
                        alt="loading"
                     />
                  </div> : ""
            }

            <div className={this.state.searchPerameter == "" ? "tab-content tab-content3 searchlst kapil" : "tab-content tab-content3 searchlst kapil aftersearch"} id='scrollableDivOne_3'>
               {

                  (() => {


                     const searchLenghth = this.filterUserin(this.state.searchinguser).length;

                     const leftSearchColumn = this.state.searchinguser.slice(0, Math.ceil(searchLenghth / 2));
                     const rightSearchColumn = this.state.searchinguser.slice(Math.ceil(searchLenghth / 2), searchLenghth);

                     return this.state.searchPerameter !== "" ? (
                        // <div className="row py-0 my-0">{leftSearchColumn.map((p, i) => {
                        <div className="row py-0 my-0">
                           <div className="col-6 leftSearchColumn" >
                              {leftSearchColumn.map((p, i) => {
                                 return (
                                    <Link to={{
                                       pathname: `/${p.username}`,
                                       state: { id: p.user_id }
                                    }} onClick={this.setStorage.bind(this, p)}
                                    >
                                       <div className="userin" id={p.user_id} data-div_name="#one_3">

                                          {/* <span class={p.profileImage == "" ? "imgg imcnti" : "imgg"}>
                                    {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage}
                                       align="images" />
                                       : <span className={"multiuser" + i} >{this.fetchmakeprofile(p.screenName)}</span>}
                                 </span> */}
                                          <h6 dangerouslySetInnerHTML={{ __html: formatSearchResult(p.screenName) }}></h6>

                                          {/* <p >{p.mutual} mutual</p> */}
                                       </div>
                                    </Link>
                                 )
                              }
                              )
                              }
                           </div>
                           <div className="col-6 rightSearchColumn" >
                              {rightSearchColumn.map((p, i) => {
                                 return (
                                    <Link to={{
                                       pathname: `/${p.username}`,
                                       state: { id: p.user_id }
                                    }} onClick={this.setStorage.bind(this, p)}
                                    >
                                       <div className="userin" id={p.user_id} data-div_name="#one_3">

                                          {/* <span class={p.profileImage == "" ? "imgg imcnti" : "imgg"}>
                                    {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage}
                                       align="images" />
                                       : <span className={"multiuser" + i} >{this.fetchmakeprofile(p.screenName)}</span>}
                                 </span> */}
                                          <h6 dangerouslySetInnerHTML={{ __html: formatSearchResult(p.screenName) }}></h6>

                                          {/* <p >{p.mutual} mutual</p> */}
                                       </div>
                                    </Link>
                                 )
                              }
                              )
                              }
                           </div>
                        </div>
                     )
                        :
                        this.state.recomended.length > 0 ?
                           <div>
                              <div className="userlist">
                                 {/* <h2 className="mt-0">Explore</h2> */}
                                 <InfiniteScroll
                                    dataLength={this.state.recomended.length - 8}
                                    next={this.fetchMoreUserData}
                                    hasMore={this.state.hasMoreUser}
                                    loader={<div className="userlistloader"><img src="../../images/newgif.gif" alt="loading" /></div>}
                                    scrollableTarget="scrollableDivOne_3">
                                    <div className="row mainUserList ">
                                    <div className="sortingBtnWrapper">
                                          <div className="sortingBtn">
                                             <a onClick={this.relevancySearch.bind(this)} href="javascript:void(0)">R</a>
                                             <p onClick={this.relevancySearch.bind(this)} className='sortingBtnInfo'>Relevancy</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.temporallySearch.bind(this)} href="javascript:void(0)">T</a>
                                             <p onClick={this.temporallySearch.bind(this)} className='sortingBtnInfo'>Temporally</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.mutualitySearch.bind(this)} href="javascript:void(0)">M</a>
                                             <p onClick={this.mutualitySearch.bind(this)} className='sortingBtnInfo'>Mutuality</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.activitySearch.bind(this)} href="javascript:void(0)">A</a>
                                             <p onClick={this.activitySearch.bind(this)} className='sortingBtnInfo'>Activity</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.alphabeticallySearch.bind(this)} href="javascript:void(0)">A</a>
                                             <p onClick={this.alphabeticallySearch.bind(this)} className='sortingBtnInfo'>Alphabetically</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.PseudorandomlySearch.bind(this)} href="javascript:void(0)">P</a>
                                             <p onClick={this.PseudorandomlySearch.bind(this)} className='sortingBtnInfo'>Pseudorandomly</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.flipSearch.bind(this)} href="javascript:void(0)">F</a>
                                             <p onClick={this.flipSearch.bind(this)} className='sortingBtnInfo'>Flip</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.PopularitySearch.bind(this)} href="javascript:void(0)">P</a>
                                             <p onClick={this.PopularitySearch.bind(this)} className='sortingBtnInfo'>Popularity</p>
                                          </div>
                                       </div>
                                       {
                                          this.renderUserGroups()
                                       }
                                    </div>
                                    {/* <div className="row ">
           { this.filterUser(this.state.recomended).map((u,i)=>{
               
            return(
            <div className="col-3">
               <a className="link" data-id={i} id={u.user_id} data-div_name="#one_3" data-toggle="tab" >
               <Link to={{
               pathname: `/${u.username}`,
               state: {id:u.user_id}
               }}  onClick={this.setStorage.bind(this,u)}
               >
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className={"multiuser"+i}>
             <div className="profile_initials"> */}
                                    {/* {this.fetchmakeprofile(u.screenName)} */}
                                    {/* </div>
             </span>}</Link>
                  <p>{u.screenName}</p>
               </a>
            </div>
            )
               
            })
         }
            </div> */}
                                 </InfiniteScroll>
                              </div>

                              {this.state.reco.length > 0 ? <div className="userlist">
                                 <h2 className="mt-0">Top Recommended</h2>
                                 <div className="row ">
                                    {this.filterUser(this.state.reco).map((u, i) => {
                                       return (
                                          <div className="col-3">
                                             <a className="link" data-id={i} id={u.user_id} data-div_name="#one_3" data-toggle="tab" >
                                                <Link to={{
                                                   pathname: `/${u.username}`,
                                                   state: { id: u.user_id }
                                                }} onClick={this.setStorage.bind(this, u)}
                                                >
                                                   {u.profileImage && u.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + u.profileImage}
                                                      align="images" />

                                                      : <span className={"multiuser" + i}>
                                                         <div className="profile_initials">
                                                            {/* {this.fetchmakeprofile(u.screenName)} */}
                                                         </div>
                                                      </span>}</Link>
                                                <p>{u.screenName}</p>
                                             </a>
                                          </div>
                                       )

                                    })
                                    }
                                 </div>
                              </div> : ""}

                              {/* <div className="userlist">
             <h2 className="mt-0">Recommended</h2>
              <div className="row ">
                { this.filterUser(this.state.recomended).map((u,i)=>{
                    if(i>=12 && i<=23)
                    {
                 return(
                 <div className="col-3">
                    <a className="link" data-id={i} id={u.user_id} data-div_name="#one_3"  data-toggle="tab" >
                    <Link to={{
               pathname: `/${u.username}`,
               state: {id:u.user_id}
               }}  onClick={this.setStorage.bind(this)}
               >
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className="multiuser" >
               <div className="profile_initials">
                  {this.fetchmakeprofile(u.screenName)}
               </div>
               </span>}</Link>
                       <p>{u.screenName}</p>
                    </a>
                 </div>
                 )
                    }
                 })
              }
                 </div>
                 </div> */}
                              {/* <div className="userlist">
             <h2 className="mt-0">More</h2>
              <div className="row ">
                { this.filterUser(this.state.recomended).map((u,i)=>{
                    if(i>=24 && i<=35)
                    {
                 return(
                 <div className="col-3">
                    <a className="link" data-id={i} id={u.user_id} data-div_name="#one_3"  data-toggle="tab" >
                    <Link to={{
               pathname: `/${u.username}`,
               state: {id:u.user_id}
               }}   onClick={this.setStorage.bind(this)}
               >
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className={"multiuser"+i}>
             <div className="profile_initials">
                {this.fetchmakeprofile(u.screenName)}
             </div>
             </span>}</Link>
                       <p>{u.screenName}</p>
                    </a>
                 </div>
                 )
                    }
                 })
              }
                 </div>
                 </div>
                 <div className="userlist">
             <h2 className="mt-0">More</h2>
              <div className="row ">
                { this.filterUser(this.state.recomended).map((u,i)=>{
                    if(i>=36 && i<=59)
                    {
                 return(
                 <div className="col-3">
                    <a className="link" data-id={i} id={u.user_id} data-toggle="tab" >
                    <Link to={{
               pathname: `/${u.username}`,
               state: {id:u.user_id}
               }}   onClick={this.setStorage.bind(this)}
               >
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className={"multiuser"+i} >
             <div className="profile_initials">
                {this.fetchmakeprofile(u.screenName)}
             </div>
             </span>}</Link>
                       <p>{u.screenName}</p>
                    </a>
                 </div>
                 )
                    }
                 })
              }
                 </div>
                 </div>          */}
                           </div>
                           : <div className="loaderclass">
                              <img
                                 src="../../images/newgif.gif"
                                 alt="loading"
                              />
                           </div>
                  })()
               }

            </div>
            <Footer {...this.props} />
            <div className="op pupup3">
               <div className="remove h-100" onClick={this.openpop.bind(this)}></div>
               <ul>
                  <h5 className="text-center">sort</h5>
                  <li><a onClick={this.relevancySearchList.bind(this)} className="remove"><img src="../../images/More.png" /> relevancy</a></li>
                  {/* <li><a  onClick={this.mutualitySearchList.bind(this)} className="remove"><img src="../../images/mut.png"/> mutuality</a></li> */}
                  <li><a onClick={this.alphabeticallySearchList.bind(this)} className="remove"><img src="../../images/az.png" /> alphabetically</a></li>
                  <button onClick={this.openpop.bind(this)} className="upbtn remove">Cancel</button>
               </ul>
            </div>
         </div >
         //</div>

      );
   };
};



export default SecondTab;