import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { userListForLogin, usersListForGuets } from '../../Services/api';
import { showInitials } from '../../utils/profiles/shared.js';
import InfiniteScroll from "react-infinite-scroll-component";
import { getProfilesList } from '../../utils/profiles/manageProfilesList';
import { removeDuplicateObjectsFromArray } from '../../utils/arrayHelper';
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];

class SecondSideBar extends React.Component {
   constructor(props) {

      super(props);
      this.state = {
         record: [], static: "", token: false, newRecord: [], page: 2, hasMoreUser: true,hasNewRecords: false, entry: []
      }
      this.componentDidMount = this.componentDidMount.bind(this);

   }
   getLocalState = () => {
      const entry = localStorage.getItem("entry");
      if (this.state.hasNewRecords || (entry && JSON.parse(entry).length && JSON.stringify(this.state.entry) !== JSON.stringify(JSON.parse(entry)))) {
         const localStorageData = JSON.parse(entry);
         const updatedData = removeDuplicateObjectsFromArray([...localStorageData, ...this.state.newRecord]);
         this.setState({ record: updatedData, hasNewRecords: false, entry: localStorageData }, () => {
            //this.mainProfileColor();
         });
      }
   }
   componentDidMount() {


      // setTimeout(() => {
         // if( JSON.parse(localStorage.getItem("entry")) <= 0 | JSON.parse(localStorage.getItem("entry")) == null)
         // {
         // setTimeout(() => {
         // const token = localStorage.getItem('token');
         //  let url=this.state.baseUrl+'/api/group/dummyusers.php';

      //    if (token && token !== null) {
      //       url=this.state.baseUrl+'/api/group/user.php';
      //       this.setState({token:true});
      //       this.regeter();
      //    } else {
      //       this.nonregister();
      //    }
      //    fetch(url,{
      //    method: "GET",
      //    headers: {
      //    Accept: "application/json",
      //    "Content-Type": "application/json",
      //    "Authorization": "Bearer " + token,
      //    },     
      //    })
      //    .then((response) => response.json())
      //    .then((responseJson) =>{
      //    this.setState({record:responseJson.response},()=>
      //    {
      //    this.mainProfileColor();
      //    });
      //    localStorage.setItem("entry", JSON.stringify(responseJson.response));
      //    }).catch((error)=>{
      //    console.log(error);
      //    })
      //    }, 1500);
      //    }
      //    else{
      //    this.setState({record:JSON.parse(localStorage.getItem("entry"))},()=>
      //    {
      //    this.mainProfileColor();
      //    });
      //    } 
      // }, 2000);
      // document.addEventListener('mouseover', this.handleMouseOver);

      this.intervalLocalStaorageChange = setInterval(this.getLocalState, 100);
      getProfilesList().then((res) => {
         this.setState({ record: [...res], });
      })
      this.setState({ entry: JSON.parse(localStorage.getItem("entry")) });
   }

   handleMouseOver = (event) => {
      const targetElement = event.target;
      const elementRect = targetElement?.getBoundingClientRect();
      const elementY = elementRect?.top + window?.scrollY;
      const tooltip = targetElement?.querySelector(".profileInfo");
      const tooltip_p = targetElement?.querySelector(".profile");
      const tooltipRect = tooltip_p?.getBoundingClientRect();
      const tooltipX = window?.innerWidth - (tooltipRect?.left + window?.scrollX + tooltipRect?.width);
      if (tooltip) {
         const paddingY = window?.innerWidth < 767 ? 15 : 16;
         tooltip.style.top = `${elementY + targetElement?.offsetHeight - 1}px`;
         tooltip.style.right = `${0}px`;
         tooltip.style.padding = `${paddingY}px ${tooltipX}px ${paddingY}px 5px`;
      }
   };



   async regeter() {
      let data = await userListForLogin(1);
      this.setState({ record: data }, () => {
         //this.mainProfileColor();
      });
   }

   async nonregister() {
      let data = await usersListForGuets(1);
      this.setState({ record: data }, () => {
         //this.mainProfileColor();
      });
   }


   mainProfileColor() {
      for (let index = 0; index < this.state.record.length; index++) {
         var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
         $(".charactercolor2" + index).css("color", "");
         $(".charactercolor2" + index).css("color", random_color.textColor);
         $(".charactercolor2" + index).css("background-color", "");
         $(".charactercolor2" + index).css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
      }
   }

   moveVisitedProfileToStartOfRecords(user) {
      const data = [...this.state.record];
      data.unshift(user);

      // Remove duplicates based on a key
      const key = 'user_id';
      const arrayUniqueByKey = [
         ...new Map(
            data.map(item => [item[key], item])
         ).values()
      ];

      // Update local storage
      localStorage.setItem('entry', JSON.stringify(arrayUniqueByKey));

      // Update the state only if needed
      if (JSON.stringify(this.state.record) !== JSON.stringify(arrayUniqueByKey)) {
         this.setState({ record: arrayUniqueByKey }, () => {
            //this.mainProfileColor();
         });
      }
   }

   componentWillUnmount() {
      clearInterval(this.interval);
      clearInterval(this.intervalLocalStaorageChange);
   }

   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   //  setStorage()
   //  {
   //    localStorage.setItem("backid","front");
   //  }
   fetchMoreUserData = () => {
      this.fetchMoreUser(this.state.page);
   };
   async fetchMoreUser(page) {
      const token = localStorage.getItem('timeToken');
      if (token) {
         try {
            await userListForLogin(page).then((res) => {
               if (res.length > 0) {
                  this.setState((prevState) => ({
                     newRecord: [...prevState.newRecord, ...res],
                     page: page + 1,
                     hasNewRecords: true,
                  }), () => {
                     if (res.length < 256) {
                        this.setState({ hasMoreUser: false });
                     }
                  });
               } else {
                  this.setState({ hasMoreUser: false });
               }
            })
         } catch (error) {
            console.log("Error users fetching", error);
         }
      } else {
         try {
            await usersListForGuets(page).then((res) => {
               if (res.length > 0) {
                  this.setState((prevState) => ({
                     newRecord: [...prevState.newRecord, ...res],
                     page: page + 1,
                     hasNewRecords: true,
                  }), () => {
                     if (res.length < 256) {
                        this.setState({ hasMoreUser: false });
                     }
                  });
               } else {
                  this.setState({ hasMoreUser: false });
               }
            })
         } catch (error) {
            console.log("Error users fetching", error);
         }
      }
   }
   renderUserGroups = () => {
      const allUsers = this.state.record;
      const Allelements = [];
      const { click_detect } = this.props;
      const upperGroupSize = 24;
      const groupSize = 8;
      const elementsPerSubgroup = 4


      for (let g = 0; g < Math.ceil(allUsers.length / upperGroupSize); g++) {
         const users = allUsers.slice(g * upperGroupSize, (g + 1) * upperGroupSize);
         const userGroups = [];
         let subgroupSize;

         for (let i = 0; i < users.length; i += subgroupSize) {
            let higherLevelGroupClass = '';

            if (i === 0) {
               subgroupSize = groupSize;
               higherLevelGroupClass = 'firstGroupside';
            } else if (i == 16) {
               subgroupSize = groupSize;
               higherLevelGroupClass = 'lastGroupside';
            } else {
               subgroupSize = groupSize;
               higherLevelGroupClass = 'middleGroupside';
            }

            const group = users.slice(i, i + subgroupSize);
            const parentDivs = [];

            for (let j = 0; j < group.length; j += elementsPerSubgroup) {
               const subgroup = group.slice(j, j + elementsPerSubgroup);
               const parentDiv = (
                  <div className={`parentDivside group-${i / elementsPerSubgroup}`} key={i + j}>
                     {subgroup.map((p, k) => (
                        <li key={k}><a className="link" onMouseOver={this.handleMouseOver} onClick={() => {
                           click_detect(p.user_id);
                           this.moveVisitedProfileToStartOfRecords(p);
                        }}
                        >
                           {/* {p.profileImage != "" ? <img src={this.state.baseUrl + p.profileImage} align="images" /> : "" */}
                              {/* // <span className={"charactercolor2"+i}>
                              //    <div className="profile_initials">
                              //       {this.fetchmakeprofile(p.screenName)}
                              //    </div></span>  */}
                           {/* } */}
                           <p className='profile'>{showInitials(p.screenName)}

                              <p className='profileInfo' data-fullname={p.screenName}>{p.screenName}</p>
                           </p></a></li>


                     ))}
                  </div>
               );

               parentDivs.push(parentDiv);
            }

            userGroups.push(
               <div className={`higherLevelGroup ${higherLevelGroupClass}`} key={i / 32}>
                  {parentDivs}
               </div>
            );
            // console.log({ higherLevelGroupClass, isLastGroup, parentDivs, userGroups });
         }
         Allelements.push(
            <div className='rowBlock'>
               {userGroups}
            </div>
         );
      }
      return Allelements;
   };
   render() {
      return (

         <div className="sidebar sidebar2">
            <ul id = "scrollableSidebarRight">
               <InfiniteScroll
                  dataLength={this.state.record.length}
                  next={this.fetchMoreUserData}
                  hasMore={this.state.hasMoreUser}
                  loader={<div className="userlistloader"><img src="../../images/newgif.gif" alt="loading" /></div>}
                  scrollableTarget="scrollableSidebarRight">
                  {
                     this.renderUserGroups()
                  }
               </InfiniteScroll>
               {/* {this.state.record ? this.state.record.map((p, i) => {
              if(i<30)
              {
           return(
<li><a class="link"  onClick={()=>click_detect(p.user_id)}
               >{p.profileImage!="" ? <img src={this.state.baseUrl+p.profileImage} align="images"/> : <span className={"charactercolor2"+i}>
                  <div className="profile_initials"> */}
               {/* {this.fetchmakeprofile(p.screenName)} */}
               {/* </div></span> }<p>{p.screenName}</p></a></li>
         ) }})
            
 : ""} */}
            </ul>
         </div>




      );
   };
};

export default SecondSideBar;