import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [logoutPopupActive, setLogoutPopupActive] = useState(false);
  const [homePageActive, setHomePageActive] = useState({status: false, activeHomePageSubspace: null});
  const [folderData, setFolderData] = useState({})

  const updateData = (newData) => {
    setData(newData);
  };

  const contextValue = {
    state: {
      data,logoutPopupActive, homePageActive, folderData
    },
    actions: { 
      updateData,setLogoutPopupActive, setHomePageActive, setFolderData
    },
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export { AppProvider, useAppContext };