import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AddCommands = ({ editCommand, setShowForm, isEditCommand, handleRefetchCommands }) => {
    const [name, setName] = useState('');
    const [command, setCommand] = useState('');
    const [error, setError] = useState('');
    const headers = {
        Accept: "application/json",
        'Content-Type': 'application/json',
    }

    useEffect(() => {
        if (editCommand) {
            setName(editCommand?.name);
            setCommand(editCommand?.command);
        }
    }, [editCommand])

    const handleAddCommand = async (e) => {
        e.preventDefault();

        if (name === '' || command === '') {
            setError('Provide name and command correctly');
            return
        }

        const url = `${process.env.REACT_APP_BASE_URL_NODE}/api/commands`;
        const data = {
            name: name,
            command: command
        }
        try {
            await axios.post(url, data, { headers }).then((response) => {
                if (response.data.status) {
                    setName('');
                    setCommand('');
                    setShowForm(false);
                    handleRefetchCommands(true);
                }
            })
        } catch (error) {
            console.log("Add Command Error:", error);
            setError("Request failed with status code 500");
        }
    }

    const handleEditCommand = async (e, id) => {
        e.preventDefault();
        const url = `${process.env.REACT_APP_BASE_URL_NODE}/api/commands/${id}/`;
        const data = {
            name: name,
            command: command
        }

        try {
            await axios.patch(url, data).then((response) => {
                if (response.data.status) {
                    setName('');
                    setCommand('');
                    setShowForm(false);
                    handleRefetchCommands(true);
                }
            })
        } catch (error) {
            console.log("Edit Command Error:", error);
            setError("Request failed with status code 500");
        }
    }

    return (
        <>
            <div className='commandForm'>
                <div className='coverBlock' onClick={() => setShowForm(false)}></div>
                <div className='formBlock'>
                    <h2>{isEditCommand ? 'Edit Command' : 'Add Command'}</h2>
                    <form>
                        <div className="inputGroup">
                            <label htmlFor="name">Name:</label>
                            <input
                                spellCheck="false"
                                id="name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Name"
                            />
                        </div>

                        <div className="inputGroup">
                            <label htmlFor="name">Command:</label>
                            <textarea
                                spellCheck="false"
                                id="command"
                                name="command"
                                value={command}
                                onChange={(e) => setCommand(e.target.value)}
                                placeholder="Command"
                            />
                        </div>

                        <button onClick={(e) => isEditCommand ? handleEditCommand(e, editCommand?._id) : handleAddCommand(e)} className='saveBtn'>{isEditCommand ? 'Edit' : 'Save'}</button>
                    </form>
                    <p className='error'>{error}</p>
                </div>
            </div>
        </>
    );
};

export default AddCommands;
