export function getTabWithMostOpenProfilesInStack(tabWiseData) {
   const tabs = Object.keys(tabWiseData)
   const values = Object.values(tabWiseData)
   const isConsumer = localStorage.getItem('profileType') == 'consumer'
   const tabRomanNumberMap = {
      'first': 'i',
      'second': isConsumer ? 'i' : 'ii',
      'third': isConsumer ? 'ii' : 'iii',
   }
   const numberTextMap = {
      1: 'one',
      2: 'two',
      3: 'three'
   }

   let tab = ''
   let openProfilesInStack = 0
   values.forEach((data, ind) => {
      if (data?.openProfiles?.length > openProfilesInStack) {
         openProfilesInStack = data.openProfiles.length
         tab = tabs[ind]
      }
   })

   return { tab: tabRomanNumberMap[tab], openProfilesInStack: numberTextMap[openProfilesInStack] }
}

export function getTabwiseOpenProfileScreenNames(tabWiseData) {
   if (!tabWiseData || !localStorage.getItem("entry"))
      return

   const twd = JSON.parse(JSON.stringify(tabWiseData))
   const tabs = Object.keys(twd)
   const tabData = Object.values(twd)

   let userList = Array.from(JSON.parse(localStorage.getItem("entry")))
   let tabwiseScreenNames = {}
   
   tabs.forEach((tab) => {
      let data = twd[tab]?.openProfiles
      if (data?.length) {
         let key = "username"
         if (typeof data[0] !== "string")
            key = "user_id"

         tabwiseScreenNames[tab] = data.reverse().map((keyVal) => {
            const user = userList.find(user => user[key] === (key === "username" ? keyVal.substring(1) : keyVal));
            return {
               screenName: user?.screenName,
               username: key === "username" ? keyVal.substring(1) : user?.username,
            };
        });
      }
   })
   return tabwiseScreenNames
}