import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import { Button } from 'reactstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from 'react-number-format';
import Footer from '../Footer';
import Notificationhelper from '../Helper/Notificationhelper.js';
import Readnotification from '../Helper/Readnotification.js';
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class Notifications extends React.Component {
   constructor(props) {
      super(props);
      const notified = Notificationhelper();
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, followrequestSearch: [], checklist: [], parpage3: 1, block3: "", item3: Array.from({ length: 20 }),
         activecolor: 'page', number: 0, perpage: 1, firstlist: [], block: "", block5: "", block4: "", block2: "", searchreqval: "", searchreqvalUser: "", perpage4: 1, followrequestUserSearch: [], items4: Array.from({ length: 20 }),
         items: Array.from({ length: 30 }), hasMore: true, loading: "", perpage2: 1, followrequest: [], items2: Array.from({ length: 20 }), perpage5: 1, followrequestUser: [], items5: Array.from({ length: 20 })
      }
   }
   componentDidMount() {
      localStorage.setItem("footer", "noti");
      if (localStorage.getItem("noti") == "req") {
         this.secondtab();
      }
      if (localStorage.getItem("noti") == "reqnot") {
         this.thiredtab();
      }
      this.reqcountt();
      this.firsttimelist();
      this.fetchUser();
      this.fetchrequest();
   }
   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   mainProfileColor() {
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".username").css("color", "");
      $(".username").css("color", random_color.textColor);
      $(".username").css("background-color", "");
      $(".username").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   fetchUser() {
      $('.loaderclass').removeClass('closeCd');
      const token = localStorage.getItem('token');
      this.setState({ activecolor: 'page', perpage5: 1, followrequestUser: [], items5: Array.from({ length: 20 }), hasMore: true });
      var pagepro = 1;
      fetch(this.state.baseUrl + '/api/follow/getUsersForFollow.php?page=' + pagepro + '&limit=20', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "TimeZone": moment.tz.guess(),
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({ followrequestUser: responseJson.response, loading: "false" }, () => {
               this.mainProfileColor();
            });
         });
   }
   fetchmorereqUser() {
      this.setState({ activecolor: 'first', perpage5: this.state.perpage5 + 1 }, () => {
         const token = localStorage.getItem('token');
         if (this.state.block5 == "block") {
            return;
         }
         fetch(this.state.baseUrl + '/api/follow/getUsersForFollow.php?page=' + this.state.perpage5 + '&limit=20', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response.length > 9) {
                  $('.loaderclass').addClass('closeCd');
                  this.setState({ block2: "block" });
               }
               if (responseJson.response.length > 0) {
                  for (var i = responseJson.response.length - 1; i >= 0; i--) {
                     this.state.followrequestUser.push(responseJson.response[i]);
                  }
                  this.setState({ followrequestUser: this.state.followrequestUser });
               }
               else {
                  this.setState({ block5: "block" });
               }
            });
         this.setState({
            items5: this.state.items5.concat(Array.from({ length: 20 }))
            , loading: "false"
         });
      });
   }
   reqcountt() {
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/user/getUserDetails.php?user_id=' + localStorage.getItem('userid'), {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({ number: responseJson.response.request });
         })
   }
   goview(e) {
      localStorage.setItem("noti", "notreq");
      this.props.history.push({
         pathname: `/SinglePage/${e.currentTarget.dataset.div_event}`,
         state: { back: "front", user: "other" }
      });
      localStorage.setItem("backid", "front");
   }
   goprofile(e) {
      localStorage.setItem("noti", "notreq");
      this.props.history.push({ pathname: `Users/${e.currentTarget.id}` });
      localStorage.setItem("backid", "front");
   }
   accept(e) {
      var pro = e.currentTarget.id;
      const token = localStorage.getItem('token');
      const body = { user_id: e.currentTarget.id, isAccept: 1 };
      axios.post(this.state.baseUrl + '/api/follow/followRequestResponse.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            if (res.data.success == true) {
               if (this.state.followrequestSearch.length > 0) {
                  var objIndex = this.state.followrequestSearch.findIndex((obj => obj.user_id == pro));
                  if (objIndex !== -1) {
                     this.state.followrequestSearch.splice(objIndex, 1);
                     this.setState({ followrequestSearch: this.state.followrequestSearch });
                  }
               }
               else {
                  var objIndex = this.state.followrequest.findIndex((obj => obj.user_id == pro));
                  if (objIndex !== -1) {
                     this.state.followrequest.splice(objIndex, 1);
                     this.setState({ followrequest: this.state.followrequest }, () => {
                        this.mainProfileColor();
                     });
                  }
               }
               this.setState({ number: res.data.response.length });
               //   if(localStorage.getItem("number") && localStorage.getItem("number")!=null)
               //   {
               //    var total=localStorage.getItem("number")+1;
               //    this.setState({number:this.state.number-total});
               //    localStorage.setItem('number',total);
               //   }
               //   else
               //   {
               //    localStorage.setItem('number',1);
               //    this.setState({number:this.state.number-1});
               //   }
            }
         });
   }
   delete(e) {
      var pro = e.currentTarget.id;
      const token = localStorage.getItem('token');
      const body = { user_id: e.currentTarget.id, isAccept: 0 };
      axios.post(this.state.baseUrl + '/api/follow/followRequestResponse.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            if (res.data.success == true) {
               console.log('delete res', res);
               if (this.state.followrequestSearch.length > 0) {
                  var objIndex = this.state.followrequestSearch.findIndex((obj => obj.user_id == pro));
                  if (objIndex !== -1) {
                     this.state.followrequestSearch.splice(objIndex, 1);
                     this.setState({ followrequestSearch: this.state.followrequestSearch });
                  }
               }
               else {
                  var objIndex = this.state.followrequest.findIndex((obj => obj.user_id == pro));
                  if (objIndex !== -1) {
                     this.state.followrequest.splice(objIndex, 1);
                     this.setState({ followrequest: this.state.followrequest }, () => {
                        this.mainProfileColor();
                     });
                  }
               }
               this.setState({ number: res.data.response.length });
               //   if(localStorage.getItem("number") && localStorage.getItem("number")!=null)
               //   {
               //    var total=localStorage.getItem("number")+1;
               //    this.setState({number:this.state.number-total});
               //    localStorage.setItem('number',total);
               //   }
               //   else
               //   {
               //    localStorage.setItem('number',1);
               //    this.setState({number:this.state.number-1});
               //   }
            }
         });
   }
   firsttimelist = async () => {
      $('.loaderclass').removeClass('closeCd');
      const token = localStorage.getItem('token');
      this.setState({ activecolor: 'page', perpage: 1, firstlist: [], items: Array.from({ length: 30 }), hasMore: true });
      try {
         const notified = await Notificationhelper();
         if (notified.length > 0) {
            this.setState({ firstlist: notified, loading: "false" }, () => {
               this.anotherfunction();
               this.readfunction();
               this.mainProfileColor();

            });
         }
         else {
            $('.loaderclass').addClass('closeCd');
         }
      }
      catch (e) {

      }
   }
   anotherfunction = async () => {
      try {
         const notified = await Notificationhelper();
         this.setState({ checklist: notified });
      }
      catch (e) {

      }
   }
   componentWillUnmount() {
      clearInterval(this.interval);
   }
   newfunction = async () => {
      try {
         var notified = await Notificationhelper();
         if (JSON.stringify(this.state.checklist) !== JSON.stringify(notified)) {
            this.setState({ checklist: notified, firstlist: notified }, () => {
               this.mainProfileColor();
            });
            try {
               var success = await Readnotification();
            }
            catch (e) {
            }
         }
      }
      catch (e) {
      }
   }
   readfunction = async () => {
      try {
         var success = await Readnotification();
      }
      catch (e) {
      }
      //this.interval = setInterval(() => this.newfunction(), 3000);
   }
   fetchrequest() {
      $('.loaderclass').removeClass('closeCd');
      const token = localStorage.getItem('token');
      this.setState({ activecolor: 'page', perpage2: 1, followrequest: [], items2: Array.from({ length: 20 }), hasMore: true });
      var pagepro = 1;
      fetch(this.state.baseUrl + '/api/follow/getFollowRequest.php?page=' + pagepro + '&limit=20', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "TimeZone": moment.tz.guess(),
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({ followrequest: responseJson.response, loading: "false" }, () => {
               this.mainProfileColor();
            });
         });
   }
   fetchmorefirst() {
      setTimeout(() => {
         this.setState({ activecolor: 'first', perpage: this.state.perpage + 1 }, () => {
            const token = localStorage.getItem('token');
            if (this.state.block == "block") {
               $('.loaderclass').addClass('closeCd');

               return;
            }
            fetch(this.state.baseUrl + '/api/notification/getNotification.php?page=' + this.state.perpage + '&limit=30', {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {
                  if (responseJson.response.length < 30) {
                     $('.loaderclass').addClass('closeCd');
                     this.setState({ block: "block" });
                  }
                  if (responseJson.response.length > 0) {
                     for (var i = responseJson.response.length - 1; i >= 0; i--) {
                        this.state.firstlist.push(responseJson.response[i]);
                     }
                     this.setState({ firstlist: this.state.firstlist }, () => {
                        this.mainProfileColor();
                     });
                  }
                  else {
                     this.setState({ block: "block" });
                  }
               });
            this.setState({
               items: this.state.items.concat(Array.from({ length: 30 }))
               , loading: "false"
            });
         });
      }, 2000);
   }
   removereqtext() {
      document.getElementById("searchinput").value = "";
      this.setState({ followrequestSearch: [] });
      this.setState({ searchreqval: "" });
   }
   removereqtextUser() {
      document.getElementById("searchUser").value = "";
      this.setState({ followrequestUserSearch: [] });
      this.setState({ searchreqvalUser: "" });
   }
   searchreq(event) {
      this.setState({ searchreqval: event.target.value }, () => {
         const token = localStorage.getItem('token');
         this.setState({ activecolor: 'page', perpage3: 1, followrequestSearch: [], items3: Array.from({ length: 30 }), hasMore: true });
         var pagepro = 1;
         fetch(this.state.baseUrl + '/api/follow/requestedUserSearch.php?page=' + pagepro + '&search=' + this.state.searchreqval + '&limit=20', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "TimeZone": moment.tz.guess(),
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               this.setState({ followrequestSearch: responseJson.response, loading: "false" });
            });
      })

   }
   searchreqUser(event) {

      this.setState({ searchreqvalUser: event.target.value }, () => {
         const token = localStorage.getItem('token');
         this.setState({ activecolor: 'page', perpage4: 1, followrequestUserSearch: [], items4: Array.from({ length: 20 }), hasMore: true });
         var pagepro = 1;
         fetch(this.state.baseUrl + '/api/follow/suggestedUserSearch.php?page=' + pagepro + '&search=' + this.state.searchreqvalUser + '&limit=20', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "TimeZone": moment.tz.guess(),
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               this.setState({ followrequestUserSearch: responseJson.response, loading: "false" });
            });
      })
   }
   followrequestUserSearch2() {
      this.setState({ activecolor: 'first', perpage4: this.state.perpage4 + 1 }, () => {
         const token = localStorage.getItem('token');
         if (this.state.block4 == "block") {
            $('.loaderclass').addClass('closeCd');

            return;
         }
         $('.loaderclass').removeClass('closeCd');
         fetch(this.state.baseUrl + '/api/follow/suggestedUserSearch.php?page=' + this.state.perpage4 + '&search=' + this.state.searchreqvalUser + '&limit=20', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response.length > 0) {
                  for (var i = responseJson.response.length - 1; i >= 0; i--) {
                     this.state.followrequestUserSearch.push(responseJson.response[i]);
                  }
                  this.setState({ followrequestUserSearch: this.state.followrequestUserSearch });
               }
               else {
                  this.setState({ block4: "block" });
               }
            });
         this.setState({
            items4: this.state.items4.concat(Array.from({ length: 20 }))
            , loading: "false"
         });
      });
   }
   followrequestSearch2() {
      setTimeout(() => {
         this.setState({ activecolor: 'first', perpage3: this.state.perpage3 + 1 }, () => {
            const token = localStorage.getItem('token');
            if (this.state.block3 == "block") {
               $('.loaderclass').addClass('closeCd');

               return;
            }
            fetch(this.state.baseUrl + '/api/follow/requestedUserSearch.php?page=' + this.state.perpage3 + '&search=' + this.state.searchreqval + '&limit=20', {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {
                  if (responseJson.response.length > 0) {
                     for (var i = responseJson.response.length - 1; i >= 0; i--) {
                        this.state.followrequestSearch.push(responseJson.response[i]);
                     }
                     this.setState({ followrequestSearch: this.state.followrequestSearch });
                  }
                  else {
                     this.setState({ block3: "block" });
                  }
               });
            this.setState({
               items3: this.state.items3.concat(Array.from({ length: 20 }))
               , loading: "false"
            });
         });
      }, 2000);
   }

   fetchmorereq() {
      setTimeout(() => {
         this.setState({ activecolor: 'first', perpage2: this.state.perpage2 + 1 }, () => {
            const token = localStorage.getItem('token');
            if (this.state.block2 == "block") {
               this.setState({ hasMore: false });
               return;
            }
            fetch(this.state.baseUrl + '/api/follow/getFollowRequest.php?page=' + this.state.perpage2 + '&limit=20', {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {
                  if (responseJson.response.length > 9) {
                     $('.loaderclass').addClass('closeCd');
                     this.setState({ hasMore: false, block2: "block" });
                  }
                  if (responseJson.response.length > 0) {
                     for (var i = responseJson.response.length - 1; i >= 0; i--) {
                        this.state.followrequest.push(responseJson.response[i]);
                     }
                     this.setState({ followrequest: this.state.followrequest }, () => {
                        this.mainProfileColor();
                     });
                  }
                  else {
                     this.setState({ block2: "block" });
                  }
               });
            this.setState({
               items2: this.state.items2.concat(Array.from({ length: 20 }))
               , loading: "false"
            });
         });
      }, 2000);
   }


   weekDays = (dayNumber) => {
      if (dayNumber == 0) {
         return "Sunday";
      }
      if (dayNumber == 6) {
         return "Saturday";
      }
      if (dayNumber == 5) {
         return "Friday";
      }
      if (dayNumber == 4) {
         return "Thursday";
      }
      if (dayNumber == 3) {
         return "Wednesday";
      }
      if (dayNumber == 2) {
         return "Tuesday";
      }
      if (dayNumber == 1) {
         return "Monday";
      }
   }
   getdateformat(datedata) {
      if (datedata) {
         var postedDate = moment.unix(datedata).format("YYYY-MM-DD");
         var currentDate = moment(new Date()).format("YYYY-MM-DD");
         var eventDate = datedata;
         var date = "";
         var duration = moment.duration(moment(postedDate).diff(moment(currentDate)));
         var daysDifference = duration.asDays();
         if (daysDifference <= -8 || daysDifference > 7) {
            const day = moment.unix(eventDate).format("D");
            const month = moment.unix(eventDate).format("MMM");
            const time = moment.unix(eventDate).format("h:mma");
            var lasttag = "";
            switch (day % 20) {
               case 1:
                  if (day != 11) {
                     lasttag = "st";
                  } else {
                     lasttag = "th";
                  }
                  break;
               case 2:
                  if (day != 12) {
                     lasttag = "nd";
                  } else {
                     lasttag = "th";
                  }
                  break;
               case 3:
                  if (day != 13) {
                     lasttag = "rd";
                  } else {
                     lasttag = "th";
                  }
                  break;
               default: lasttag = "th";
                  break;
            }
            date = `${month} ${day}${lasttag}, ${time}`;
            return date;
         }
         if (daysDifference < -1 && daysDifference > -8) {
            date = "last" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference < 0 && daysDifference >= -1) {
            date = "yesterday at " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 0 && daysDifference < 1) {
            date = moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 1 && daysDifference < 2) {
            date = "tomorrow at " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 2 && daysDifference < 7) {
            date = "this" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference == 7) {
            date = "next" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
      }
   }
   weekforNext() {
      if (this.state.firstlist.length > 29) {
         return "loaderclass load";
      }
      else {
         return "loaderclass";
      }
   }
   weekforNext2() {
      if (this.state.followrequest.length > 19 | this.state.followrequestSearch.length > 19) {
         return "loaderclass load";
      }
      else {
         return "loaderclass";
      }
   }
   weekforNext3() {
      if (this.state.followrequestUser.length > 19 | this.state.followrequestUserSearch.length > 19) {
         return "loaderclass load";
      }
      else {
         return "loaderclass";
      }
   }
   commentfilter(valuess) {
      var regex = /(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/ig;
      const text = valuess;
      const replaced2 = text.replace(regex, value => `<a href=${"https://" + value} class='hashtag_link' target='_blank'>${value}</a>`);
      var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig
      var replace7 = replaced2.replace(mention_preg, value => `<a class=${value.substring(1)}>${value}</a>`);
      return (<span dangerouslySetInnerHTML={{ __html: replace7 }}></span>);

   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   firsttab() {
      $('#f2').removeClass('active');
      $('#f3').removeClass('active');
      $('#onefollow').removeClass('active show');
      $('#onesuggested').removeClass('active show');
      $('#f1').addClass('active');
      $('#onenotifications').addClass('active show');
   }
   secondtab() {
      $('#f1').removeClass('active');
      $('#f3').removeClass('active');
      $('#onenotifications').removeClass('active show');
      $('#onesuggested').removeClass('active show');
      $('#f2').addClass('active');
      $('#onefollow').addClass('active show');
      if (this.state.followrequest.length == []) {
         this.fetchrequest();
      }
   }
   gocommentOn(e) {
      localStorage.setItem("backid", "front");
      localStorage.setItem("noti", "notreq");
      this.props.history.push({
         pathname: `/CommentOn/${e.currentTarget.dataset.div_commentid}`,
         state: {
            m_route: "other",
            eventid: e.currentTarget.dataset.div_eventid, username: e.currentTarget.dataset.div_username,
            commentid: e.currentTarget.dataset.div_commentid, userid: e.currentTarget.dataset.div_userid, comment: e.currentTarget.dataset.div_comment, islike: e.currentTarget.dataset.div_isliked,
            name: e.currentTarget.dataset.div_name, image: e.currentTarget.dataset.div_image, likes: e.currentTarget.dataset.div_likescount, commentCount: e.currentTarget.dataset.div_chieldcount
         }
      });
   }

   thiredtab() {
      $('#f1').removeClass('active');
      $('#f2').removeClass('active');
      $('#onenotifications').removeClass('active show');
      $('#onefollow').removeClass('active show');
      $('#f3').addClass('active');
      $('#onesuggested').addClass('active show');
      if (this.state.followrequestUser == []) {
         this.fetchUser();
      }
   }
   setStorage2() {
      localStorage.setItem("backid", "front");
      localStorage.setItem("noti", "req");
   }
   setStorage3() {
      localStorage.setItem("backid", "front");
      localStorage.setItem("noti", "reqnot");
   }
   followuser = (e) => {

      if (this.state.followrequestUserSearch.length > 0) {
         var objIndex = this.state.followrequestUserSearch.findIndex((obj => obj.user_id == e.currentTarget.id.split("/")[0]));
         if (objIndex !== -1) {
            this.state.followrequestUserSearch.splice(objIndex, 1);
            this.setState({ followrequestUserSearch: this.state.followrequestUserSearch })
         }
      }
      else {
         var objIndex = this.state.followrequestUser.findIndex((obj => obj.user_id == e.currentTarget.id.split("/")[0]));
         if (objIndex !== -1) {
            this.state.followrequestUser.splice(objIndex, 1);
            this.setState({ followrequestUser: this.state.followrequestUser })
         }
      }
      if (e.currentTarget.id.split("/")[1] == 1) {
         var body = {
            user_id: e.currentTarget.id.split("/")[0],
            isRequest: 1
         };
      }
      else {
         var body = {
            user_id: e.currentTarget.id.split("/")[0],
            isRequest: 0
         };
      }
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/follow/sendFollowRequest.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {

         })
         .catch((error) => {
            this.setState({
               error: error.message
            });
         })
   }
   render() {
      return (
         //   <div className="newscreen1 newscreen01">
         // <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}  id="one_5">
         <div className={`${this.props.pos} screen1`} id="one_5">
            <div className="top" id="myHeader">
               <section className="header headernoti">
                  <ul className="nav nav-tabs tabshead">
                     <li><a id="f1" className="active" onClick={this.firsttab.bind(this)} data-toggle="tab" >notifications </a></li>
                     {/* <li><a id="f2" data-toggle="tab" onClick={this.secondtab.bind(this)} >{this.state.number>0 ? 'follow requests ('+this.state.number+')': 'follow requests'}</a></li>
            <li><a id="f3" data-toggle="tab" onClick={this.thiredtab.bind(this)}>suggested users</a></li> */}
                  </ul>
               </section>
            </div>
            <div className="tab-content notificationslist">
               <div id="onenotifications" className="tab-pane fade in active show">
                  <div className="row px-0 mx-0">

                     <div
                        id="scrollableDivNoti"
                        className="scrollClass newscreen1"
                        style={{
                           overflow: 'auto',
                           display: 'row',
                           flexDirection: 'row',
                           margintop: 47,
                        }}
                     >

                        {this.state.firstlist.length > 0 ?
                           <InfiniteScroll
                              dataLength={this.state.items.length}
                              next={this.fetchmorefirst.bind(this)}
                              style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                              inverse={false} //
                              hasMore={true}
                              loader={
                                 <div className={this.weekforNext()}>
                                    <img
                                       src="../../images/newgif.gif"
                                       alt="loading"
                                    />
                                 </div>}

                              endMessage={
                                 <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                 </p>
                              }
                              useWindow={false}
                              scrollableTarget="scrollableDivNoti"
                           >
                              {this.state.firstlist.map((c, i) => {

                                 return (

                                    <div className="kunal">
                                       {(() => {
                                          if (c.type == "mention" | c.type == "commentmention") {
                                             return (<div className="userin">
                                                {c.profileImage ? <img id={c.username} onClick={this.goprofile.bind(this)} className="u_img" src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username" id={c.username} onClick={this.goprofile.bind(this)}>{this.fetchmakeprofile(c.screenName)}</span>}
                                                <h6 className={c.comment ? "nodiffrent" : "diffrentsix"}>
                                                   <p id={c.username} onClick={this.goprofile.bind(this)}>{c.screenName}</p>
                                                   <span data-div_event={c.comment ? c.comment.commentOn[0].eventID : c.event.eventID} onClick={this.goview.bind(this)} >tagged you in</span>{c.comment ?
                                                      <span data-div_event={c.comment ? c.comment.commentOn[0].eventID : c.event.eventID} onClick={this.goview.bind(this)}><a>{" " + c.comment.commentOn[0].screenName.split(" ")[0] + "'s post"}</a>
                                                         <div className="peragraph">
                                                            <span>{this.commentfilter(c.comment.comment)}</span>
                                                         </div></span> :
                                                      <a data-div_event={c.comment ? c.comment.commentOn[0].eventID : c.event.eventID} onClick={this.goview.bind(this)} >{" " + c.event.screenName.split(" ")[0] + "'s post"}</a>}
                                                </h6>
                                             </div>)
                                          }
                                          if (c.type == "follow") {
                                             return (<div id={c.username} onClick={this.goprofile.bind(this)} className="userin">
                                                {c.profileImage ? <img id={c.username} onClick={this.goprofile.bind(this)} className="u_img" src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username" id={c.username} onClick={this.goprofile.bind(this)}>{this.fetchmakeprofile(c.screenName)}</span>}
                                                <h6 className="diffrentsix">
                                                   <p>{c.screenName}</p>
                                                   <span>followed you</span><a></a>
                                                </h6>
                                             </div>)
                                          }
                                          if (c.type == "commentlike") {
                                             return (<div className="userin">
                                                {c.profileImage ? <img id={c.username} onClick={this.goprofile.bind(this)} className="u_img" src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username" id={c.username} onClick={this.goprofile.bind(this)}>{this.fetchmakeprofile(c.screenName)}</span>}
                                                <h6>
                                                   <p id={c.username} onClick={this.goprofile.bind(this)}>{c.screenName}</p>
                                                   <span data-div_event={c.comment.commentOn[0].eventID} onClick={this.goview.bind(this)} >liked your comment on</span>{c.comment ?
                                                      <span data-div_event={c.comment.commentOn[0].eventID} onClick={this.goview.bind(this)} ><a>{" " + c.comment.commentOn[0].screenName.split(" ")[0] + "'s post"} </a>
                                                         <div className="peragraph">
                                                            <span>{this.commentfilter(c.comment.comment)}</span>
                                                         </div></span> : ""
                                                   }
                                                </h6>
                                             </div>)
                                          }
                                          if (c.type == "like") {
                                             return (<div className="userin" data-div_event={c.event.eventID} >
                                                {c.profileImage ? <img id={c.username} onClick={this.goprofile.bind(this)} className="u_img" src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username" id={c.username} onClick={this.goprofile.bind(this)}>{this.fetchmakeprofile(c.screenName)}</span>}
                                                <h6 className="diffrentsix">
                                                   <p id={c.username} onClick={this.goprofile.bind(this)}>{c.screenName}</p>
                                                   <span data-div_event={c.event.eventID} onClick={this.goview.bind(this)} >liked your post for</span>{c.event ?
                                                      <a data-div_event={c.event.eventID} onClick={this.goview.bind(this)}>{" " + this.getdateformat(c.event.postedOn)}</a> : ""
                                                   }
                                                </h6>
                                             </div>)
                                          }
                                          if (c.type == "request") {
                                             return (<div id={c.username} onClick={this.goprofile.bind(this)} className="userin">
                                                {c.profileImage ? <img className="u_img" src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username">{this.fetchmakeprofile(c.screenName)}</span>}
                                                <h6 className="diffrentsix">
                                                   <p>{c.screenName}</p>
                                                   <span>requested to follow you</span>
                                                </h6>
                                             </div>)
                                          }
                                          if (c.type == "accept") {
                                             return (<div className="userin" id={c.username} onClick={this.goprofile.bind(this)}>
                                                {c.profileImage ? <img className="u_img" src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username" >{this.fetchmakeprofile(c.screenName)}</span>}
                                                <h6 className="diffrentsix" >
                                                   <p>{c.screenName}</p>
                                                   <span>accepted your follow request</span>
                                                </h6>
                                             </div>)
                                          }
                                          if (c.type == "withwho") {
                                             return (<div className="userin" data-div_event={c.event.eventID} >
                                                {c.profileImage ? <img className="u_img" id={c.username} onClick={this.goprofile.bind(this)} src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username" id={c.username} onClick={this.goprofile.bind(this)}>{this.fetchmakeprofile(c.screenName)}</span>}
                                                <h6 className="diffrentsix">
                                                   <p id={c.username} onClick={this.goprofile.bind(this)} >{c.screenName}</p>
                                                   <span data-div_event={c.event.eventID} onClick={this.goview.bind(this)}>will be doing something with you</span>
                                                   {c.event ?
                                                      <a data-div_event={c.event.eventID} onClick={this.goview.bind(this)} >{" " + this.getdateformat(c.event.postedOn)}</a> : ""
                                                   }
                                                </h6>
                                             </div>)
                                          }
                                          if (c.type == "comment") {
                                             return (<div className="userin" data-div_event={c.event.eventID} >

                                                {c.profileImage ? <img className="u_img" id={c.username} onClick={this.goprofile.bind(this)} src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username" id={c.username} onClick={this.goprofile.bind(this)}>{this.fetchmakeprofile(c.screenName)}</span>}
                                                <h6 className="diffrentsix">
                                                   <p id={c.username} onClick={this.goprofile.bind(this)}>{c.screenName}</p>
                                                   <span data-div_event={c.event.eventID} onClick={this.goview.bind(this)}>commented on your post for</span>
                                                   {c.event ?
                                                      <a data-div_event={c.event.eventID} onClick={this.goview.bind(this)}>{" " + this.getdateformat(c.event.postedOn)}</a> : ""
                                                   }
                                                </h6>
                                             </div>)
                                          }
                                          if (c.type == "commentreply") {
                                             return (<div className="userin" >
                                                {c.profileImage ? <img className="u_img" id={c.username} onClick={this.goprofile.bind(this)} src={this.state.baseUrl + c.profileImage} align="images" /> : <span className="username" id={c.username} onClick={this.goprofile.bind(this)}>{this.fetchmakeprofile(c.screenName)}</span>}
                                                <h6>
                                                   <p id={c.username} onClick={this.goprofile.bind(this)}>{c.screenName}</p>
                                                   <span onClick={this.gocommentOn.bind(this)} data-div_chieldcount={c.comment.chieldCount} data-div_likescount={c.comment.likesCount} data-div_isliked={c.comment.isLiked} data-div_name={c.comment.screenName} data-div_image={c.comment.profileImage} data-div_comment={c.comment.comment} data-div_userid={c.comment.user_id} data-div_commentid={c.comment.commentID} data-div_username={c.comment.username} data-div_eventid={c.comment.commentOn[0].eventID}>commented on your comment for</span>
                                                   {c.comment ?
                                                      <span onClick={this.gocommentOn.bind(this)} data-div_chieldcount={c.comment.chieldCount} data-div_likescount={c.comment.likesCount} data-div_isliked={c.comment.isLiked} data-div_name={c.comment.screenName} data-div_image={c.comment.profileImage} data-div_comment={c.comment.comment} data-div_userid={c.comment.user_id} data-div_commentid={c.comment.commentID} data-div_username={c.comment.username} data-div_eventid={c.comment.commentOn[0].eventID}><a>{" " + c.comment.commentOn[0].screenName.split(" ")[0] + "'s post"} </a>
                                                         <div className="peragraph">
                                                            <span>{this.commentfilter(c.comment.comment)}</span>
                                                         </div></span> : ""
                                                   }
                                                </h6>
                                             </div>)
                                          }
                                       })()}
                                    </div>
                                 )
                              })}
                           </InfiniteScroll> :
                           <div className="blankcalendar blankcalendar2">
                              <div className="testc"><p>{'Notifications will show up here when other interact with you, ' + localStorage.getItem('screenname').split(" ")[0]}</p></div>
                              <span className="bell"><i class="fas fa-bell"></i></span>
                           </div>
                        }


                     </div>

                  </div>
               </div>
               {/* <div id="onefollow" className="followrequests tab-pane fade">
         <div className="header_search ">
            <form className="form-inline mt-md-0">
               <input className="form-control" type="text" id="searchinput"  onKeyUp={this.searchreq.bind(this)} placeholder="search users"  label="Search"/>
              {this.state.searchreqval.length>0 && this.state.searchreqval!=="" ? <span className="btn"><img className="" onClick={this.removereqtext.bind(this)}  src="../../images/close(1).png"/></span>:
             <span className="btn"><img className=""  src="../../images/search.png"/></span> }
            </form>
         </div>
         <div className="followingmain">
            <div className="row pr-0 pl-0">

            <div
  id="scrollableDivReq"
  className="scrollClass newscreen1"
  style={{ 
    overflow: 'auto',
    display: 'row',
    flexDirection: 'row',
    margintop: 47,}}
>
{(() => { 
  if(this.state.followrequestSearch.length > 0)
{
return(
   
      <InfiniteScroll
       dataLength={this.state.items3.length}
       next={this.followrequestSearch2.bind(this)}
       style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
       inverse={false} //
       hasMore={true}
       loader={
         <div className={this.weekforNext2()}>
         <img
            src="../../images/newgif.gif"
            alt="loading"
         />
         </div>}
   
        endMessage={
               <p style={{ textAlign: "center" }}>
                 <b>Yay! You have seen it all</b>
               </p>
             }
             useWindow={false}
       scrollableTarget="scrollableDivReq"
     >
        {this.state.followrequestSearch.length>0  ? this.state.followrequestSearch.map((c, i) => {
          
          return(
             
             <div className="kunal2">
               
               <div className="col-12">
               <div className="userin userinfollowers">
                        <div className="intus">
                        <Link className="might" to={'/'+c.username} onClick={this.setStorage2.bind(this)}>
                           {c.profileImage ? <img className="u_img" src={this.state.baseUrl+c.profileImage} align="images"/> : <span className="username">{c.screenName.split(" ")[1].charAt(0) ? c.screenName.split(" ")[0].charAt(0)+c.screenName.split(" ")[1].charAt(0): c.screenName.split(" ")[0].charAt(0)}</span>}
                           <h6>{c.screenName}</h6>
                           <p><NumberFormat value={c.mutual} displayType={'text'} thousandSeparator={true}/> mutual</p>
                           </Link>
                        </div>
                        <h5><span id={c.user_id} onClick={this.accept.bind(this)}>Accept</span> <span id={c.user_id} onClick={this.delete.bind(this)}className="dicline">Decline</span></h5>
                     </div>
                  </div>
            </div>
   
          )}):""}
     </InfiniteScroll>
  )
}
else{
  
         if(this.state.followrequest.length > 0)
       {
       return(
   
      <InfiniteScroll
       dataLength={this.state.items2.length}
       next={this.fetchmorereq.bind(this)}
       style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
       inverse={false} //
       hasMore={true}
       loader={
         <div className={this.weekforNext2()}>
         <img
            src="../../images/newgif.gif"
            alt="loading"
         />
         </div>}
   
        endMessage={
               <p style={{ textAlign: "center" }}>
                 <b>Yay! You have seen it all</b>
               </p>
             }
             useWindow={false}
       scrollableTarget="scrollableDivReq"
     >
        {this.state.followrequest.length>0  ? this.state.followrequest.map((c, i) => {
          return(
             <div className="kunal2">
               <div className="col-12">
                     <div className="userin userinfollowers">
                        <div className="intus">
                        <Link className="might" to={'/'+c.username} onClick={this.setStorage2.bind(this)}>
                        {c.profileImage ? <img className="u_img" src={this.state.baseUrl+c.profileImage} align="images"/> : <span className="username">{c.screenName.split(" ")[1].charAt(0) ? c.screenName.split(" ")[0].charAt(0)+c.screenName.split(" ")[1].charAt(0): c.screenName.split(" ")[0].charAt(0)}</span>}
                           <h6>{c.screenName}</h6>
                           <p><NumberFormat value={c.mutual} displayType={'text'} thousandSeparator={true}/> mutual</p>
                           </Link>
                        </div>
                        <h5><span id={c.user_id} onClick={this.accept.bind(this)}>Accept</span> <span id={c.user_id} onClick={this.delete.bind(this)}className="dicline">Decline</span></h5>
                     </div>
                  </div>
                  
            </div>
          )}):""}
     </InfiniteScroll>
       )
          }
          else
          {
             return(<div className="blankcalendar blankcalendar2">
             <div className="testc"><p>{'Requests will show up here when you receive follow requests, '+localStorage.getItem('screenname').split(" ")[0]}</p></div>
             <span><span className="firstround"></span><span className="secondround"></span></span>
          </div>)
          }
}
})()}
  </div>
            </div>
         </div>
      </div>
      <div id="onesuggested" className="followrequests tab-pane fade">
      <div className="header_search ">
            <form className="form-inline mt-md-0">
               <input className="form-control" type="text" id="searchUser"  onKeyUp={this.searchreqUser.bind(this)} placeholder="search users"  label="Search"/>
              {this.state.searchreqvalUser.length>0 && this.state.searchreqvalUser!=="" ? <span className="btn"><img className="" onClick={this.removereqtextUser.bind(this)}  src="../../images/close(1).png"/></span>:
             <span className="btn"><img className=""  src="../../images/search.png"/></span> }
            </form>
         </div>
         <div className="followingmain ">
         <div class={this.state.followrequestUserSearch.length>0 || this.state.followrequestUser.length>0  ? "loaderclass closeCd": "loaderclass onscroll"}><img src="../../images/newgif.gif" alt="loading"/></div>
            <div className="row pr-0 pl-0">

            <div
  id="scrollableDivfollow"
  className="scrollClass newscreen1"
  style={{ 
    overflow: 'auto',
    display: 'row',
    flexDirection: 'row',
    margintop: 47,}}
>
{(() => { 
  if(this.state.followrequestUserSearch.length > 0)
{
return(
   
      <InfiniteScroll
       dataLength={this.state.items4.length}
       next={this.followrequestUserSearch2.bind(this)}
       style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
       inverse={false} //
       hasMore={true}
      //  loader={
      //    <div className={this.weekforNext3()}>
      //    <img
      //       src="../../images/newgif.gif"
      //       alt="loading"
      //    />
      //    </div>}

      // loader={
      //    <div className={this.state.followrequestUserSearch.length>0 ? "loaderclass" : "loaderclass closeCd"}>
      //    <img
      //       src="../../images/newgif.gif"
      //       alt="loading"
      //    />
      //    </div>}
   
        endMessage={
               <p style={{ textAlign: "center" }}>
                 <b>Yay! You have seen it all</b>
               </p>
             }
             useWindow={false}
       scrollableTarget="scrollableDivfollow"
     >
        {this.state.followrequestUserSearch.length>0  ? this.state.followrequestUserSearch.map((c, i) => {
          return( 
             <div className="kunal2">
               <div className="col-12">
               <div className="userin userinfollowers">
                        <div className="intus">
                        <Link className="might" to={'/'+c.username} onClick={this.setStorage2.bind(this)}>
                        {c.profileImage ? <img className="u_img" src={this.state.baseUrl+c.profileImage} align="images"/> : <span className="username">{c.screenName.split(" ")[1].charAt(0) ? c.screenName.split(" ")[0].charAt(0)+c.screenName.split(" ")[1].charAt(0): c.screenName.split(" ")[0].charAt(0)}</span>}
                           <h6>{c.screenName}</h6>
                           <p><NumberFormat value={c.mutual} displayType={'text'} thousandSeparator={true}/> mutual</p>
                           </Link>
                        </div>
                        {(() => { 
 if(c.isFollowing == 0 && c.make_public == 1 )
 {
 return(<h5><span id={c.user_id+'/'+c.make_public}  onClick={this.followuser.bind(this)} className="follow" >follow</span></h5>);
 }
 else if(c.isFollowing == 0 && c.make_public == 0 && c.isAlreadyRequested == 0 )
 {
 return(<h5><span id={c.user_id+'/'+c.make_public}  onClick={this.followuser.bind(this)} className="follow" >request</span></h5>);
 }
 })()}
                     </div>
                  </div>
            </div>
          )}):""}
     </InfiniteScroll>
  )
}
else{
       
       return(
      <InfiniteScroll
       dataLength={this.state.items5.length}
       next={this.fetchmorereqUser.bind(this)}
       style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
       inverse={false} //
       hasMore={true}
      //  loader={
      //    <div className={this.weekforNext3()}>
      //    <img
      //       src="../../images/newgif.gif"
      //       alt="loading"
      //    />
      //    </div>}

// loader={
//    <div className={this.state.followrequestUser.length>0 ? "loaderclass" : "loaderclass closeCd"}>
//    <img
//       src="../../images/newgif.gif"
//       alt="loading"
//    />
//    </div>}
   
        endMessage={
               <p style={{ textAlign: "center" }}>
                 <b>Yay! You have seen it all</b>
               </p>
             }
             useWindow={false}
       scrollableTarget="scrollableDivfollow"
     >
        {this.state.followrequestUser.length>0  ? this.state.followrequestUser.map((c, i) => {
          return(
             <div className="kunal2">
               <div className="col-12">
                     <div className="userin userinfollowers">
                        <div className="intus">
                        <Link className="might" to={'/'+c.username} onClick={this.setStorage3.bind(this)}>
                        {c.profileImage ? <img className="u_img" src={this.state.baseUrl+c.profileImage} align="images"/> : <span className="username">{c.screenName.split(" ")[1].charAt(0) ? c.screenName.split(" ")[0].charAt(0)+c.screenName.split(" ")[1].charAt(0): c.screenName.split(" ")[0].charAt(0)}</span>}
                           <h6>{c.screenName}</h6>
                           <p><NumberFormat value={c.mutual} displayType={'text'} thousandSeparator={true}/> mutual</p>
                           </Link>
                        </div>
                        {(() => { 
 if(c.isFollowing == 0 && c.make_public == 1 )
 {
 return(<h5><span id={c.user_id+'/'+c.make_public}  onClick={this.followuser.bind(this)} className="follow" >follow</span></h5>);
 }
 else if(c.isFollowing == 0 && c.make_public == 0 && c.isAlreadyRequested == 0 )
 {
 return(<h5><span id={c.user_id+'/'+c.make_public}  onClick={this.followuser.bind(this)} className="follow" >request</span></h5>);
 }
 })()}
                     </div>
                  </div> 
            </div>
          )}):""}
     </InfiniteScroll>
       )
}
})()}
   
  </div>
            </div>
         </div>
      </div> */}
            </div>
            <Footer {...this.props} />
         </div>
         //    </div> 
      )
   }
}
export default Notifications;