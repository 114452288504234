import React, { useCallback, useContext, useEffect, useState } from "react";
import Loginform from "../Loginform";
import SignUp from "../SignUp";
import GuestFooter from "../GuestFooter";
import { MyContext } from '../../context';

export const loginPagePathname = "loginSignUp"

export default function LoginSignUp({ screenId, showSignUp: signUp, location, setVisitedPaths, history, routeMatch, params, pos, appContext }) {
  const context = useContext(MyContext)

  const [showSignUp, setShowSignUp] = useState(signUp);

  const isScreen1 = screenId && screenId == "screen_1";
  const routeProps = { location, history, routeMatch, params, pos, setVisitedPaths, appContext }

  const { setActiveTab } = context

  let updateView = useCallback(() => {
    setShowSignUp(!showSignUp);
  }, []);
  localStorage.setItem("footer", "add");

  const loginElement = (
    <div className={`${pos} screen1 in tab-pane`} id="loginSignup">
      <div className="top newbg" id="myHeader">
        <section className="header userlogin">
          <div className="row">
            <div className="col-12 pl-0 pr-0 header_center text-center">
              <h6>Timesort</h6>
            </div>
          </div>
        </section>
      </div>
      {showSignUp ? (
        <SignUp updateView={updateView} showPath={isScreen1} parentPathName={loginPagePathname} {...routeProps} />
      ) : (
        <Loginform updateView={updateView} showPath={isScreen1} parentPathName={loginPagePathname} {...routeProps} />
      )}
      <GuestFooter {...routeProps} />
    </div>
  );

  useEffect(() => {
    setActiveTab("third")
    return () => {
      updateSharedDataInContext()
    }
  }, [])
  function updateSharedDataInContext() {
    const { sharedData, setSharedData } = context
    sharedData.pageWiseData[loginPagePathname] = { ...sharedData.pageWiseData[loginPagePathname], showSignUp }

    setSharedData({ ...sharedData });
  }
  return isScreen1 ? (
    <div className="newscreen1 newscreen01">{loginElement}</div>
  ) : (
    loginElement
  );
}

