import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { Player, ControlBar } from 'video-react';
import { Button } from 'reactstrap';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from 'react-number-format';
import Footer from '../Footer';
class tag extends React.Component {
   constructor(props) {
      super(props);
      let UserName = null;
      const path = this.props.location.pathname;
      const params = this.props.location.pathname.split('/');
      UserName = params[params.length - 1];
      this.state = {
         hashtag: UserName, baseUrl: process.env.REACT_APP_BASE_URL, activecolor: 'page', hashtag2: "", loaded: true, page: 2, items: Array.from({ length: 10 }), hasMore: true, tags: [],
         alphabetically_following: "", mutuality_following: "", totalPost: 0, slectedConst: "", block: false, more: false, errortitle: "", statepage: 0,
      }
   }

   goback() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   fetchMoreComment() {
      if (this.state.block == false) {
         this.setState({ more: true, loaded: true });
         const token = localStorage.getItem('token');
         setTimeout(() => {
            fetch(this.state.baseUrl + '/api/event/getEventByHashtag.php?hashtag=' + this.state.hashtag2 + '&page=' + this.state.page + '&limit=10', {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {
                  if (responseJson.response.events.length > 9) {
                     this.setState({ page: this.state.page + 1 });
                  }
                  else {
                     this.setState({ block: true, loaded: false });
                  }
                  for (var i = responseJson.response.events.length - 1; i >= 0; i--) {
                     this.state.tags.push(responseJson.response.events[i]);
                  }
                  this.setState({ tags: this.state.tags });
                  localStorage.setItem("across", JSON.stringify(this.state.tags));
               }, 3000);
         });

         this.setState({
            items: this.state.items.concat(Array.from({ length: 10 }))
         });
      }
   }

   checkComment() {
      if (this.state.loaded == false || this.state.block == true) {
         return 'loaderclass closeCd';
      }
      else {
         if (this.state.more == true) {
            return 'loaderclass load';
         }
         else {
            return 'loaderclass';
         }
      }
   }

   // componentDidUpdate() {
   //    if(this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]!=="" && this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]!== undefined)
   //    {
   //       if(this.state.hashtag2!==this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1])
   //       {
   //          this.setState({hashtag:this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]})
   //             this.mainfuncion(this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]);
   //             $('#one_13').removeClass('active fades');
   //             setTimeout(() => {
   //             $('#one_13').addClass('active fades');
   //             },300);
   //       } 
   //    }
   // }

   componentDidMount() {
      localStorage.removeItem("across");
      $(".errorbtn").click(function () {
         $(".errorpop").toggle();
      });
      this.setState({ hashtag2: this.state.hashtag }, () => {
         this.mainfuncion(this.state.hashtag2);
      })
   }
   mainfuncion(datass) {
      $(".errorbtn").click(function () {
         $(".errorpop").toggle();
      });
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/event/getEventByHashtag.php?hashtag=' + datass + '&page=1&limit=10', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            localStorage.setItem("across", JSON.stringify(responseJson.response.events));
            this.setState({ tags: responseJson.response.events, totalPost: responseJson.response.totalPost, loaded: false, items: Array.from({ length: 10 }), hasMore: true });
         });
   }
   likepost(e) {
      $("#" + e.currentTarget.id).toggleClass("thumbs-down");
      var hascc = $('div').hasClass('thumbs-down');
      if (hascc == true) {
         setTimeout(function () {
            $('.thumbs-down').removeClass('thumbs-down');
         }, 1500);
      }
      var evtid = e.currentTarget.id.replace('crox', '');
      const body = {
         user_id: localStorage.getItem('userid'),
         eventID: evtid,
      }
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/event/addOrRemoveLike.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "TimeZone": moment.tz.guess(),
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            console.log('res');
         });
   }
   collectMentions(value) {
      var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      var hash = value.match(/(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      if (more || hash) {
         if (more !== null && more.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < more.length; index++) {
                  $("." + more[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/' + more[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
         if (hash !== null && hash.length > 0) {
            console.log()
            $(document).ready(function () {
               for (let index = 0; index < hash.length; index++) {
                  $("." + hash[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/tag/' + hash[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
      }
   }
   fetchafterfilter(jstatus) {
      this.collectMentions(jstatus);
      var regex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/ig;
      var replaced2 = jstatus.replace(regex, value => `<a href=${"https://" + value} class='hashtag_link' target='_blank'>${value}</a>`);
      var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig;
      var replaced7 = replaced2.replace(mention_preg, value => `<a class=${value.substring(1)}>${value}</a>`);
      var regex2 = /!+([a-zA-Z0-9_]+)/ig;
      var replaced3 = replaced7.replace(regex2, value => `<span>${value}</span>`);
      var replaced = replaced3.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return replaced;
   }

   mutualitySearchList() {
      $(".pupup3").toggle();
      this.setState({ slectedConst: "mutual" });
   }
   alphabeticallySearchList() {
      $(".pupup3").toggle();
      this.setState({ slectedConst: "alpha" });
   }
   relevancySearchList() {
      $(".pupup3").toggle();
      this.setState({ slectedConst: "" });
      if (JSON.parse(localStorage.getItem("across"))) {
         this.setState({ tags: JSON.parse(localStorage.getItem("across")) });
      }
   }
   filterUserin(data) {
      if (this.state.slectedConst == "") {
         return data;
      }
      else if (this.state.slectedConst == "mutual") {
         return data.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse();
      }
      else if (this.state.slectedConst == "alpha") {
         return data.sort((a, b) => a.screenName.localeCompare(b.screenName));
      }
   }

   setStorage() {
      localStorage.setItem("backid", "front");
   }
   weekDays = (dayNumber) => {
      if (dayNumber == 0) {
         return "Sunday";
      }
      if (dayNumber == 6) {
         return "Saturday";
      }
      if (dayNumber == 5) {
         return "Friday";
      }
      if (dayNumber == 4) {
         return "Thursday";
      }
      if (dayNumber == 3) {
         return "Wednesday";
      }
      if (dayNumber == 2) {
         return "Tuesday";
      }
      if (dayNumber == 1) {
         return "Monday";
      }
   }

   openpop() {
      $(".pupup3").toggle();
   }
   getdateformat(datedata) {
      if (datedata) {
         var postedDate = moment.unix(datedata).format("YYYY-MM-DD");
         var currentDate = moment(new Date()).format("YYYY-MM-DD");
         var eventDate = datedata;
         var date = "";
         var duration = moment.duration(moment(postedDate).diff(moment(currentDate)));
         var daysDifference = duration.asDays();
         if (daysDifference <= -8 || daysDifference > 7) {
            const day = moment.unix(eventDate).format("D");
            const month = moment.unix(eventDate).format("MMM");
            const time = moment.unix(eventDate).format("h:mma");
            var lasttag = "";
            switch (day % 10) {
               case 1:
                  if (day != 11) {
                     lasttag = "st";
                  } else {
                     lasttag = "th";
                  }
                  break;
               case 2:
                  if (day != 12) {
                     lasttag = "nd";
                  } else {
                     lasttag = "th";
                  }
                  break;
               case 3:
                  if (day != 13) {
                     lasttag = "rd";
                  } else {
                     lasttag = "th";
                  }
                  break;
               default: lasttag = "th";
                  break;
            }
            date = `${month} ${day}${lasttag}, ${time}`;
            return date;
         }
         if (daysDifference < -1 && daysDifference > -8) {
            date = "last" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference < 0 && daysDifference >= -1) {
            date = "yesterday at " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 0 && daysDifference < 1) {
            date = moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 1 && daysDifference < 2) {
            date = "tomorrow at " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference >= 2 && daysDifference < 7) {
            date = "this" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
         if (daysDifference == 7) {
            date = "next" + " " + this.weekDays(moment(postedDate).weekday()) + " " + "at" + " " + moment.unix(eventDate).format("h:mma");
            return date;
         }
      }
   }



   thireduserpop(e) {
      this.setState({ cscreenname: e.currentTarget.dataset.div_name, follower: e.currentTarget.dataset.div_follower, c_userid: e.currentTarget.dataset.div_userid, c_event: e.currentTarget.id }, () => {
         $(".pupup4").toggle();
      });
      // $(".pupup4").toggle();
   }
   changeCurrentTimeOld(seconds, players) {
      let playerd = this[`ref${players}`].getState().player.currentTime;
      this[`ref${players}`].seek(playerd + seconds);
   }

   removeuserpop() {
      $(".pupup4").toggle();
      this.setState({ cscreenname: "", c_userid: "", c_event: "", follower: 0 });
   }
   thiredSeeMore() {
      $(".pupup4").toggle();
      $('#promo' + this.state.c_event).addClass('closeBd');
      setTimeout(function () {
         $('#promo' + this.state.c_event).removeClass('closeBd');
      }.bind(this), 3000);
   }
   blockuserpop() {
      $(".pupup4").toggle();
      this.setState({ errortitle: "Block " + this.state.cscreenname.split(" ")[0], editerror: "Are you sure would like to block " + this.state.cscreenname + ", " + localStorage.getItem("screenname").split(" ")[0] + "?" }, () => {
         $(".errorpop").toggle();
      });
   }
   removefollowpop() {
      $(".pupup4").toggle();
      this.setState({ errortitle: "Remove Follower", editerror: "Are you sure would like to remove " + this.state.cscreenname + " as a follower, " + localStorage.getItem("screenname").split(" ")[0] + "?" }, () => {
         $(".errorpop").toggle();
      });
   }
   thiredLessMore() {
      $(".pupup4").toggle();
      $('#promi' + this.state.c_event).addClass('closeBd');
      setTimeout(function () {
         $('#promi' + this.state.c_event).removeClass('closeBd');
      }.bind(this), 3000);
   }

   blockuser() {
      if (this.state.errortitle == "Remove Follower") {
         const body = { user_id: this.state.c_userid }
         const token = localStorage.getItem('token');
         axios.post(this.state.baseUrl + '/api/follow/removeFollower.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
            }).catch((error) => {
               console.log(error);
            })
      }
      if (this.state.errortitle == "Block") {
         const body = { profileId: this.state.c_userid }
         const token = localStorage.getItem('token');
         axios.post(this.state.baseUrl + '/api/user/blockUser.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
            }).catch((error) => {
               console.log(error);
            })
      }
   }
   render() {
      return (
         //         <div className="newscreen1 newscreen01">
         // <div className="screen1 active fades"  id="one_13">
         <div className={`${this.props.pos} screen1`} id="one_13">
            <div className="top" id="myHeader">
               <section className="header headerpost">
                  <div className="row">
                     <div className="col-4 header_left pr-0">
                        <ul className="nav nav-tabs">
                           <li><a className="linkback" data-toggle="tab" onClick={this.goback.bind(this)} >
                              <img defaultStyle="font-size: 16px" src="../images/back.png" align="img" /></a></li>
                        </ul>
                     </div>
                     <div className="col-4 header_center pl-0 pr-0 text-center">
                        <h6 className="mb-0">{'#' + this.state.hashtag}</h6>
                        <p>{this.state.totalPost > 0 ? this.state.totalPost + " posts" : "posts"}</p>
                     </div>
                     <div className="col-4 header_right pl-0">
                        <ul className="uld">
                           <li className="removepost"><a onClick={this.openpop.bind(this)}><img src="../images/more2.png" align="img" /></a></li>
                        </ul>
                     </div>
                  </div>
               </section>
            </div>
            {/* <div className="tab-content postdiv haggtag">
      <div className="row">
         <div className="tab-content "> */}
            <div id="listcontent" className="tab-content tab-content2">
               <div className="sectionone">
                  <div
                     id="scrollableDivSms"
                     className="scrollTag newscreen1"
                     style={{
                        overflow: 'auto',
                        display: 'row',
                        flexDirection: 'row',
                        margintop: 47,
                     }}
                  >
                     <InfiniteScroll
                        dataLength={this.state.items.length}
                        next={this.fetchMoreComment.bind(this)}
                        style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                        inverse={false}

                        hasMore={true}
                        loader={
                           <div id="march" className={this.checkComment()}>
                              <img
                                 src="../../images/newgif.gif"
                                 alt="loading"
                              />
                           </div>}
                        endMessage={
                           <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                           </p>}
                        useWindow={false}
                        scrollableTarget="scrollableDivSms"
                     >
                        <div className="mb-2">
                           {this.state.tags.length > 0 ? this.filterUserin(this.state.tags).map((j, x) => {
                              return (
                                 <div className="hashes">
                                    <div id={'hash' + j.eventID} className={j.eventID}>
                                       <div class="likeaddto" id={"promo" + j.eventID}><img class="w-100" src="../../images/More.png" alt="image" /></div>
                                       <div class="likeaddto" id={"promi" + j.eventID}><img class="w-100" src="../../images/setting_dont_follow_back.png" alt="image" /></div>
                                       <div id={"crox" + j.eventID} className="postlike" onDoubleClick={this.likepost.bind(this)}>
                                          <div className="userin">
                                             <a><Link to={{
                                                pathname: `/${j.username}`,
                                                state: { id: j.user_id }
                                             }} onClick={this.setStorage.bind(this)}
                                             >{j.profileImage != "" ? <img src={this.state.baseUrl + j.profileImage} align="images" /> : <span className="username">{this.state.static}</span>}</Link></a>
                                             <h4><Link to={{
                                                pathname: `/${j.username}`,
                                                state: { id: j.user_id }
                                             }} onClick={this.setStorage.bind(this)}
                                             >{j.screenName}{'\u00A0'}</Link>
                                                <span>{this.getdateformat(j.postedOn)}</span>
                                             </h4>
                                             <h5 dangerouslySetInnerHTML={{ __html: this.fetchafterfilter(j.status) }}>
                                             </h5>
                                          </div>
                                          <div className="post ">
                                             {(() => {
                                                if (j.mediaType == "video") {
                                                   return (
                                                      <div id={'Video' + j.eventID} className="video-div">
                                                         <Player
                                                            ref={player => {
                                                               this[`ref${j.eventID}`] = player;
                                                            }}

                                                            id={'Videos' + j.eventID}
                                                            autoPlay={false} className="video-react-has-started">
                                                            <source src={this.state.baseUrl + j.eventImage} type="video/mp4" />
                                                            <ControlBar autoHide={false} className="my-class" />
                                                         </Player>
                                                         <ul class="videoulkap pb-3">
                                                            <li><Button data-id={"player" + j.id} className={-10} onClick={() => this.changeCurrentTimeOld(-10, j.eventID)} ><img class="img1" src="../../images/Backwards.png" />
                                                               <span>10</span></Button>
                                                            </li>
                                                            <li><img class="img2" src="../../images/pause.png" /></li>
                                                            <li><Button data-id={j.id} className={10} onClick={() => this.changeCurrentTimeOld(10, j.eventID)} ><img class="img3" src="../../images/Forwards.png" />
                                                               <span>10</span></Button>
                                                            </li>
                                                         </ul>
                                                      </div>);
                                                }
                                                else if (j.mediaType == "image") {
                                                   return (
                                                      <img className="w-100 mt-1" src={this.state.baseUrl + j.eventImage} alt="image" />
                                                   );
                                                }
                                             }
                                             )()}
                                             <div className="hearti">
                                                <img className="w-100" src="../../images/cklick.png" alt="image" />
                                             </div>
                                          </div>
                                       </div>
                                       <ul className={j.mediaType == null || j.mediaType == "" ? "videoul mt3 status" : "videoul mt3"} >
                                          <li> {(() => {
                                             if (j.commentCounts > 0) {
                                                return (<a className="link link2k comentbt " id={j.eventID} data-toggle="tab" ><Link to={{
                                                   pathname: `/SinglePage/${j.eventID}`,
                                                   state: { back: "front", username: this.state.cusername, user: "other", image: this.state.cprofileImage, name: this.state.cscreenname }
                                                }} onClick={this.setStorage.bind(this)}>
                                                   <img src="../../images/comment.png" Style="height:20px;"
                                                      alt="icon" /> <NumberFormat value={j.commentCounts} displayType={'text'} thousandSeparator={true} /></Link></a>)
                                             }
                                             else {
                                                return (<a className="link comentbt" id={j.eventID} data-toggle="tab" ><Link to={{
                                                   pathname: `/SinglePage/${j.eventID}`,
                                                   state: { back: "front", username: this.state.cusername, user: "other", image: this.state.cprofileImage, name: this.state.cscreenname }
                                                }} onClick={this.setStorage.bind(this)}>
                                                   <img src="../../images/comment.png" Style="height:20px;" alt="icon" /></Link></a>)
                                             }
                                          })()}
                                             <a className="upbutton" id={j.eventID} data-div_follower={j.isFollowers} data-div_name={j.screenName} data-div_userid={j.eventBy} onClick={this.thireduserpop.bind(this)} ><img className="option ml-3" src="../../images/option.png"
                                                align="icon" /></a></li>
                                          {(() => {
                                             if (j.withwho != null) {
                                                return (<li><img src="../../images/b.png" alt="icon" />
                                                   {(() => {
                                                      if (j.withwho != null) {
                                                         return (
                                                            j.withwho.map((k, l) => {
                                                               return (
                                                                  <span><a><Link to={{
                                                                     pathname: `/${k.username}`,
                                                                     state: { id: k.user_id }
                                                                  }} onClick={this.setStorage.bind(this)}
                                                                  >{k.screenName}</Link></a><span>, </span></span>
                                                               );
                                                            })
                                                         )
                                                      }
                                                      else {
                                                         return (
                                                            <a href="#"></a>
                                                         )
                                                      }
                                                   }
                                                   )()}
                                                </li>
                                                )
                                             }
                                          }
                                          )()}
                                          {(() => {
                                             if (j.likedByUsers.length > 0) {
                                                return (<li>
                                                   <img src="../../images/c.png" alt="icon" />
                                                   {(() => {
                                                      if (j.likedByUsers.length > 0) {
                                                         return (
                                                            j.likedByUsers.map((q, t) => {
                                                               return (<span><a><Link to={{
                                                                  pathname: `/${q.username}`,
                                                                  state: { id: q.user_id }
                                                               }} onClick={this.setStorage.bind(this)}
                                                               >{q.screenName}</Link></a><span>, </span></span>
                                                               );
                                                            })
                                                         )
                                                      }
                                                   }
                                                   )()}
                                                </li>
                                                )
                                             }
                                          }
                                          )()}
                                          {(() => {
                                             if (j.likedByUsers.length > 0) {
                                                return (<li>
                                                   <img src="../../images/d.png" alt="icon" />
                                                   {(() => {
                                                      if (j.commentByUsers.length > 0) {
                                                         return (
                                                            j.commentByUsers.map((m, index) => {
                                                               return (
                                                                  <span><a><Link to={{
                                                                     pathname: `/${m.username}`,
                                                                     state: { id: m.user_id }
                                                                  }} onClick={this.setStorage.bind(this)}
                                                                  >{m.screenName}</Link></a><span>, </span></span>
                                                               );
                                                            })
                                                         )
                                                      }
                                                   }
                                                   )()}
                                                </li>
                                                )
                                             }
                                          }
                                          )()}
                                       </ul>
                                    </div></div>)
                           }
                           )
                              : ""}
                        </div>
                     </InfiniteScroll>
                  </div>
               </div>
            </div>
            <Footer {...this.props} />
            <div className="op pupup4">
               <div className="more h-100" onClick={this.removeuserpop.bind(this)}></div>
               <ul>
                  <li><a className="more" onClick={this.thiredSeeMore.bind(this)}><img src="../../images/More.png" /> see more like this</a></li>
                  <li><a className="more" onClick={this.thiredLessMore.bind(this)}><img src="../../images/setting_dont_follow_back.png" /> see less like this</a></li>
                  <li><a className="more"><img src="../../images/setting_request.png" />{this.state.cscreenname ? "message " + this.state.cscreenname.split(" ")[0] : "message"}</a></li>
                  {this.state.follower == 1 ? <li><a className="more" onClick={this.removefollowpop.bind(this)}><img src="../../images/remove.png" /> remove follower</a></li> : ""}
                  <li><a className="more" onClick={this.blockuserpop.bind(this)}><img src="../../images/block.png" />{this.state.cscreenname ? "block " + this.state.cscreenname.split(" ")[0] : "block"}</a></li>
                  <li><span className="link more" ><Link to={{
                     pathname: `/Report/${this.state.c_userid}`,
                     state: { name: this.state.cscreenname }
                  }} onClick={this.setStorage.bind(this)}><img src="../../images/report.png" />{this.state.cscreenname ? "report " + this.state.cscreenname.split(" ")[0] : "report"}</Link></span></li>
                  <button className=" more upbtn" onClick={this.removeuserpop.bind(this)}>Cancel</button>
               </ul>
            </div>
            <div className="op errorpop mainpop">
               <div className="errorbtn error h-100"></div>
               <ul>
                  <li className="lipost">{this.state.errortitle}</li>
                  <li>{this.state.editerror}</li>
                  <div className="newpop">
                     <button className="upbtn errorbtn">CANCEL</button>
                     <button className="upbtn errorbtn" onClick={this.blockuser.bind(this)}>YES</button>
                  </div>
               </ul>
            </div>
            <div className="op pupup3">
               <div className="removepost h-100" onClick={this.openpop.bind(this)}></div>
               <ul>
                  <h5 className="text-center">sort</h5>
                  <li><a onClick={this.relevancySearchList.bind(this)} className="remove"><img src="../../images/More.png" /> relevancy</a></li>
                  <li><a onClick={this.mutualitySearchList.bind(this)} className="remove"><img src="../../images/mut.png" /> mutuality</a></li>
                  <li><a onClick={this.alphabeticallySearchList.bind(this)} className="remove"><img src="../../images/az.png" /> alphabetically</a></li>
                  <button onClick={this.openpop.bind(this)} className="upbtn remove">Cancel</button>
               </ul>
            </div>
         </div>
         // </div>
      )
   }
}
export default tag;
