import { addToLessInterestedProfiles, addToHighInterestedProfiles, getUserRecommedations, recentListForLogin, userListForLogin, usersListForGuets, getRecommendationsFromPastVisitsForLogin } from "../../Services/api"
import { mergeArraysWithoutDuplicates, removeDuplicateObjectsFromArray, removeElementsFromArrayOfObjects } from "../arrayHelper"

export async function getProfilesList() {
    
    let list = await getList()

    localStorage.setItem("entry", JSON.stringify(list))
    
    return list
}

async function getList() {
    let recents = []
    let profileWiseRecommendations=[]
    let recommendationsFromPastVisits=[]
    let mostVisitedProfiles = []

    const savedRecents = localStorage.getItem("recents")
    const savedProfileWiseRecommendations = localStorage.getItem("profileWiseRecommendations")
    const savedRecommendationsFromPastVisits = localStorage.getItem("recommendationsFromPastVisits")
    const savedMostVisitedProfiles = localStorage.getItem("mostVisitedProfiles")

    const isLoggedInUser = localStorage.getItem("timeToken")

    if (savedRecents)
        recents = JSON.parse(savedRecents)
    else if (isLoggedInUser)
        recents = await recentListForLogin()


    if (savedProfileWiseRecommendations) {
        profileWiseRecommendations = Array.from(JSON.parse(savedProfileWiseRecommendations))
        profileWiseRecommendations = profileWiseRecommendations.filter(obj => obj.visibility).flatMap(obj=> obj.recommendations)
    }

    if (savedRecommendationsFromPastVisits)
        recommendationsFromPastVisits = JSON.parse(savedRecommendationsFromPastVisits)
    else if (isLoggedInUser){
        recommendationsFromPastVisits = await getRecommendationsFromPastVisitsForLogin()
        localStorage.setItem("recommendationsFromPastVisits", JSON.stringify(recommendationsFromPastVisits))
    }
            
    if (savedMostVisitedProfiles)
        mostVisitedProfiles = JSON.parse(savedMostVisitedProfiles)
    else if (isLoggedInUser)
        mostVisitedProfiles = await userListForLogin()
    else
        mostVisitedProfiles = await usersListForGuets()

    return removeDuplicateObjectsFromArray([...recents, ...profileWiseRecommendations, ...recommendationsFromPastVisits, ...mostVisitedProfiles])
}

export function getListBySortingType(props) {
    const savedRecents = localStorage.getItem("recents") ? JSON.parse(localStorage.getItem("recents")) : [];
    const savedProfileWiseRecommendations = localStorage.getItem("profileWiseRecommendations") ? JSON.parse(localStorage.getItem("profileWiseRecommendations")) : [];
    const savedRecommendationsFromPastVisits = localStorage.getItem("recommendationsFromPastVisits") ? JSON.parse(localStorage.getItem("recommendationsFromPastVisits")) : [];
    const savedMostVisitedProfiles = localStorage.getItem("mostVisitedProfiles") ? JSON.parse(localStorage.getItem("mostVisitedProfiles")) : [];

    if (props.sortingType == 'Popularity') {
        return removeDuplicateObjectsFromArray([...savedMostVisitedProfiles, ...savedProfileWiseRecommendations, ...savedRecommendationsFromPastVisits, ...savedRecents]);
    } else if (props.sortingType == 'Mutuality') {
        return removeDuplicateObjectsFromArray([...savedProfileWiseRecommendations, ...savedRecommendationsFromPastVisits, ...savedRecents, ...savedMostVisitedProfiles]);
    }
}

export async function addToRecents(profile) {
    let recents = []

    const savedRecents = localStorage.getItem("recents")
    if (savedRecents)
        recents = JSON.parse(savedRecents)

    recents.unshift(profile)

    recents = removeDuplicateObjectsFromArray(recents)

    // if (recents.length > 20) {
    //     recents.pop()
    // }

    localStorage.setItem("recents", JSON.stringify(recents))

    await updateProfileWiseRecommendations(profile.user_id, await getUserRecommedations(profile.user_id))

    return recents
}

export async function seeMoreLikeThis(userId, page) {
    const profiles = await addToHighInterestedProfiles(userId, page)
    updateProfileWiseRecommendations(userId, profiles, true)

    return profiles
}

export async function seeLessLikeThis(userId) {
    const added = await addToLessInterestedProfiles(userId)

    await updateProfileWiseRecommendations(userId, [], false)

}

async function updateProfileWiseRecommendations(visited_profile_id, profiles = [], visibility ) {
    if (!visited_profile_id)
        return []

    let profileWiseRecommendations = []
    let existingVisibilityForProfile 
    const savedProfileWiseRecommendations = localStorage.getItem("profileWiseRecommendations")

    let visitedProfileInfo = {
        user_id: visited_profile_id,
        visibility:true,
        recommendations: [...profiles]
    }

    if (savedProfileWiseRecommendations) {
        profileWiseRecommendations = Array.from(JSON.parse(savedProfileWiseRecommendations))

        profileWiseRecommendations = profileWiseRecommendations.filter(existingProfileInfo => {
            if (existingProfileInfo.user_id === visitedProfileInfo.user_id) {
                // appending existing recommendations to the new recommendations of profile and getting existing visibility value
                visitedProfileInfo.recommendations = removeDuplicateObjectsFromArray([...visitedProfileInfo.recommendations, ...existingProfileInfo.recommendations])
                existingVisibilityForProfile = existingProfileInfo.visibility
                return false
            }
            return true
        })
    }

    if (visibility !== undefined)
        visitedProfileInfo.visibility = visibility
    else if (existingVisibilityForProfile === undefined)
        visitedProfileInfo.visibility = true
    else
        visitedProfileInfo.visibility = existingVisibilityForProfile
    
    profileWiseRecommendations.unshift(visitedProfileInfo)

    localStorage.setItem("profileWiseRecommendations", JSON.stringify(profileWiseRecommendations))

    getProfilesList()

    return visitedProfileInfo
}

export function removeProfileDataFromStorage() {
    localStorage.removeItem("entry");
    localStorage.removeItem("profileWiseRecommendations");
    localStorage.removeItem("mostVisitedProfiles");
    localStorage.removeItem("recommendationsFromPastVisits");
    localStorage.removeItem("recommendations");
    localStorage.removeItem("recents");
}